import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatPhoneNumber, formatCep, formatCpfOrCnpj } from '../../../utils/ValidationUtils';

export default function CadastralPesquisaPJ({
  cadastralPesquisaPJ
}: {
  cadastralPesquisaPJ: any
}) {
  const { t } = useTranslation();
  const cnaeList: any[] | undefined = []
  const telefonesList: any[] | undefined = []
  const emailList: any[] | undefined = []
  const matrizList: any[] | undefined = []
  const participacaoSocietariaList: any[] | undefined = []
  const quadroSocietarioList: any[] | undefined = []
  const enderecosList: any[] | undefined = []
  const filiaisList: any[] | undefined = []
  if(cadastralPesquisaPJ.CNAE) 
    Object.getOwnPropertyNames(cadastralPesquisaPJ.CNAE).map((item) => cnaeList.push(cadastralPesquisaPJ.CNAE[item]))
  if(cadastralPesquisaPJ.TELEFONES) 
    Object.getOwnPropertyNames(cadastralPesquisaPJ.TELEFONES).map((item) => telefonesList.push(cadastralPesquisaPJ.TELEFONES[item]))
  if(cadastralPesquisaPJ.EMAIL) 
    Object.getOwnPropertyNames(cadastralPesquisaPJ.EMAIL).map((item) => emailList.push(cadastralPesquisaPJ.EMAIL[item]))
  if(cadastralPesquisaPJ.MATRIZ) 
    Object.getOwnPropertyNames(cadastralPesquisaPJ.MATRIZ).map((item) => matrizList.push(cadastralPesquisaPJ.MATRIZ[item]))
  if(cadastralPesquisaPJ.PARTICIPACAOSOCIETARIA) 
    Object.getOwnPropertyNames(cadastralPesquisaPJ.PARTICIPACAOSOCIETARIA).map((item) => participacaoSocietariaList.push(cadastralPesquisaPJ.PARTICIPACAOSOCIETARIA[item]))
  if(cadastralPesquisaPJ.QUADROSOCIETARIO) 
    Object.getOwnPropertyNames(cadastralPesquisaPJ.QUADROSOCIETARIO).map((item) => quadroSocietarioList.push(cadastralPesquisaPJ.QUADROSOCIETARIO[item]))
  if(cadastralPesquisaPJ.ENDERECOS) 
    Object.getOwnPropertyNames(cadastralPesquisaPJ.ENDERECOS).map((item) => enderecosList.push(cadastralPesquisaPJ.ENDERECOS[item]))
  if(cadastralPesquisaPJ.FILIAIS) 
    Object.getOwnPropertyNames(cadastralPesquisaPJ.FILIAIS).map((item) => filiaisList.push(cadastralPesquisaPJ.FILIAIS[item]))
  return (
    <>
          <Panel header={t('Dados Cadastrais')}>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <label className="label-dados">{t('natureza')}</label>
              <Chip style={{width:"100%"}} label={cadastralPesquisaPJ.DADOSCADASTRAIS.CODIGONATUREZA + ' - ' + cadastralPesquisaPJ.DADOSCADASTRAIS.DESCNATUREZA} />
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div>
              <label className="label-dados">{t('documento')}</label>
              <Chip style={{width:"100%"}} label={formatCpfOrCnpj(cadastralPesquisaPJ.DADOSCADASTRAIS.CPFCNPJ)} />
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div>
              <label className="label-dados">{t('Nascimento/Fundada')}</label>
              <Chip style={{width:"100%"}} label={cadastralPesquisaPJ.DADOSCADASTRAIS.NASCFUND} />
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <label className="label-dados">{t('razaoSocial')}</label>
              <Chip style={{width:"100%"}} label={cadastralPesquisaPJ.DADOSCADASTRAIS.NOMERAZAO} />
            </div>
          </div>
          {cadastralPesquisaPJ.DADOSCADASTRAIS.NOMEFANTASIA && 
          <div className="col p-fluid flex flex-wrap">
            <div>
              <label className="label-dados">{t('nomeFantasia')}</label>
              <Chip style={{width:"100%"}} label={cadastralPesquisaPJ.DADOSCADASTRAIS.NOMEFANTASIA} />
            </div>
          </div>}
        </div>
      </Panel>
      <br></br>
      <Panel header={t('cnaes')}>
        <DataTable size="small" value={cnaeList} tableStyle={{ minWidth: '50rem' }}>
          <Column body={(rowData) => `${rowData.CODIGO} - ${rowData.DESCRICAO}`} header={t('cnaes')}></Column>
        </DataTable>
      </Panel>
      <br></br>
      <Panel header={t('emails')}>
        <DataTable size="small" value={emailList} tableStyle={{ minWidth: '50rem' }}>
          <Column field="EMAIL" header={t('emails')}></Column>
        </DataTable>
      </Panel>
      <br></br>
      <Panel header={t('enderecos')}>
        <DataTable size="small" value={enderecosList} tableStyle={{ minWidth: '50rem' }}>
          <Column field="CEP" body={(rowData) => `${formatCep(rowData.CEP)}`} header={t('cep')}></Column>
          <Column field="LOGRADOURO" header={t('logradouro')}></Column>
          <Column field="TP_LOGRADOURO" body={(rowData) => rowData.TP_LOGRADOURO ? `${rowData.TP_LOGRADOURO}` : '-'} header={t('Tipo Logradouro')}></Column>
          <Column field="NUMERO" header={t('numero')}></Column>
          <Column field="BAIRRO" header={t('bairro')}></Column>
          <Column field="COMPLEMENTO" body={(rowData) => rowData.COMPLEMENTO ? `${rowData.COMPLEMENTO}` : '-'} header={t('complemento')}></Column>
          <Column field="CIDADE" header={t('cidade')}></Column>
          <Column field="UF" header={t('uf')}></Column>
        </DataTable>
      </Panel>
      <br></br>
      <Panel header={t('filiais')}>
        <DataTable size="small" value={filiaisList} tableStyle={{ minWidth: '50rem' }}>
          <Column field="ATIVA" header={t('Ativa')}></Column>
          <Column field="BAIXADA" header={t('Baixada')}></Column>
          <Column field="INAPTA" header={t('Inapta')}></Column>
          <Column field="NULA" header={t('Nula')}></Column>
          <Column field="QUANTIDADE" header={t('quantidade')}></Column>
          <Column field="SUSPENSA" header={t('Suspensa')}></Column>
        </DataTable>
      </Panel>            
      <br></br>
      <Panel header={t('Informações Empresa')}>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <label className="label-dados">{t('Faturamento')}</label>
              <Chip style={{width:"100%"}} label={cadastralPesquisaPJ.INFOEMPRESA.FATURAMENTO} />
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div>
              <label className="label-dados">{t('filiais')}</label>
              <Chip style={{width:"100%"}} label={cadastralPesquisaPJ.INFOEMPRESA.FILIAIS} />
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <label className="label-dados">{t('Número de Funcionarios')}</label>
              <Chip style={{width:"100%"}} label={cadastralPesquisaPJ.INFOEMPRESA.NUMFUNC} />
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div>
              <label className="label-dados">{t('porte')}</label>
              <Chip style={{width:"100%"}} label={cadastralPesquisaPJ.INFOEMPRESA.PORTE} />
            </div>
          </div>
        </div>
      </Panel>
      <br></br>
      <Panel header={t('Matriz')}>
        <DataTable size="small" value={matrizList} tableStyle={{ minWidth: '50rem' }}>
          <Column field="MATRIZ" header={t('Matriz')}></Column>
          <Column field="CNPJ" body={(rowData) => `${formatCpfOrCnpj(rowData.CNPJ)}`} header={t('cnpj')}></Column>
          <Column field="CONDICAO" header={t('condicao')}></Column>
        </DataTable>
      </Panel>
      <br></br>
      <Panel header={t('Participações Societarias')}>
        <DataTable size="small" value={participacaoSocietariaList} tableStyle={{ minWidth: '50rem' }}>
          <Column field="DOCUMENTO" body={(rowData) => `${formatCpfOrCnpj(rowData.DOCUMENTO)}`} header={t('documento')}></Column>
          <Column field="PERCENTUAL" body={(rowData) => rowData.PERCENTUAL ? `${rowData.PERCENTUAL}` : '-'} header={t('Percentual')}></Column>
          <Column field="QUALIFICACAO" header={t('Qualificação')}></Column>
          <Column field="DATA" body={(rowData) => rowData.DATA ? `${rowData.DATA}` : '-'} header={t('data')}></Column>
          <Column field="CNPJ" body={(rowData) => `${formatCpfOrCnpj(rowData.CNPJ)}`} header={t('cnpj')}></Column>
        </DataTable>
      </Panel>
      <br></br>
      <Panel header={t('Quadros Societarios')}>
        <DataTable size="small" value={quadroSocietarioList} tableStyle={{ minWidth: '50rem' }}>
          <Column field="QUALIFICACAO_GLOBAL" header={t('Qualificação Global')}></Column>
          <Column field="QUALIFICACAO" header={t('Qualificação')}></Column>
          <Column field="PERCENTUAL" body={(rowData) => rowData.PERCENTUAL ? `${rowData.PERCENTUAL}` : '-'} header={t('Percentual')}></Column>
          <Column field="NOMERAZAO" header={t('razaoSocial')}></Column>
          <Column field="NOME" header={t('nome')}></Column>
          <Column field="DOCUMENTO" body={(rowData) => `${formatCpfOrCnpj(rowData.DOCUMENTO)}`} header={t('documento')}></Column>
          <Column field="DATA" body={(rowData) => rowData.DATA ? `${rowData.DATA}` : '-'} header={t('data')}></Column>
          <Column field="CPFCNPJ" body={(rowData) => `${formatCpfOrCnpj(rowData.CPFCNPJ)}`} header={t('CPF/CNPJ')}></Column>
        </DataTable>
      </Panel>
      <br></br>
      <Panel header={t('telefones')}>
        <DataTable size="small" value={telefonesList} tableStyle={{ minWidth: '50rem' }}>
          <Column body={(rowData) => `${formatPhoneNumber(rowData.DDD + rowData.TELEFONE)}`} header={t('telefones')}></Column>
        </DataTable>
      </Panel>
    </>
  )
}

import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";
import RegistroList from './RegistroList';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import RegistroCadastrar from './RegistroCadastrar';
import { Messages } from 'primereact/messages';
import postRegistro from "../../service/postRegistro";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import getRegistroMotivoCancelamento from '../../service/getRegistroMotivoCancelamento';
import postCancelarRegistro from '../../service/postCancelarRegistro';

export default function RegistroPage() {
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const msgsDialog = useRef<Messages>(null);
  const [registro, setRegistro] = useState<any | null>(null);
  const [registroEdit, setRegistroEdit] = useState<any | null>(null);
  const labelButton = t('cadastrar')
  const [selectedMotivo, setSelectedMotivo] = useState<any>(null);
  const [motivos, setMotivos] = useState<any>([]);
  const [date, setDate] = useState<Date>(new Date());

  const footerContent = (
    <React.Fragment>
          <Button form='form-permission' style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Cancelar" onClick={() => cancelarRegistro()}   />
          <Button label="Fechar" icon="pi pi-times" style={{color:'#183462'}} onClick={() => resetForm()} className="p-button-text" />
    </React.Fragment>
    );

  const resetForm = () => {
  
    setRegistroEdit(undefined)

  }  

  const cancelarRegistro = () => {
    const cancelamento = {
      id: registroEdit.id,
      documento: registroEdit.documento,
      motivo: selectedMotivo
    }
    postCancelarRegistro(cancelamento).then(data => {
      if(data.includes('Erro')){
        if (msgs.current) {
          msgsDialog.current?.clear();
          msgsDialog.current?.show([
              { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
             
          ]);
        }
      }
      else{
        setRegistroEdit(null);
        setDate(new Date())
        
        if (msgs.current) {
          msgs.current?.clear();
          msgs.current?.show([
              { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
             
          ]);
        }
      }
    })
  }


  const edit = (registro:any) => {
    setRegistroEdit(registro);
  }

  useEffect(() => {
    getRegistroMotivoCancelamento().then( (data) => setMotivos(data))

  }, [])

  const saveRegistro = (registro:any) => {
    postRegistro(registro).then(data => {
      if(data.includes('Erro')){
        if (msgs.current) {
          msgs.current?.clear();
          msgs.current?.show([
              { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
             
          ]);
        }
      }
      else{
        setRegistro(null);
        if (msgs.current) {
          msgs.current?.clear();
          msgs.current?.show([
              { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
             
          ]);
        }
      }
    })
  }
  
  const startContent = (
    <React.Fragment>
        <Button label={labelButton} icon="pi pi-plus" style={{backgroundColor:'#183462'}} onClick={() => {setRegistro({})}}/>
    </React.Fragment>
);

  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-id-card" style={{ fontSize: '2.0rem' }}></i> {t('Negativação')}</h1>
            </div>     
          </div>
      </div>
      <div className="card">
        <Messages ref={msgs} />
        {!registro && (
          <>
            <Toolbar start={startContent} />
            <br></br>
            <br></br>
            <RegistroList date={date} onEdit={(registro:any) => { edit(registro)}}></RegistroList>
          </>
        )}
        {registro && (
          <>
           <RegistroCadastrar msgs={msgs} onSave={saveRegistro} registro={registro} onClose={() => {setRegistro(null)}}></RegistroCadastrar>
          </>
        )}
      </div>
      <Dialog header={t('cancelarRegistro')} visible={registroEdit} style={{ width: '80vw' }} onHide={() => resetForm()} footer={footerContent}>
      <Messages ref={msgsDialog} />
        <div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div className="flex-auto">
                <label htmlFor="documento" className="font-bold block mb-2">{t('documento')} </label>
                <InputText id="documento" disabled style={{width: '20rem'}} value={registroEdit?.documento}></InputText>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div className="flex-auto">
                <label htmlFor="nome" className="font-bold block mb-2">{t('nome')} </label>
                <InputText id="nome" disabled style={{width: '20rem'}} value={registroEdit?.nome}></InputText>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div className="flex-auto">
                <label htmlFor="contrato" className="font-bold block mb-2">{t('contrato')} </label>
                <InputText id="contrato" disabled style={{width: '20rem'}} value={registroEdit?.contrato}></InputText>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div className="flex-auto">
                <label htmlFor="valor" className="font-bold block mb-2">{t('valor')} </label>
                <InputText id="valor" disabled style={{width: '20rem'}} value={registroEdit?.valor}></InputText>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap" style={{flexGrow:'0.5'}}>
              <div>
                <label className="label-dados" style={{paddingLeft:'0'}}>{t('motivoCancelamento')}</label>
                <Dropdown style={{width: '50%'}} value={selectedMotivo} onChange={(e) => setSelectedMotivo(e.value)} options={motivos} optionLabel="label" placeholder="Selecione Motivo Cancelamento" 
                        filter className="w-full md:w-14rem" />  
              </div>
            </div>
          </div>
        </div>
      </Dialog>
                
    </Container>
  )
}

import { useTranslation } from "react-i18next";
import { useEffect} from "react";
import findFranquia from '../../service/findFranquia';
import { SortOrder } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { useGlobalState } from "../globalState/GlobalStateProvider";
import { GlobalSaveData } from "../globalState/GlobalSaveData";

const TopbarFranquia = () => {
  const { t } = useTranslation();
  const {state} = useGlobalState();

  const changeFranquia = (value:any) => {
    console.log(value)
    state.user.franquiaSelected = value
    GlobalSaveData("user", state.user);
    window.location.reload()
  }

  const getValue = () => {
    if(state.franquias && state.user.franquiaSelected){
      return state.franquias.filter((s:any) => s.nome === state.user.franquiaSelected.nome)[0]

    }
    return null
  }

  useEffect(() => {
    
    if(!state.franquias){
      const lazyFranquia = {
        first: 0,
        rows: 9999,
        page: 1,
        franquia: null,
        documento: null,
        sortField: 'nome',
        sortOrder: SortOrder.DESC
      };
      
      findFranquia(lazyFranquia).then((data) => {state.franquias = data.franquias; GlobalSaveData("franquias", data.franquias)})
    }
    
  },[])

  return (
    <div style={{paddingRight:'2rem'}}>
      <Dropdown value={getValue()} onChange={(e) => changeFranquia(e.value)} className="franquia " style={{ width: '300px'}} options={state.franquias} optionLabel="nome"  placeholder="Selecione Franquia" 
        filter showClear  />
      
    </div>
  );
};

export default TopbarFranquia;

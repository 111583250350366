import { http } from '../../config/axios'

 const findVeiculo = (params:any): Promise<any> => {
  const queryParams = params
                ? Object.keys(params)
                      .map((k) => encodeURIComponent(params[k]))
                      .join('/')
                : '';
  return http
    .get('api/veiculo/find/' + queryParams)
    .then((res) => {
      res.data.veiculos.forEach((p:any) => {
        p.kms.forEach((k:any) => {
          k.data = new Date(k.data)
        })
      })
      return res.data as any
    })
    .catch((error) => {
      console.error('Erro findVeiculo', error)
      throw Error(error.response.data)
    })
}

export default findVeiculo;

import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import findVeiculoVenda from '../../service/findVeiculoVenda';
import { Button } from 'primereact/button';
import { SortOrder } from 'primereact/api';
import putVeiculoVendaComunicar from '../../service/putVeiculoVendaComunicar';
import { Dialog } from 'primereact/dialog';
import React from 'react';



export default function VeiculoVendaList({onEdit, msgs}:{onEdit:(veiculoVenda:any)=>void, msgs:any}) {
  const { t } = useTranslation();
  const [veiculosVenda, setVeiculosVenda] = useState(undefined);
  const [erros, setErros] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: 'placa',
    sortOrder: SortOrder.DESC
  });


const cols = [
  { field: 'documento', header: 'Documento' },
  { field: 'nome', header: 'Nome' },
  { field: 'email', header: 'Email' },
  { field: 'inativo', header: 'Inativo' }
];
const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
const exportPdf = () => {
  import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
          const lazy = {
            first: 0,
            rows: totalRecords,
            page:  1,
            sortField: 'placa',
            sortOrder: SortOrder.DESC
          }
          findVeiculoVenda(lazy).then(data => {
            const doc : any = new jsPDF.default('portrait');
            doc.autoTable(exportColumns, data.clientes);
            doc.save('clientes.pdf');
          });
          
      });
  });
};

const exportExcel = () => {
import('xlsx').then((xlsx) => {
  const lazy = {
    first: 0,
    rows: totalRecords,
    page:  1,
    sortField: 'placa',
    sortOrder: SortOrder.DESC
  }
  findVeiculoVenda(lazy).then(data => {
    const users: any[] = []
    data.clientes.forEach((client:any) => users.push({nome:client.nome, email:client.email, documento:client.documento, inativo:client.inativo}))
    
    const worksheet = xlsx.utils.json_to_sheet(users);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
    });
    saveAsExcelFile(excelBuffer, 'clientes');
  });

    
});
};
const saveAsExcelFile = (excelBuffer: any, fileName: string) => {
import('file-saver').then((module) => {
    if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
});
};

const header = (
  <div className="flex-consulta align-items-center justify-content-end gap-2">
      <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
      <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
  </div>
);




  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: (event.first / event.rows) + 1,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };
  const onSort = (event:any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  const formatDate = (value: { toLocaleDateString: (arg0: string, arg1: { day: string; month: string; year: string; }) => any; }) => {
    return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
  };

  const dateBodyTemplate = (rowData: { dataVenda: any; }) => {
    return formatDate(rowData.dataVenda);
  };

  const erroBodyTemplate = (rowData:string) => {
    return rowData;
  };

  const valueBodyTemplate = (rowData: { valor: any; }) => {
    return rowData.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
  };

  const loadLazyData = () => {
    findVeiculoVenda(lazyState).then(data => {setTotalRecords(data.total);setVeiculosVenda(data.veiculosVenda)}).catch(() => {setTotalRecords(0);setVeiculosVenda(undefined)});
  }

  const enviar = (venda:any) => {
    putVeiculoVendaComunicar(venda)
    .then((data) => {
      msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
         
      ]);
    })
    .catch((erro) => {
      msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: erro, closable: false }
         
      ]);
    });
  }

  const actionTemplate = (venda:any) => {
    return (
       <>
      { venda.status === 'Incluído' && (
        <Button icon="pi pi-cloud-upload" onClick={() => {enviar(venda)}} style={{background:'transparent', color:'gray', border: 'none'}} />
     )}
     { venda.status === 'Erro' && (
        <Button icon="pi pi-search" onClick={() => {console.log(venda.erros);setErros(venda.erros)}} style={{background:'transparent', color:'gray', border: 'none'}} />
     )}
     { venda.status === 'Enviado' && (
        <Button icon="pi pi-trash" onClick={() => onEdit(venda)} style={{background:'transparent', color:'gray', border: 'none'}} />
      )}
     </>
    );
  };

  const footerContent = (
    <React.Fragment>
         
          <Button label="Fechar" icon="pi pi-times" style={{color:'#183462'}} onClick={() => resetForm()} className="p-button-text" />
    </React.Fragment>
    );

  const resetForm = () => {
    setErros(undefined);
  }  
  
  useEffect(() => {
    loadLazyData();
  },[lazyState])

  return (
    <div>
      <div>
        
        <br></br>
        <br></br>
        <DataTable value={veiculosVenda} first={lazyState.first} dataKey="id" paginator={true}  lazy={true}
                        emptyMessage="Sem registros" header={header}
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows} totalRecords={totalRecords} onPage={onPage}
                        tableStyle={{ minWidth: '75rem' }}>
           <Column  body={dateBodyTemplate} dataType="Date" header={t('dataVenda')}></Column>
           <Column field="placa" sortable header={t('placa')}  />
           <Column field="tipoSolicitante" sortable header={t('solicitante')}  />
           <Column body={valueBodyTemplate} sortable header={t('valor')}  />
           <Column field="status" sortable header={t('status')}  />
           <Column body={actionTemplate} />
        </DataTable> 
      </div>
      <Dialog header={t('erros')} visible={erros} style={{ width: '80vw' }} onHide={() => resetForm()} footer={footerContent}>
        <DataTable size="small" value={erros} tableStyle={{ minWidth: '50rem' }}>
           <Column  body={erroBodyTemplate}  header={t('erro')}></Column>
         </DataTable> 
      </Dialog>
    </div>
  )
}



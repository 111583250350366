import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { ICadastralPFEmail } from "../../../interface/CadastralPFEmail";

interface ICadastralPFEmailProps {
  data: ICadastralPFEmail | null;
}

function registerAdapter(data: ICadastralPFEmail | null) {
  if (data) {
    return [{ ...data.EMAILS.REGISTRO_1 }, { ...data.EMAILS.REGISTRO_2 }];
  }
  return [];
}

export const CadastralPFEmail = ({ data }: ICadastralPFEmailProps) => {
  return (
    <div>
      <Panel header="Registro cadastral">
        {data?.EMAILS && (
          <DataTable
            size="small"
            tableStyle={{ minWidth: "50rem" }}
            value={registerAdapter(data)}
          >
            <Column field="NOMERAZAO" header="Nome razão social" />
            <Column field="CPFCNPJ" header="CPF/CNPJ" />
            <Column field="TIPOPESSOA" header="Tipo pessoa" />
            <Column field="UF" header="UF" />
            <Column field="CIDADE" header="Cidade" />
          </DataTable>
        )}

        {!data?.EMAILS && <p>Nenhum dado encontrado</p>}
      </Panel>
    </div>
  );
};

import { Panel } from "primereact/panel";

import styles from "./styles.module.css";
import { Description } from "../Description";
import { ListDescriptionsProps } from "./type";

export function ListDescriptions({ categoria, dados }: ListDescriptionsProps) {
  const gridContainer =
    dados.length === 1
      ? [styles.gridContainer, styles.oneColumn].join(" ")
      : styles.gridContainer;

  return (
    <div className={styles.wrapper} key={categoria}>
      <Panel header={categoria}>
        <div className={gridContainer}>
          {dados.map(({ label, value }) => (
            <Description key={label} label={label} value={value} />
          ))}
        </div>
      </Panel>
    </div>
  );
}

import { http } from '../../config/axios'

export default function postVeiculoVenda(veiculoVenda: any): Promise<string> {
  return http
    .post('api/veiculovenda', veiculoVenda)
    .then((res) => {
      return res.data as string
    })
    .catch((error) => {
      console.error('Erro post veiculo venda', error)
      throw Error(error.response.data)
    })
}

import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import findVeiculo from '../../service/findVeiculo';
import { Button } from 'primereact/button';
import { SortOrder } from 'primereact/api';




export default function VeiculoList({onEdit}:{onEdit:(veiculo:any)=>void}) {
  const { t } = useTranslation();
  const [veiculos, setVeiculos] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: 'placa',
    sortOrder: SortOrder.DESC
  });


const cols = [
  { field: 'documento', header: 'Documento' },
  { field: 'nome', header: 'Nome' },
  { field: 'email', header: 'Email' },
  { field: 'inativo', header: 'Inativo' }
];
const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
const exportPdf = () => {
  import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
          const lazy = {
            first: 0,
            rows: totalRecords,
            page:  1,
            sortField: 'placa',
            sortOrder: SortOrder.DESC
          }
          findVeiculo(lazy).then(data => {
            const doc : any = new jsPDF.default('portrait');
            doc.autoTable(exportColumns, data.clientes);
            doc.save('clientes.pdf');
          });
          
      });
  });
};

const exportExcel = () => {
import('xlsx').then((xlsx) => {
  const lazy = {
    first: 0,
    rows: totalRecords,
    page:  1,
    sortField: 'placa',
    sortOrder: SortOrder.DESC
  }
  findVeiculo(lazy).then(data => {
    const users: any[] = []
    data.clientes.forEach((client:any) => users.push({nome:client.nome, email:client.email, documento:client.documento, inativo:client.inativo}))
    
    const worksheet = xlsx.utils.json_to_sheet(users);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
    });
    saveAsExcelFile(excelBuffer, 'clientes');
  });

    
});
};
const saveAsExcelFile = (excelBuffer: any, fileName: string) => {
import('file-saver').then((module) => {
    if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
});
};

const kmBodyTemplate = (rowData: { kms: any; }) => {
  return rowData.kms.length > 0 ? rowData.kms[rowData.kms.length - 1].quilometragem : 0;
};

const header = (
  <div className="flex-consulta align-items-center justify-content-end gap-2">
      <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
      <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
  </div>
);




  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: (event.first / event.rows) + 1,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };
  const onSort = (event:any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  

  const loadLazyData = () => {
    findVeiculo(lazyState).then(data => {setTotalRecords(data.total);setVeiculos(data.veiculos)}).catch(() => {setTotalRecords(0);setVeiculos(undefined)});
  }

  const editBodyTemplate = (client:any) => {
    return <Button icon="pi pi-pencil" onClick={() => onEdit(client)} style={{background:'transparent', color:'gray', border: 'none'}} />;
  };
  
  useEffect(() => {
    loadLazyData();
  },[lazyState])

  return (
    <div>
      <div>
        
        <br></br>
        <br></br>
        <DataTable value={veiculos} first={lazyState.first} dataKey="id" paginator={true}  lazy={true}
                        emptyMessage="Sem registros" header={header}
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows} totalRecords={totalRecords} onPage={onPage}
                        tableStyle={{ minWidth: '75rem' }}>
           <Column field="placa" sortable header={t('placa')}  />
           <Column field="marca" sortable header={t('marca')}  />
           <Column field="modelo" sortable header={t('modelo')}  />
           <Column body={kmBodyTemplate} header={t('KM')}  />
           <Column body={editBodyTemplate} />
        </DataTable> 
      </div>
    </div>
  )
}



import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { Controller, useForm} from 'react-hook-form';
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import getRegistroNatureza from "../../service/getRegistroNatureza";
import getRegistroParticipacao from "../../service/getRegistroParticipacao";
import getViaCep from "../../service/getViaCep";
import getRegistroTipoEmail from "../../service/getRegistroTipoEmail";
import { InputNumber } from "primereact/inputnumber";


export default function RegistroCadastrar({registro, onClose, onSave, msgs}:{registro:any, onClose: () => void, onSave: (user:any) => void, msgs:any}) {
  const { t } = useTranslation();
  const [isCpf, setIsCpf] = useState(true); 
  const [naturezas, setNaturezas] = useState<any>([]);
  const [participacoes, setParticipacoes] = useState<any>([]);
  const [selectedSms, setselectedSms] = useState();
  const [selectedEmail, setselectedEmail] = useState();
  const [tiposEmail, setTiposEmail] = useState<any>([]);
  const tiposNotificacao = [
      { name: 'Selecione', code: null },
      { name: 'Sim', code: 'S' },
      { name: 'Não', code: 'N' }
  ];
  const defaultValues = {
    nome: '',
    documento: '',
    nascimento: null,
    valor: null,
    ocorrencia: null,
    contrato: '',
    natureza: null,
    participacao: null,
    cep: '',
    bairro: '',
    logradouro: '',
    numero: '',
    uf: '',
    cidade: '',
    complemento: '',
    ddd1: '',
    ddd2: '',
    ddd3: '',
    celular1: '',
    celular2: '',
    celular3: '',
    tipoEmail1: null,
    email1: '',
    tipoEmail2: null,
    email2: '',
    tipoEmail3: null,
    email3: ''

  };
  const cnpj = (v:string) =>{
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/,"$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    return v
  }

  const cpf = (v:string) => {
      v=v.replace(/\D/g,"")                    //Remove tudo o que não é dígito
      v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
      v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                                              //de novo (para o segundo bloco de números)
      v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
      return v
  }

  const validDocument = (document:string) => {
    let valid = true
    if(document.length === 14){
      valid = verifyCPF(document)
    }
    else{
      valid = verifyCnpj(document)
    }
    if(valid){
      msgs.current?.clear();
    }
    else{
      msgs.current?.clear();
      msgs.current?.show([
         { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Documento inválido', closable: false }
             
      ]);
    }
  }

  const verifyCnpj : any = (cnpj:string) => {
 
    cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj === '') return false;
     
    if (cnpj.length !== 14)
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;
         
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho) + '';
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(0)))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(1)))
          return false;
           
    return true;
    

}

  const  verifyCPF = (strCPF:string) => {
    strCPF = strCPF.replaceAll('.','').replace('-','')
    let Soma;
    let Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;
    if (strCPF == "11111111111") return false;
    if (strCPF == "22222222222") return false;
    if (strCPF == "33333333333") return false;
    if (strCPF == "44444444444") return false;
    if (strCPF == "55555555555") return false;
    if (strCPF == "66666666666") return false;
    if (strCPF == "77777777777") return false;
    if (strCPF == "88888888888") return false;
    if (strCPF == "99999999999") return false;

    for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
}

  const maskCpfCnpj = (input:string) =>{
    if(input.length > 14){
      setIsCpf(false)
      return cnpj(input)
    }
    else{
      setIsCpf(true)
      return cpf(input)
    }
  }

  const {
    control,
    formState: { errors},
    handleSubmit,
    setValue
} = useForm({ defaultValues });

  const onSubmit = (data:any) => {
    console.log(data)
    const registro = {
      documento: data.documento,
      nome: data.nome,
      dataNascimento: data.nascimento,
      logradouro: data.logradouro,
      cep: data.cep,
      bairro: data.bairro,
      numeroEndereco: data.numero,
      complemento: data.complemento,
      valor: data.valor,
      contrato: data.contrato,
      ocorrencia: data.ocorrencia,
      participacao: data.participacao,
      natureza: data.natureza,
      ddd1: data.ddd1,
      ddd2: data.ddd2,
      ddd3: data.ddd3,
      celular1: data.celular1,
      celular2: data.celular2,
      celular3: data.celular3,
      tipoEmail1: data.tipoEmail1,
      email1: data.email1,
      tipoEmail2: data.tipoEmail2,
      email2: data.email2,
      tipoEmail3: data.tipoEmail3,
      email3: data.email3
    }
    window.scrollTo(0, 0);
    onSave(registro);
  };

  const getFormErrorMessage = (name:string) => {
    type ObjectKey = keyof typeof errors;
    const error = errors[Object.keys(errors).find(k => k === name) as ObjectKey]
    return error ? <small className="p-error">{error.message}</small> : <small className="p-error">&nbsp;</small>;
  };

  const buscarCep = (cep:string) => {
    if(cep.length == 8){
      getViaCep(cep).then((data) => {
        if(data){
          setValue('bairro', data.bairro) 
          setValue('cidade', data.localidade)
          setValue('uf', data.uf)
          setValue('logradouro', data.logradouro)
        }
        
      }).catch((erro) => console.log(erro))
    }
    
  }

  useEffect(() => {
    getRegistroNatureza().then( (data) => setNaturezas(data))
    getRegistroParticipacao().then( (data) => setParticipacoes(data))
    getRegistroTipoEmail().then((data) => setTiposEmail(data))
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
      <div>
        <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <Controller
                    name="documento"
                    control={control}
                    rules={{ required: 'Documento é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.documento })}></label>
                            <span className="">
                            <div>
                                <label htmlFor="documento" className="font-bold block mb-2 required">{t('documento')} </label>
                                <InputText maxLength={18} max={18} onKeyUp={() => { field.onChange(maskCpfCnpj(field.value))}} style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onBlur={(e) => validDocument(e.target.value)} onChange={(e) => field.onChange(e.target.value)} />
                            </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                
                  
              
            </div>
            <div className="col">
              <Controller
                    name="nome"
                    control={control}
                    rules={{ required: 'Nome é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.nome })}></label>
                            <span className="">
                            <div>
                              <label htmlFor="nome" className="font-bold block mb-2 required">{t('nome')} </label>
                              <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
              
            </div>
            {isCpf && (

            <div className="col">
              <Controller
                    name="nascimento"
                    control={control}
                    rules={{ required: 'Nascimento é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.nascimento })}></label>
                            <span className="">
                            <div>
                                <label htmlFor="nascimento" className="font-bold block mb-2 required">{t('dt_nascimento')} </label>
                                <Calendar id='data' value={field.value}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                            </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
              
            </div>
            )}
        </div>
        <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <Controller
                    name="contrato"
                    control={control}
                    rules={{ required: 'Contrato é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.contrato })}></label>
                            <span className="">
                            <div>
                                <label htmlFor="contrato" className="font-bold block mb-2 required">{t('contrato')} </label>
                                <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                            </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                
                  
              
            </div>
            <div className="col">
              <Controller
                    name="valor"
                    control={control}
                    rules={{ required: 'Valor é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.valor })}></label>
                            <span className="">
                            <div>
                              <label htmlFor="nome" className="font-bold block mb-2 required">{t('valor')} </label>
                              <InputNumber id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
                              </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
              
            </div>
            <div className="col">
              <Controller
                    name="ocorrencia"
                    control={control}
                    rules={{ required: 'Ocorrência é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.ocorrencia })}></label>
                            <span className="">
                            <div>
                                <label htmlFor="nascimento" className="font-bold block mb-2 required">{t('dt_ocorrencia')} </label>
                                <Calendar id='data' value={field.value}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                            </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
              
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <Controller
                    name="natureza"
                    control={control}
                    rules={{ required: 'Natureza é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.natureza })}></label>
                            <span className="">
                            <div>
                                <label htmlFor="natureza" className="font-bold block mb-2 required">{t('natureza')} </label>
                                <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => field.onChange(e.value)} options={naturezas} optionLabel="label" placeholder="Selecione Natureza" 
                                  filter className="w-full md:w-14rem" />  
                            </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                
                  
              
            </div>
            <div className="col p-fluid flex flex-wrap">
              <Controller
                    name="participacao"
                    control={control}
                    rules={{ required: 'Participação é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.participacao })}></label>
                            <span className="">
                            <div>
                                <label htmlFor="participacao" className="font-bold block mb-2 required">{t('participacao')} </label>
                                <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => field.onChange(e.value)} options={participacoes} optionLabel="label" placeholder="Selecione Participação" 
                                  filter className="w-full md:w-14rem" />  
                            </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                
                  
              
            </div>
            
           
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <Controller
                    name="cep"
                    control={control}
                    rules={{ required: 'CEP é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.cep })}></label>
                            <span className="">
                            <div>
                                <label htmlFor="cep" className="font-bold block mb-2 required">{t('cep')} </label>
                                <InputText maxLength={8} max={8} style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => {field.onChange(e.target.value); buscarCep(e.target.value);}} />
                            </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                
                  
              
            </div>
            <div className="col">
              <Controller
                    name="logradouro"
                    control={control}
                    rules={{ required: 'Logradouro é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.logradouro })}></label>
                            <span className="">
                            <div>
                              <label htmlFor="logradouro" className="font-bold block mb-2 required">{t('logradouro')} </label>
                              <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
              
            </div>
            <div className="col">
              <Controller
                    name="numero"
                    control={control}
                    rules={{ required: 'Número é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.numero })}></label>
                            <span className="">
                            <div>
                                <label htmlFor="numero" className="font-bold block mb-2 required">{t('numero')} </label>
                                <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                            </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
              
            </div>
            <div className="col">
              <Controller
                    name="bairro"
                    control={control}
                    rules={{ required: 'Bairro é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.bairro })}></label>
                            <span className="">
                            <div>
                                <label htmlFor="bairro" className="font-bold block mb-2 required">{t('bairro')} </label>
                                <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                            </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
              
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <Controller
                    name="complemento"
                    control={control}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.complemento })}></label>
                            <span className="">
                            <div>
                                <label htmlFor="complemento" className="font-bold block mb-2">{t('complemento')} </label>
                                <InputText maxLength={8} max={8} style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => {field.onChange(e.target.value)}} />
                            </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                
                  
              
            </div>
            <div className="col">
              <Controller
                    name="cidade"
                    control={control}
                    rules={{ required: 'Cidade é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.cidade })}></label>
                            <span className="">
                            <div>
                              <label htmlFor="cidade" className="font-bold block mb-2 required">{t('cidade')} </label>
                              <InputText disabled style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
              
            </div>
            <div className="col">
              <Controller
                    name="uf"
                    control={control}
                    rules={{ required: 'UF é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.uf })}></label>
                            <span className="">
                            <div>
                                <label htmlFor="uf" className="font-bold block mb-2 required">{t('uf')} </label>
                                <InputText disabled style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                            </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
              
            </div>
            
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados" style={{paddingLeft:'0'}}>{t('notificacao_sms')}</label>
                <Dropdown value={selectedSms} onChange={(e) => setselectedSms(e.value)} options={tiposNotificacao} optionLabel="name" 
                    placeholder="Notificação SMS" optionValue='code' className="w-full md:w-14rem" />
              </div>
              
            </div>
            <div className="col">
              <div>
                <label className="label-dados" style={{paddingLeft:'0'}}>{t('notificacao_email')}</label>
                <Dropdown value={selectedEmail} onChange={(e) => setselectedEmail(e.value)} options={tiposNotificacao} optionLabel="name" 
                    placeholder="Notificação E-mail" optionValue='code' className="w-full md:w-14rem" />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              {selectedSms === 'S' && (
                <>
                  <div className="grid">
                    <div className="col" style={{width:'20px', flexGrow:'0.2'}}>
                      <Controller
                        name="ddd1"
                        control={control}
                        render={({ field, fieldState }) => (
                            <>
                                <label htmlFor={field.name} className={classNames({ 'p-error': errors.ddd1 })}></label>
                                <span className="">
                                <div>
                                    <label htmlFor="ddd1" className="font-bold block mb-2">{t('ddd')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                                </span>
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                      />
                    </div>
                    <div className="col" style={{paddingLeft: '0'}}>
                      <Controller
                          name="celular1"
                          control={control}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.celular1 })}></label>
                                  <span className="">
                                  <div>
                                      <label htmlFor="celular1" className="font-bold block mb-2">{t('celular')} </label>
                                      <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                  </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                        />
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col" style={{width:'20px', flexGrow:'0.2'}}>
                      <Controller
                        name="ddd2"
                        control={control}
                        render={({ field, fieldState }) => (
                            <>
                                <label htmlFor={field.name} className={classNames({ 'p-error': errors.ddd2 })}></label>
                                <span className="">
                                <div>
                                    <label htmlFor="ddd2" className="font-bold block mb-2">{t('ddd')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                                </span>
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                      />
                    </div>
                    <div className="col" style={{paddingLeft: '0'}}>
                      <Controller
                          name="celular2"
                          control={control}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.celular2 })}></label>
                                  <span className="">
                                  <div>
                                      <label htmlFor="celular2" className="font-bold block mb-2">{t('celular')} </label>
                                      <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                  </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                        />
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col" style={{width:'20px', flexGrow:'0.2'}}>
                      <Controller
                        name="ddd3"
                        control={control}
                        render={({ field, fieldState }) => (
                            <>
                                <label htmlFor={field.name} className={classNames({ 'p-error': errors.ddd3 })}></label>
                                <span className="">
                                <div>
                                    <label htmlFor="ddd3" className="font-bold block mb-2">{t('ddd')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                                </span>
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                      />
                    </div>
                    <div className="col" style={{paddingLeft: '0'}}>
                      <Controller
                          name="celular3"
                          control={control}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.celular3 })}></label>
                                  <span className="">
                                  <div>
                                      <label htmlFor="celular3" className="font-bold block mb-2">{t('celular')} </label>
                                      <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                  </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                        />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col p-fluid flex flex-wrap">
              {selectedEmail === 'S' && (
                <>
                  <div className="grid">
                    <div className="col" style={{width:'30px', flexGrow:'0.4'}}>
                      <Controller
                        name="tipoEmail1"
                        control={control}
                        render={({ field, fieldState }) => (
                            <>
                                <label htmlFor={field.name} className={classNames({ 'p-error': errors.tipoEmail1})}></label>
                                <span className="">
                                <div>
                                    <label htmlFor="tipoEmail1" className="font-bold block mb-2">{t('tipo')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => field.onChange(e.value)} options={tiposEmail} optionLabel="label" placeholder="Selecione" 
                                      filter className="w-full md:w-14rem" />  
                                </div>
                                </span>
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                      />
                    </div>
                    <div className="col" style={{paddingLeft: '0'}}>
                      <Controller
                          name="email1"
                          control={control}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.email1 })}></label>
                                  <span className="">
                                  <div>
                                      <label htmlFor="email1" className="font-bold block mb-2">{t('email')} </label>
                                      <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                  </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                        />
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col" style={{width:'30px', flexGrow:'0.4'}}>
                      <Controller
                        name="tipoEmail2"
                        control={control}
                        render={({ field, fieldState }) => (
                            <>
                                <label htmlFor={field.name} className={classNames({ 'p-error': errors.tipoEmail2})}></label>
                                <span className="">
                                <div>
                                    <label htmlFor="tipoEmail2" className="font-bold block mb-2">{t('tipo')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => field.onChange(e.value)} options={tiposEmail} optionLabel="label" placeholder="Selecione" 
                                      filter className="w-full md:w-14rem" />  
                                </div>
                                </span>
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                      />
                    </div>
                    <div className="col" style={{paddingLeft: '0'}}>
                      <Controller
                          name="email2"
                          control={control}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.email2 })}></label>
                                  <span className="">
                                  <div>
                                      <label htmlFor="email2" className="font-bold block mb-2">{t('email')} </label>
                                      <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                  </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                        />
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col" style={{width:'30px', flexGrow:'0.4'}}>
                      <Controller
                        name="tipoEmail3"
                        control={control}
                        render={({ field, fieldState }) => (
                            <>
                                <label htmlFor={field.name} className={classNames({ 'p-error': errors.tipoEmail3})}></label>
                                <span className="">
                                <div>
                                    <label htmlFor="tipoEmail3" className="font-bold block mb-2">{t('tipo')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => field.onChange(e.value)} options={tiposEmail} optionLabel="label" placeholder="Selecione" 
                                      filter className="w-full md:w-14rem" />  
                                </div>
                                </span>
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                      />
                    </div>
                    <div className="col" style={{paddingLeft: '0'}}>
                      <Controller
                          name="email3"
                          control={control}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.email3 })}></label>
                                  <span className="">
                                  <div>
                                      <label htmlFor="email3" className="font-bold block mb-2">{t('email')} </label>
                                      <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                  </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                        />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        <br></br>
        <br></br>
        <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar" type="submit"   />
            <Button icon="pi pi-times" onClick={onClose} rounded outlined severity="danger" aria-label="Cancel" />
        </div> 
      </div>
    </form>
    
  )
}


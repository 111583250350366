import { Chip } from "primereact/chip";

type InfoDisplayProps = React.ComponentProps<"div"> & {
  label: string;
  value: string;
};

export const InfoDisplay = ({
  label = "",
  value = "",
  ...props
}: InfoDisplayProps) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        position: "relative",
      }}
      {...props}
    >
      <p
        style={{
          fontSize: "0.875rem",
          fontWeight: "500",
          color: "#0F172A",
          margin: 0,
        }}
      >
        {label}
      </p>

      <Chip
        style={{
          width: "100%",
          display: "flex",
          margin: 0,
        }}
        label={value}
      />
    </div>
  );
};

import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { Controller, useForm} from 'react-hook-form';
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Image } from 'primereact/image';
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useRef, useState } from "react";
import { InputMask } from "primereact/inputmask";
import { FileUpload, FileUploadHandlerEvent, FileUploadHeaderTemplateOptions, FileUploadSelectEvent, FileUploadUploadEvent, ItemTemplateOptions } from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";
import { Carousel } from "primereact/carousel";
export default function LeilaoCadastrar({leilao, onClose, onSave, msgs}:{leilao:any, onClose: () => void, onSave: (user:any) => void, msgs:any}) {
  const { t } = useTranslation();
  const [totalSize, setTotalSize] = useState(0);
  const [files, setFiles] = useState<any>(leilao.id && leilao.imagens ? leilao.imagens : []);
  const fileUploadRef = useRef<FileUpload>(null);
  
  const onTemplateSelect = (e: FileUploadSelectEvent) => {
    let _totalSize = totalSize;
    let files = e.files;

    for (let i = 0; i < files.length; i++) {
        _totalSize += files[i].size || 0;
    }

    setTotalSize(_totalSize);
   };

   const uploadHandler = async (e: FileUploadHandlerEvent) => {
        if(e.files.length + files.length > 4){
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Limite de imagens atingido', closable: false }
               
            ]);
        }
        else{
            e.files.forEach(async (file:any) => {
                const reader = new FileReader();
                let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
    
                reader.readAsDataURL(blob);
    
                reader.onloadend = function () {
                    const base64data = reader.result;
                    setFiles((files: any) => [...files,base64data])
                };
            });    
            if(fileUploadRef && fileUploadRef.current){
                fileUploadRef.current.clear()
            }
        }
        
   }

    const onTemplateUpload = (e: FileUploadUploadEvent) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize); 
    };

    const onTemplateRemove = (file: File, callback: Function) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex-score align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
        const file = inFile as any;
        return (
            <div className="flex-score align-items-center flex-wrap">
                <div className="flex-score align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex-score flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex-score align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    arrate e solte sua imagem aqui
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };
  
  const formatDate = (value: { toLocaleDateString: (arg0: string, arg1: { day: string; month: string; year: string; }) => any; }) => {
    return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
  };
  const defaultValues = {
    placa: '',
    chassi:'',
    modelo: '',
    marca: '',
    cor: '',
    condicao: '',
    leiloeiro: '',
    combustivel: '',
    comitente: '',
    lote: null,
    anoModelo: null,
    anoFabricacao: null,
    dataLeilao: new Date('null'),
    observacoes: ''
  };
  defaultValues.placa = leilao.placa ? leilao.placa.toString() : ''
  defaultValues.chassi = leilao.chassi ? leilao.chassi.toString() : ''
  defaultValues.modelo = leilao.modelo ? leilao.modelo.toString() : ''
  defaultValues.marca = leilao.marca ? leilao.marca.toString() : ''
  defaultValues.observacoes = leilao.observacoes ? leilao.observacoes.toString() : ''
  defaultValues.cor = leilao.cor ? leilao.cor.toString() : ''
  defaultValues.condicao = leilao.condicao ? leilao.condicao.toString() : ''
  defaultValues.leiloeiro = leilao.leiloeiro ? leilao.leiloeiro.toString() : ''
  defaultValues.combustivel = leilao.combustivel ? leilao.combustivel.toString() : ''
  defaultValues.comitente = leilao.comitente ? leilao.comitente.toString() : ''
  defaultValues.lote = leilao.lote ? leilao.lote : null
  defaultValues.anoModelo = leilao.anoModelo ? leilao.anoModelo : null
  defaultValues.anoFabricacao = leilao.anoFabricacao ? leilao.anoFabricacao : null
  
  const {
      control,
      formState: { errors},
      handleSubmit,
      setValue
  } = useForm({ defaultValues });

  const onSubmit = (data:any) => {
      data.placa && save(data);
  };

 
  const save = (data:any) => {
    const lei = {
      id: leilao.id? leilao.id : undefined,
      placa: data.placa,
      chassi: data.chassi,
      cor: data.cor,
      leiloeiro: data.leiloeiro,
      combustivel: data.combustivel,
      comitente: data.comitente,
      marca: data.marca,
      modelo: data.modelo,
      condicao: data.condicao,
      anoFabricacao: data.anoFabricacao,
      anoModelo: data.anoModelo,
      dataLeilao: data.dataLeilao,
      lote:data.lote,
      imagens: files,
      observacoes:data.observacoes
    }
    window.scrollTo(0, 0);
    onSave(lei);
  }

  const fileTemplate = (file: any) => {
    return (
        <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
            <div className="mb-3">
            <Image src={file as string} alt="imagem" width="100rem" height="100rem" preview/>
            </div>
            <div>
                <div className="mt-5 flex-score flex-wrap gap-2 justify-content-center">
                    <Button className="button_relatorio" style={{color:'red'}} icon="pi pi-trash" rounded outlined severity="danger" onClick={() => setFiles(files.filter((f:string) => f !== file))}/>
                </div>
            </div>
        </div>
    );
 };

  useEffect(() => {
    if(leilao.dataLeilao){
      setValue('dataLeilao', new Date(leilao.dataLeilao) );
    }
  }, [])
  const getFormErrorMessage = (name:string) => {
    type ObjectKey = keyof typeof errors;
    const error = errors[Object.keys(errors).find(k => k === name) as ObjectKey]
    return error ? <small className="p-error">{error.message}</small> : <small className="p-error">&nbsp;</small>;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
        <div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                
                  <Controller
                      name="placa"
                      control={control}
                      rules={{ required: 'Placa é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.placa })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="placa" className="font-bold block mb-2 required">{t('placa')} </label>
                                <InputMask disabled={leilao.id} id={field.name}  value={field.value} className={classNames({ 'p-invalid': fieldState.error })} mask="aaa-9*99" onChange={(e) => field.onChange(e.target.value)}></InputMask>
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                    
               
              </div>
              <div className="col">
                <Controller
                      name="chassi"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.chassi })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="chassi" className="font-bold block mb-2">{t('chassi')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
              <div className="col">
                <Controller
                      name="lote"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.lote })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="lote" className="font-bold block mb-2">{t('lote')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
              <div className="col">
                <Controller
                      name="dataLeilao"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.dataLeilao })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="dataLeilao" className="font-bold block mb-2">{t('dataLeilao')} </label>
                                  <Calendar id='data' value={field.value}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                
                  <Controller
                      name="marca"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.marca })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="marca" className="font-bold block mb-2">{t('marca')} </label>
                                <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                    
               
              </div>
              <div className="col">
                <Controller
                      name="modelo"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.modelo })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="modelo" className="font-bold block mb-2">{t('modelo')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
              <div className="col">
                <Controller
                      name="anoModelo"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.anoModelo })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="anoModelo" className="font-bold block mb-2">{t('anoModelo')} </label>
                                  <InputNumber style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} size={4} maxLength={4} max={9999}  useGrouping={false}  />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
              <div className="col">
                <Controller
                      name="anoFabricacao"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.anoFabricacao })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="anoFabricacao" className="font-bold block mb-2">{t('anoFabricacao')} </label>
                                  <InputNumber style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} size={4} maxLength={4} max={9999}  useGrouping={false}  />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
              <div className="col p-fluid flex flex-wrap">
                
                  <Controller
                      name="cor"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.cor })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="cor" className="font-bold block mb-2">{t('cor')} </label>
                                <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                    
               
              </div>
            </div>
            <div className="grid">
              
              <div className="col">
                <Controller
                      name="condicao"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.condicao })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="condicao" className="font-bold block mb-2">{t('condicao')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
              <div className="col">
                <Controller
                      name="leiloeiro"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.leiloeiro })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="leiloeiro" className="font-bold block mb-2">{t('leiloeiro')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
              <div className="col">
                <Controller
                      name="combustivel"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.combustivel })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="combustivel" className="font-bold block mb-2">{t('combustivel')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
              <div className="col">
                <Controller
                      name="comitente"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.comitente })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="comitente" className="font-bold block mb-2">{t('comitente')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
            </div>
            <div className="grid">
            
            <div className="col">
                <Controller
                      name="observacoes"
                      control={control}
                      
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.observacoes })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="observacoes" className="font-bold block mb-2">{t('observacoes')} </label>
                                  <InputTextarea style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
            </div>
            <div className="grid">
                <div style={{minWidth: '800px'}}>
                    <FileUpload ref={fileUploadRef} name="demo[]" customUpload uploadHandler={uploadHandler} multiple accept="image/*" maxFileSize={1000000}
                        onUpload={onTemplateUpload}  onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                        headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                </div>
            </div>
            <br></br>
            <br></br>
            <div className="card">
                <Carousel value={files} numVisible={3} numScroll={3} itemTemplate={fileTemplate} />
            </div>
          
          
          
        </div>
        <br></br>
        <br></br>
      
        <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar" type="submit"   />
            <Button icon="pi pi-times" onClick={onClose} rounded outlined severity="danger" aria-label="Cancel" />
        </div> 
    </form>
    
  )
}



import { Panel } from "primereact/panel";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import "../styles.css";
import { ListDescriptions } from "../../../../components/ListDescriptions";
import { Retorno, DadosProps, Gps353Props, CategoriaDados } from "./types";

const formatarDadosNaoArray = (retorno: Retorno): CategoriaDados[] => {
  const labelMap: { [key: string]: string } = {
    "RESUMO-TITULOS-PROTESTADOS": "Resumo de Títulos Protestados",
    REGISTRO: "Registro",
    TIPOREGISTRO: "Tipo de Registro",
    TAMANHOREGISTRO: "Tamanho do Registro",
    "RESUMO-OCORRENCIAS-DE-DEBITOS": "Resumo de Ocorrências de Débitos",
    "PAINEL-CONTROLE-POSITIVO": "Painel de Controle Positivo",
    "NOTA-COMPORTAMENTO-FATURA-EM-ATRASO":
      "Nota Comportamento Fatura em Atraso",
    "NOTA-COMPORTAMENTO-CONTRATOS-RECENTES":
      "Nota Comportamento Contratos Recentes",
    NOTA: "Nota",
    IDENTIFICACAO: "Identificação",
    DOCUMENTO: "Documento",
    DATAEMISSAORG: "Data de Emissão do RG",
    DATANASCIMENTO: "Data de Nascimento",
    NOMEMAE: "Nome da Mãe",
    SITUACAORECEITA: "Situação Receita",
    ESTADOCIVIL: "Estado Civil",
    NUMERODEPENDENTES: "Número de Dependentes",
    GRAUINSTRUCAO: "Grau de Instrução",
    NUMERORG: "Número do RG",
    REGIAOCPF: "Região do CPF",
    UNIDADEFEDARATIVARG: "Unidade Federativa do RG",
    DATAATUALIZACAO: "Data de Atualização",
    NOME: "Nome",
    TITULOELEITOR: "Título de Eleitor",
    ORGAOEMISSOR: "Órgão Emissor",
    SEXOCONSULTADO: "Sexo Consultado",
    "DEVOLUCOES-INFORMADAS-PELO-USUARIO": "Devoluções Informadas pelo Usuário",
    "RESUMO-DEVOLUCOES-INFORMADA-PELO-USUARIO":
      "Resumo de Devoluções Informadas pelo Usuário",
    "MENSAGEM-REGISTRO": "Mensagem de Registro",
    LOCALIZACAO: "Localização",
    COMPLEMENTO: "Complemento",
    UNIDADEFEDERATIVA: "Unidade Federativa",
    NUMEROLOGRADOURO: "Número do Logradouro",
    "TELEFONE-1": "Telefone 1",
    "TELEFONE-2": "Telefone 2",
    "TELEFONE-3": "Telefone 3",
    DDD1: "DDD Telefone 1",
    DDD2: "DDD Telefone 2",
    DDD3: "DDD Telefone 3",
    TIPOLOGRADOURO: "Tipo de Logradouro",
    NOMELOGRADOURO: "Nome do Logradouro",
    CEP: "CEP",
    BAIRRO: "Bairro",
    CIDADE: "Cidade",
    "SCORE-CLASSIFICACAO-VARIOS-MODELOS": "Score Classificação Vários Modelos",
    TIPOSCORE: "Tipo de Score",
    TEXTO: "Texto",
    SCORE: "Score",
    CLASSIFICACAONUMERICA: "Classificação Numérica",
    MODELOSCORE: "Modelo de Score",
    PROBABILIDADE: "Probabilidade",
    DESCRICAONATUREZA: "Descrição da Natureza",
    PLANOEXECUCAO: "Plano de Execução",
    CODIGONATUREZAMODELO: "Código da Natureza do Modelo",
    CLASSIFICACAOALFABETICA: "Classificação Alfabética",
    VALOR: "Valor",
    NOMESCORE: "Nome do Score",
    TEXTO2: "Texto Secundário",
    "CONSULTAS-ANTERIORES": "Consultas Anteriores",
    "RELACAO-FALENCIA-RECUPERACAO-JUDICIAL":
      "Relação de Falência/Recuperação Judicial",
    "NOME-DOCUMENTOS": "Nome de Documentos",
    "RELACAO-DEVOLUCOES-INFORMADAS-PELO-CCF":
      "Relação de Devoluções Informadas pelo CCF",
    "RESUMO-DEVOLUCOES-INFORMADAS-PELO-CCF":
      "Resumo de Devoluções Informadas pelo CCF",
    "RESUMO-DE-ACOES-CIVEIS": "Resumo de Ações Cíveis",
    "TITULOS-PROTESTADOS": "Títulos Protestados",
    "CHEQUES-SUSTADOS-PELO-MOTIVO-21": "Cheques Sustados pelo Motivo 21",
    DEBITOS: "Débitos",
    "RELACAO-DE-ACOES-CIVEIS": "Relação de Ações Cíveis",
    "RESUMOCONSULTAS-ANTERIORES-90-DIAS":
      "Resumo de Consultas Anteriores (90 dias)",
    "PARTICIPACOES-DO-DOCUMENTO-CONSULTADO":
      "Participações do Documento Consultado",
    DATADEENTRADA: "Data de Entrada",
    NUMERODOCUMENTOA: "Número do Documento A",
    NUMERODOCUMENTOB: "Número do Documento B",
    VALOREMPERCENTUAL: "Valor em Percentual",
    RAZAOSOCIAL: "Razão Social",
    TIPODOCUMENTOA: "Tipo de Documento A",
    TIPODOCUMENTOB: "Tipo de Documento B",
    FUNCAO: "Função",
    TIPOSOCIO: "Tipo de Sócio",
    OBSERVACAO: "Observação",
    "INFORMACOES-COMPLEMENTARES": "Informações Complementares",
    "STATUS-CONSUMIDOR": "Status do Consumidor",
    MENSAGEM: "Mensagem",
  };

  const formatRecursively = (obj: Retorno): DadosProps[] => {
    return Object.keys(obj)
      .filter((key) => !Array.isArray(obj[key]))
      .map((key) => {
        const value = obj[key];

        if (typeof value === "object" && value !== null) {
          const subValue = Object.values(value).join(", ");
          return {
            label: labelMap[key] || key,
            value: subValue !== "" ? subValue : "-",
          };
        }

        return {
          label: labelMap[key] || key,
          value: value !== undefined && value !== "" ? String(value) : "-",
        };
      });
  };

  const categorias: CategoriaDados[] = [];

  Object.keys(retorno)
    .filter((key) => !Array.isArray(retorno[key]))
    .forEach((key) => {
      const dadosObj = retorno[key];
      const dados = formatRecursively(dadosObj);

      if (dados.length > 0) {
        categorias.push({
          categoria: labelMap[key] || key,
          dados: dados,
        });
      }
    });

  return categorias;
};

export function formatarLabel(label: string): string {
  return label
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/-/g, " ")
    .replace(/\s+/g, " ")
    .trim()
    .replace(/\b\w/g, (l) => l.toUpperCase());
}

function formatarDadosArray(response: any): any[] {
  const arraysFormatados: any[] = [];

  for (const key in response) {
    if (response.hasOwnProperty(key) && Array.isArray(response[key])) {
      const arrayData = response[key];
      const categoria = formatarLabel(key);

      arraysFormatados.push({
        categoria: categoria,
        dados: arrayData,
      });
    }
  }

  return arraysFormatados;
}

export function Gps353({ data }: Gps353Props) {
  const categoriasArray = formatarDadosArray(data);
  const categoriasNaoArray = formatarDadosNaoArray(data);

  return (
    <>
      {categoriasNaoArray.map(({ categoria, dados }) => (
        <ListDescriptions key={categoria} dados={dados} categoria={categoria} />
      ))}

      {categoriasArray.map(({ categoria, dados }) => (
        <Panel key={categoria} header={categoria}>
          <DataTable
            scrollable
            value={dados}
            responsiveLayout="scroll"
            className="no-wrap-table"
          >
            <Column field="TIPOSCORE" header="Tipo Score" />
            <Column field="TEXTO" header="Texto" />
            <Column field="SCORE" header="Score" />
            <Column
              field="CLASSIFICACAONUMERICA"
              header="Classificação Numérica"
            />
            <Column field="MODELOSCORE" header="Modelo Score" />
            <Column field="PROBABILIDADE" header="Probabilidade" />
            <Column field="TAMANHOREGISTRO" header="Tamanho Registro" />
            <Column field="DESCRICAONATUREZA" header="Descrição Natureza" />
            <Column field="REGISTRO" header="Registro" />
            <Column field="TIPOREGISTRO" header="Tipo Registro" />
            <Column field="PLANOEXECUCAO" header="Plano Execução" />
            <Column
              field="CODIGONATUREZAMODELO"
              header="Código Natureza Modelo"
            />
            <Column
              field="CLASSIFICACAOALFABETICA"
              header="Classificação Alfabética"
            />
            <Column field="VALOR" header="Valor" />
            <Column field="NOMESCORE" header="Nome Score" />
          </DataTable>
        </Panel>
      ))}
    </>
  );
}

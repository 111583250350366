import { Panel } from "primereact/panel";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";

import styles from "./styles.module.css";
import { DadosProps } from "../Gps353/types";
import { ListDescriptions } from "../../../../components/ListDescriptions";

type AutocorpLeilaoProps = {
  data: any;
};

type CategoriaDados = {
  categoria: string;
  dados: DadosProps[];
};

type StatusRetorno = {
  CODIGO: string;
  DESCRICAO: string;
};

type Ocorrencia = {
  STATUS: string;
  CONTEUDO: string;
  FONTE: string;
};

type Controle = {
  QUANTIDADE_OCORRENCIAS: string;
  OCORRENCIAS: Ocorrencia[];
};

type DadosRetornados = {
  [key: string]: string;
};

type InformacoesRetorno = {
  PRODUTO: string;
  DATA_HORA_CONSULTA: string;
  TERMINAL: string;
  PDF: string;
  REQUISICAO: string;
  STATUS_RETORNO: StatusRetorno;
  CHAVE_CONSULTA: string;
  SOLICITANTE: string;
  VERSAO: string;
  CLIENTE: string;
  ENTIDADE: string;
};

type Parametros = {
  [key: string]: string;
};

type ApiResponse = {
  CONTROLE?: Controle | null;
  DADOS_RETORNADOS?: DadosRetornados;
  INFORMACOES_RETORNO: InformacoesRetorno;
  PARAMETROS?: Parametros;
};

type OcorrenciaFormatada = {
  status: string;
  conteudo: string;
  fonte: string;
};

type ControleFormatado = {
  quantidadeOcorrencias: string;
  ocorrencias: OcorrenciaFormatada[];
};

type FormattedData = {
  controle: ControleFormatado;
  details: CategoriaDados[];
};

type ScoreLeilao = {
  PERCENTUAL_SOBRE_TABELA_REFERENCIA: string;
  ACEITACAO: string;
  DESCRICAO_PONTUACAO: string;
  PONTUACAO: string;
  EXIGE_VISTORIA_ESPECIAL: string;
};

type DadosVeiculoLeilao = {
  CARROCERIA: string;
  MARCA_MODELO: string;
  QTD_EIXOS: string;
  CHASSI: string;
  COR: string;
  ANO_MODELO: string;
  PLACA: string;
  KILOMETRAGEM: string;
  EIXO_TRASEIRO: string;
  COMBUSTIVEL: string;
  CAMBIO: string;
  MOTOR: string;
  RENAVAM: string;
  ANO_FABRICACAO: string;
  CATEGORIA: string;
};

type CodigoDescricao = {
  CODIGO: string;
  DESCRICAO: string;
};

type CheckListVeiculoLeilao = {
  FRENTE: CodigoDescricao;
  OBSERVACOES: string;
  INTERIOR: CodigoDescricao;
  LATERAL_ESQUERDA: CodigoDescricao;
  RODAS_FALTANTES: string;
  LOCAL_QUEIMADO: string;
  TETO: CodigoDescricao;
  AIRBAGS_ROMPIDOS: string;
  EXISTE_INFORMACAO: string;
  TRASEIRA: CodigoDescricao;
  LATERAL_DIREITA: CodigoDescricao;
};

type Leilao = {
  SCORE: ScoreLeilao;
  QUANTIDADE_OCORRENCIAS: string;
  DADOS_VEICULO: DadosVeiculoLeilao;
  STATUS_RETORNO: StatusRetorno;
  CHECK_LIST_VEICULO: CheckListVeiculoLeilao;
  OCORRENCIAS: any[];
};

type IndicioSinistro = {
  STATUS_RETORNO: StatusRetorno;
  EXISTE_OCORRENCIA: string;
  DESCRICAO_OCORRENCIA: string;
};

type ApiResponseLeilao = {
  LEILAO: Leilao;
  INDICIO_SINISTRO: IndicioSinistro;
};

type FileData = {
  label: string;
  url: string;
};

type ExtractedFiles = {
  images: FileData[];
  documents: FileData[];
};

function formatarLabel(label: string): string {
  const labelMap: { [key: string]: string } = {
    NOME_MAE: "Nome da Mãe",
    UF: "UF",
    CEP: "CEP",
    FARM_GEOMETRY: "Farm Geometry",
    DECISAO: "Decisão",
    PENDENCIAS_VENCIDAS: "Pendências Vencidas",
    TELEFONE_VINCULADO_DOCUMENTO_INFORMADO:
      "Telefone Vinculado ao Documento Informado",
    STATUS_LICENCIAMENTO: "Status do Licenciamento",
    CONGLOMERADOS: "Conglomerados",
    PONTUALIDADE_PAGTO: "Pontualidade de Pagamento",
    LEILAO_COMPOSTO: "Leilão Composto",
    REFERENCIA_DE_NEGOCIOS: "Referência de Negócios",
    LEILAO: "Leilão",
    IDENTIFICACAO_PESSOA_FISICA: "Identificação Pessoa Física",
    MONITORAMENTO: "Monitoramento",
    INDICIO_SINISTRO_DETALHADO: "Indício de Sinistro Detalhado",
    PARECER_TECNICO: "Parecer Técnico",
    DPVAT: "DPVAT",
    VEICULOS_POR_DOCUMENTO: "Veículos por Documento",
    BIN_NACIONAL: "BIN Nacional",
    COMPROMISSOS: "Compromissos",
    CRLV: "CRLV",
    BIN_SENATRAN: "BIN Senatran",
    TELEFONE_FIXO: "Telefone Fixo",
    LOCALIZACAO: "Localização",
    SOMENTE_TELEFONE: "Somente Telefone",
    VALIDACAO_FACIAL_CDV: "Validação Facial CDV",
    DADOS_PATERNOS: "Dados Paternos",
    HISTORICO_ROUBO_FURTO: "Histórico de Roubo/Furto",
    LOCALIZADOR_AGREGADOS: "Localizador de Agregados",
    PARTICIPACAO_EM_FALENCIAS: "Participação em Falências",
    FATURAMENTO_PRESUMIDO: "Faturamento Presumido",
    BENEFICIO: "Benefício",
    CNH: "CNH",
    ENDERECOS: "Endereços",
    MONITORAMENTO_SIMPLES: "Monitoramento Simples",
    VIZINHOS: "Vizinhos",
    DADOS_RECEITA_FEDERAL: "Dados da Receita Federal",
    PARECER_TECNICO_AVANCADO_2: "Parecer Técnico Avançado 2",
    EMAILS: "E-mails",
    DECODIFICADOR_DE_CHASSI: "Decodificador de Chassi",
    REMARKETING: "Remarketing",
    ACOES_TRABALHISTAS: "Ações Trabalhistas",
    VINCULO_MESMO_ENDERECO: "Vínculo no Mesmo Endereço",
    LIMITE_CREDITO: "Limite de Crédito",
    HISTORICO_DE_PAGAMENTOS_POR_PERIODO: "Histórico de Pagamentos por Período",
    VALIDACAO_PF_BASICA: "Validação PF Básica",
    CONSULTAS_ANTERIORES: "Consultas Anteriores",
    PARECER_TECNICO_AVANCADO: "Parecer Técnico Avançado",
    CONTATOS: "Contatos",
    VALIDACAO_PF_CNH: "Validação PF CNH",
    CCF_BACEN: "CCF Bacen",
    CONFERENCIA_MOTOR_E_CHASSI: "Conferência de Motor e Chassi",
    DADOS_AGENCIA_BANCARIA: "Dados da Agência Bancária",
    PASSAGENS_COMERCIAIS: "Passagens Comerciais",
    PROTESTO_SINTETICO: "Protesto Sintético",
    RESIDENTES: "Residentes",
    ENDERECO_DO_CEP: "Endereço do CEP",
    ACOES_CIVEIS: "Ações Cíveis",
    PARENTES: "Parentes",
    ESTIMATIVA_KILOMETRAGEM: "Estimativa de Quilometragem",
    RADAR_SECURITARIO_COMPLETO: "Radar Securitário Completo",
    RADAR_SECURITARIO: "Radar Securitário",
    RESUMOCONSULTAS_ANTERIORES_90_DIAS_INFO:
      "Resumo de Consultas Anteriores (90 Dias)",
    PARTICIPACOES_EMPRESAS_CONSULTADAS: "Participações em Empresas Consultadas",
    INFORMACOES_DA_EMPRESA: "Informações da Empresa",
    VALIDACAO_PF_ENDERECO: "Validação PF Endereço",
    AUTO_PHOTO: "Auto Photo",
    INFORMACOES_ELEITORAIS: "Informações Eleitorais",
    CREDITO_OBTIDO: "Crédito Obtido",
    AUTO_PHOTO_V2: "Auto Photo V2",
    LOCAIS_TRABALHO: "Locais de Trabalho",
    ALERTAS: "Alertas",
    RELATORIO_TEXTO: "Relatório Texto",
    FALENCIA_RECUPERACAO_JUDICIAL: "Falência/Recuperação Judicial",
    OCUPACAO_PESSOA_FISICA: "Ocupação Pessoa Física",
    TITULOS_A_VENCER: "Títulos a Vencer",
    INFOBUSCA: "Infobusca",
    HIST_CONSULTAS: "Histórico de Consultas",
    VALIDACAO_PF_FILIACAO: "Validação PF Filiação",
    TITULAR_DO_TELEFONE: "Titular do Telefone",
    HISTORICO_DE_PAGAMENTOS: "Histórico de Pagamentos",
    PERDA_TOTAL: "Perda Total",
    PENDENCIAS_FINANCEIRAS: "Pendências Financeiras",
    CONTROLE_POSITIVO: "Controle Positivo",
    SOMENTE_ENDERECO: "Somente Endereço",
    AGREGADOS: "Agregados",
    RECHEQUE: "Recheque",
    CARACTERISTICAS_ADICIONAIS: "Características Adicionais",
    PENDENCIAS_INTERNAS: "Pendências Internas",
    RENAJUD: "Renajud",
    ALERTA_DOCUMENTOS_ROUBADOS: "Alerta de Documentos Roubados",
    QUADRO_SOCIETARIO: "Quadro Societário",
    RECHEQUE_ONLINE: "Recheque Online",
    HISTORICO_PROPRIETARIOS_DADOS_DETALHADOS:
      "Histórico de Proprietários Detalhado",
    OUTRAS_GRAFIAS: "Outras Grafias",
    VALIDACAO_PESSOA_FISICA: "Validação Pessoa Física",
    ADMINISTRADORES_DA_EMPRESA: "Administradores da Empresa",
    FALENCIAS_ACOES_RECUPERACOES: "Falências/Ações/Recuperações",
    INDICIO_DETALHADO_ALERTA: "Indício Detalhado Alerta",
    ROUBO_FURTO: "Roubo/Furto",
    GRAVAME: "Gravame",
    BATIDOS: "Batidos",
    COMPROMETIMENTO_FUTURO: "Comprometimento Futuro",
    PENDENCIAS_REFIN: "Pendências Refin",
    CCF_VAREJO: "CCF Varejo",
    INFORMACOES_ALERTAS_RESTRICOES: "Informações/Alertas/Restrições",
    SCORE: "Score",
    INDICIO_SINISTRO: "Indício de Sinistro",
    BIN_ESTADUAL: "BIN Estadual",
    DADOS_GERAIS: "Dados Gerais",
    CRV: "CRV",
    INDICADORES: "Indicadores",
    BASE_HISTORICO_PROPRIETARIOS: "Base de Histórico de Proprietários",
    RECALL: "Recall",
    ANALISE_KILOMETRAGEM: "Análise de Quilometragem",
    PRINCIPAIS_FORNECEDORES: "Principais Fornecedores",
    STATUS_CONSUMIDOR: "Status do Consumidor",
    PROTESTO_ANALITICO: "Protesto Analítico",
    HISTORICO_PROPRIETARIOS: "Histórico de Proprietários",
    TELEFONE_CELULAR: "Telefone Celular",
    RESUMO_ACOES_TRABALHISTAS: "Resumo de Ações Trabalhistas",
    RENACH: "Renach",
    PROPRIETARIO_ATUAL_VEICULO: "Proprietário Atual do Veículo",
    HISTORICO_KILOMETRAGEM: "Histórico de Quilometragem",
    GRAVAME_DADOS_DETALHADOS: "Gravame Dados Detalhados",
    PARTICIPACAO_SOCIO_ADM_OUTRAS_EMPRESAS:
      "Participação Sócio/Adm em Outras Empresas",
    PRECIFICADOR: "Precificador",
    LAUDO_CAUTELAR: "Laudo Cautelar",
    CONTUMACIA: "Contumácia",
    VALIDACAO_PF_DOCUMENTO: "Validação PF Documento",
    OUTROS_TELEFONES: "Outros Telefones",
    PARTICIPACAO_EM_EMPRESAS: "Participação em Empresas",
    FICHA_TECNICA: "Ficha Técnica",
    TELEFONE_COMERCIAL: "Telefone Comercial",
    DEMAIS_INFORMACOES: "Demais Informações",
    CALCULO_VALORIZACAO: "Cálculo de Valorização",
    VALIDACAO_PF_BIOMETRIA_FACE: "Validação PF Biometria Facial",
    NUM_CAMBIO: "Número do Câmbio",
    ENDERECO_BAIRRO: "Endereço Bairro",
    FOTO_BASE64: "Foto em Base64",
    CARTEIRAPROFISSIONAL: "Carteira Profissional",
    CHASSI: "Chassi",
    NUM_CARROCERIA: "Número da Carroceria",
    EMAIL: "E-mail",
    NACIONALIDADE: "Nacionalidade",
    CR_ORGAO_EXPEDIDOR: "CR Órgão Expedidor",
    NUM_TERCEIRO_EIXO: "Número do Terceiro Eixo",
    ENDERECO: "Endereço",
    CAR_NUMBER: "Número do Carro",
    PASSAPORTE_ORGAO_EXPEDIDOR: "Passaporte Órgão Expedidor",
    CPFCNPJ: "CPF/CNPJ",
    CMC7: "CMC7",
    IDADE: "Idade",
    SITUACAO_CPF_CNPJ: "Situação do CPF/CNPJ",
    SOM_SEMELHANTE: "Som Semelhante",
    CENTRAL: "Central",
    OBITO: "Óbito",
    KILOMETRAGEM: "Quilometragem",
    RG_UF_EXPEDIDOR: "RG UF Expedidor",
    PASSAPORTE_UF_EXPEDIDOR: "Passaporte UF Expedidor",
    ENDERECO_COMPLEMENTO: "Endereço Complemento",
    TIPO_DOCUMENTO_ENVIADO: "Tipo de Documento Enviado",
    NOME: "Nome",
    REGISTRO_GERAL: "Registro Geral (RG)",
    CNH_DATA_PRIMEIRA_HABILITACAO: "CNH Data da Primeira Habilitação",
    DATA_NASCIMENTO: "Data de Nascimento",
    DDD: "DDD",
    GRUPO: "Grupo",
    COTA: "Cota",
    CART_NAC_HABILITACAO: "Carteira Nacional de Habilitação",
    CNH_DATA_VALIDADE: "CNH Data de Validade",
    TIPO_PESSOA: "Tipo de Pessoa",
    NUM_BENEFICIO_INSS: "Número do Benefício INSS",
    DATA_FINAL: "Data Final",
    CERTIFICADORESERVISTA: "Certificado de Reservista",
    PROPRIETARIO_VEICULO: "Proprietário do Veículo",
    NOME_PAI: "Nome do Pai",
    DT_VENCIMENTO: "Data de Vencimento",
    CIDADE: "Cidade",
    CNH_REGISTRO_NACIONAL_ESTRANGEIRO: "CNH Registro Nacional Estrangeiro",
    NUM_MOTOR: "Número do Motor",
    TELEFONE: "Telefone",
    PASSAPORTE: "Passaporte",
    PLACA: "Placa",
    RG_ORGAO_EXPEDIDOR: "RG Órgão Expedidor",
    CNH_SITUACAO: "Situação da CNH",
    CP_ORGAO_EXPEDIDOR: "CP Órgão Expedidor",
    SEXO: "Sexo",
    DATA_INICIO: "Data de Início",
    CP_UF_EXPEDIDOR: "CP UF Expedidor",
    CNH_CATEGORIA: "Categoria da CNH",
    RENAVAM: "Renavam",
    CR_UF_EXPEDIDOR: "CR UF Expedidor",
    ENDERECO_NUM: "Número do Endereço",
    NUM_EIXO_TRASEIRO: "Número do Eixo Traseiro",
    VALOR: "Valor",
    CNH_DATA_ULTIMA_EMISSAO: "CNH Data da Última Emissão",
    PERCENTUAL_SOBRE_TABELA_REFERENCIA: "Percentual Sobre Tabela Referência",
    ACEITACAO: "Aceitação",
    DESCRICAO_PONTUACAO: "Descrição da Pontuação",
    PONTUACAO: "Pontuação",
    EXIGE_VISTORIA_ESPECIAL: "Exige Vistoria Especial",
    CARROCERIA: "Carroceria",
    MARCA_MODELO: "Marca/Modelo",
    QTD_EIXOS: "Quantidade de Eixos",
    EIXO_TRASEIRO: "Eixo Traseiro",
    COMBUSTIVEL: "Combustível",
    CAMBIO: "Câmbio",
    ANO_MODELO: "Ano Modelo",
    ANO_FABRICACAO: "Ano de Fabricação",
    CATEGORIA: "Categoria",
    FRENTE: "Frente",
    OBSERVACOES: "Observações",
    INTERIOR: "Interior",
    LATERAL_ESQUERDA: "Lateral Esquerda",
    RODAS_FALTANTES: "Rodas Faltantes",
    LOCAL_QUEIMADO: "Local Queimado",
    TETO: "Teto",
    AIRBAGS_ROMPIDOS: "Airbags Rompidos",
    EXISTE_INFORMACAO: "Existe Informação",
    TRASEIRA: "Traseira",
    LATERAL_DIREITA: "Lateral Direita",
    EXISTE_OCORRENCIA: "Existe Ocorrência",
    DESCRICAO_OCORRENCIA: "Descrição da Ocorrência",
    CODIGO: "Código",
    DESCRICAO: "Descrição",
  };

  const formattedLabel = labelMap[label];

  if (formattedLabel) {
    return formattedLabel;
  } else {
    return label
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
  }
}

function formataHeader(response: ApiResponse): FormattedData {
  if (!response) {
    return {
      controle: {
        quantidadeOcorrencias: "-",
        ocorrencias: [],
      },
      details: [],
    };
  }

  const {
    CONTROLE = null,
    DADOS_RETORNADOS = {},
    INFORMACOES_RETORNO,
    PARAMETROS = {},
  } = response;

  const controleFormatado: ControleFormatado = {
    quantidadeOcorrencias: CONTROLE?.QUANTIDADE_OCORRENCIAS || "-",
    ocorrencias:
      CONTROLE?.OCORRENCIAS?.map((parte) => ({
        status: parte.STATUS || "-",
        conteudo: parte.CONTEUDO || "-",
        fonte: parte.FONTE || "-",
      })) || [],
  };

  function filtrarCampos(
    obj: { [key: string]: any },
    parentKey: string
  ): DadosProps[] {
    return Object.entries(obj)
      .filter(([key, value]) => {
        if (typeof value === "string") {
          return !(
            isImageUrl(value) ||
            isBase64Image(value) ||
            isDocumentUrl(value, key)
          );
        }
        return true;
      })
      .map(([key, value]) => ({
        label: formatarLabel(key),
        value: value || "-",
      }));
  }

  const details: CategoriaDados[] = [];

  if (Object.keys(DADOS_RETORNADOS).length > 0) {
    details.push({
      categoria: "Dados Retornados",
      dados: filtrarCampos(DADOS_RETORNADOS, "DADOS_RETORNADOS"),
    });
  }

  details.push({
    categoria: "Informações de Retorno",
    dados: [
      { label: "Produto", value: INFORMACOES_RETORNO.PRODUTO || "-" },
      {
        label: "Data Hora Consulta",
        value: INFORMACOES_RETORNO.DATA_HORA_CONSULTA || "-",
      },
      { label: "Terminal", value: INFORMACOES_RETORNO.TERMINAL || "-" },

      { label: "Requisição", value: INFORMACOES_RETORNO.REQUISICAO || "-" },
      {
        label: "Status Retorno - Código",
        value: INFORMACOES_RETORNO.STATUS_RETORNO?.CODIGO || "-",
      },
      {
        label: "Status Retorno - Descrição",
        value: INFORMACOES_RETORNO.STATUS_RETORNO?.DESCRICAO || "-",
      },
      {
        label: "Chave Consulta",
        value: INFORMACOES_RETORNO.CHAVE_CONSULTA || "-",
      },
      { label: "Solicitante", value: INFORMACOES_RETORNO.SOLICITANTE || "-" },
      { label: "Versão", value: INFORMACOES_RETORNO.VERSAO || "-" },
      { label: "Cliente", value: INFORMACOES_RETORNO.CLIENTE || "-" },
      { label: "Entidade", value: INFORMACOES_RETORNO.ENTIDADE || "-" },
    ],
  });

  if (Object.keys(PARAMETROS).length > 0) {
    details.push({
      categoria: "Parâmetros",
      dados: filtrarCampos(PARAMETROS, "PARAMETROS"),
    });
  }

  return {
    controle: controleFormatado,
    details: details,
  };
}

function formatarDadosLeilao(
  response: ApiResponseLeilao | null
): CategoriaDados[] {
  if (response === null) {
    return [];
  }

  const { LEILAO, INDICIO_SINISTRO } = response;

  const categorias: CategoriaDados[] = [];

  categorias.push({
    categoria: "Dados do Leilão",
    dados: [
      {
        label: formatarLabel("QUANTIDADE_OCORRENCIAS"),
        value: LEILAO.QUANTIDADE_OCORRENCIAS || "-",
      },
    ],
  });

  const scoreDados = Object.entries(LEILAO.SCORE)
    .filter(([key, value]) => {
      return !(
        isImageUrl(value) ||
        isBase64Image(value) ||
        isDocumentUrl(value, key)
      );
    })
    .map(([key, value]) => ({
      label: formatarLabel(key),
      value: value || "-",
    }));

  categorias.push({
    categoria: "Score",
    dados: scoreDados,
  });

  const dadosVeiculo = Object.entries(LEILAO.DADOS_VEICULO)
    .filter(([key, value]) => {
      return !(
        isImageUrl(value) ||
        isBase64Image(value) ||
        isDocumentUrl(value, key)
      );
    })
    .map(([key, value]) => ({
      label: formatarLabel(key),
      value: value || "-",
    }));

  categorias.push({
    categoria: "Dados do Veículo",
    dados: dadosVeiculo,
  });

  const checkListDados: DadosProps[] = [];

  const checkList = LEILAO.CHECK_LIST_VEICULO;

  for (const key of Object.keys(checkList) as Array<
    keyof CheckListVeiculoLeilao
  >) {
    const value = checkList[key];
    if (
      typeof value === "object" &&
      value !== null &&
      "CODIGO" in value &&
      "DESCRICAO" in value
    ) {
      if (
        !isImageUrl(value.CODIGO) &&
        !isBase64Image(value.CODIGO) &&
        !isDocumentUrl(value.CODIGO, "CODIGO") &&
        !isImageUrl(value.DESCRICAO) &&
        !isBase64Image(value.DESCRICAO) &&
        !isDocumentUrl(value.DESCRICAO, "DESCRICAO")
      ) {
        checkListDados.push({
          label: `${formatarLabel(key)} - Código`,
          value: value.CODIGO || "-",
        });
        checkListDados.push({
          label: `${formatarLabel(key)} - Descrição`,
          value: value.DESCRICAO || "-",
        });
      }
    } else {
      if (
        !isImageUrl(value) &&
        !isBase64Image(value) &&
        !isDocumentUrl(value, key)
      ) {
        checkListDados.push({
          label: formatarLabel(key),
          value: value || "-",
        });
      }
    }
  }

  categorias.push({
    categoria: "Check List do Veículo",
    dados: checkListDados,
  });

  categorias.push({
    categoria: "Status Retorno - Leilão",
    dados: [
      {
        label: formatarLabel("CODIGO"),
        value: LEILAO.STATUS_RETORNO.CODIGO || "-",
      },
      {
        label: formatarLabel("DESCRICAO"),
        value: LEILAO.STATUS_RETORNO.DESCRICAO || "-",
      },
    ],
  });

  categorias.push({
    categoria: "Indício de Sinistro",
    dados: [
      {
        label: formatarLabel("EXISTE_OCORRENCIA"),
        value: INDICIO_SINISTRO.EXISTE_OCORRENCIA || "-",
      },
      {
        label: formatarLabel("DESCRICAO_OCORRENCIA"),
        value: INDICIO_SINISTRO.DESCRICAO_OCORRENCIA || "-",
      },
      {
        label: "Status Retorno - Código",
        value: INDICIO_SINISTRO.STATUS_RETORNO.CODIGO || "-",
      },
      {
        label: "Status Retorno - Descrição",
        value: INDICIO_SINISTRO.STATUS_RETORNO.DESCRICAO || "-",
      },
    ],
  });

  return categorias;
}

function isImageUrl(url: string): boolean {
  return /\.(jpeg|jpg|png|gif|bmp)$/i.test(url);
}

function isDocumentUrl(url: string, key: string): boolean {
  if (key.toLowerCase().includes("pdf")) {
    return true;
  }

  if (url.toLowerCase().includes("pdf")) {
    return true;
  }

  return /\.(pdf|docx?|xlsx?|pptx?)$/i.test(url);
}

function isBase64Image(data: string): boolean {
  return /^data:image\/(jpeg|jpg|png|gif|bmp);base64,/.test(data);
}

function formatLabel(label: string): string {
  return label
    .split(".")
    .map((word) => word.replace(/_/g, " "))
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" - ");
}

function extractFilesFromObject(
  obj: any,
  images: FileData[],
  documents: FileData[],
  parentKey: string = ""
): void {
  if (typeof obj !== "object" || obj === null) {
    return;
  }

  for (const key in obj) {
    if (!obj.hasOwnProperty(key)) continue;

    const value = obj[key];
    const fullKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof value === "string") {
      if (isImageUrl(value) || isBase64Image(value)) {
        images.push({
          label: formatLabel(fullKey),
          url: value,
        });
      } else if (isDocumentUrl(value, key)) {
        documents.push({
          label: formatLabel(fullKey),
          url: value,
        });
      }
    } else if (typeof value === "object") {
      extractFilesFromObject(value, images, documents, fullKey);
    }
  }
}

function extractFilesFromResponses(
  response1: ApiResponse,
  response2: ApiResponseLeilao
): ExtractedFiles {
  const images: FileData[] = [];
  const documents: FileData[] = [];

  extractFilesFromObject(response1, images, documents);
  extractFilesFromObject(response2, images, documents);

  return {
    images,
    documents,
  };
}

export function AutocorpLeilao({ data }: AutocorpLeilaoProps) {
  const { details, controle } = formataHeader(data.HEADER);
  const dadosVeicular = formatarDadosLeilao(data?.VEICULAR || null);
  const { documents, images } = extractFilesFromResponses(
    data.HEADER,
    data.VEICULAR
  );

  return (
    <>
      {data?.ERRO ? (
        <Message
          severity="error"
          text={data.ERRO}
          style={{ marginBottom: 32 }}
        />
      ) : (
        <>
          {documents.length > 0 && (
            <Panel header="Documentos" style={{ marginBottom: 32 }}>
              <div className={styles.horizontalStack}>
                {documents.map((doc, index) => (
                  <a
                    key={index}
                    href={doc.url}
                    target="_blank"
                    className={styles.link}
                    rel="noopener noreferrer"
                  >
                    <Button
                      label={doc.label}
                      icon="pi pi-file"
                      style={{ backgroundColor: "#183462" }}
                    />
                  </a>
                ))}
              </div>
            </Panel>
          )}

          {images.length > 0 && (
            <Panel header="Imagens" style={{ marginBottom: 32 }}>
              <div className={styles.horizontalStack}>
                {images.map((img, index) => (
                  <div key={index} className={styles.imageContainer}>
                    <img
                      src={img.url}
                      alt={img.label}
                      className={styles.image}
                    />
                    <p>{img.label}</p>
                  </div>
                ))}
              </div>
            </Panel>
          )}

          {details.map(({ dados, categoria }) => (
            <ListDescriptions dados={dados} categoria={categoria} />
          ))}

          <Panel header="Ocorrências" style={{ marginBottom: 32 }}>
            <DataTable value={controle.ocorrencias}>
              <Column field="status" header="Status" />
              <Column field="conteudo" header="Conteúdo" />
              <Column field="fonte" header="Fonte" />
            </DataTable>
          </Panel>

          {dadosVeicular.length > 0 &&
            dadosVeicular.map(({ dados, categoria }) => (
              <ListDescriptions dados={dados} categoria={categoria} />
            ))}
        </>
      )}
    </>
  );
}

import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";
import LeilaoList from './LeilaoList';
import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import LeilaoCadastrar from './LeilaoCadastrar';
import { Messages } from 'primereact/messages';
import postLeilao from "../../service/postLeilao";
import putLeilao from '../../service/putLeilao';

export default function LeilaoPage() {
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const [leilao, setLeilao] = useState<any | null>(null);
  const labelButton = t('cadastrar')
  const saveLeilao = (leilao:any) => {
    if(leilao.id){
      putLeilao(leilao).then(data => {
        if(data.includes('Erro')){
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
               
            ]);
          }
        }
        else{
          setLeilao(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
               
            ]);
          }
        }
      })
    }
    else{
      postLeilao(leilao).then(data => {
        if(data.includes('Erro')){
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
               
            ]);
          }
        }
        else{
          setLeilao(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
               
            ]);
          }
        }
      })
    }
    
    
  }
  const startContent = (
    <React.Fragment>
        <Button label={labelButton} icon="pi pi-plus" style={{backgroundColor:'#183462'}} onClick={() => {setLeilao({})}}/>
    </React.Fragment>
);

  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-car" style={{ fontSize: '2.0rem' }}></i> {t('leiloes')}</h1>
            </div>     
          </div>
      </div>
      <div className="card">
        <Messages ref={msgs} />
        {!leilao && (
          <>
            <Toolbar start={startContent} />
            <br></br>
            <br></br>
            <LeilaoList onEdit={(leilao:any) => { setLeilao(leilao);}}></LeilaoList>
          </>
        )}
        {leilao && (
          <>
           <LeilaoCadastrar msgs={msgs} leilao={leilao} onSave={saveLeilao} onClose={() => {setLeilao(null)}}></LeilaoCadastrar>
          </>
        )}
      </div>
                
    </Container>
  )
}

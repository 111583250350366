
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";
import OutPutCard from "../../../components/outputCard";
import { Divider } from "primereact/divider";





export default function AutomotivaOnline({
  automotivaOnline
}: {
  automotivaOnline: any
}) {
  const { t } = useTranslation();
  automotivaOnline = automotivaOnline.RETORNO
  return (
    <Panel header={t('Automotiva Base Nacional')} style={{width:'98%'}}>
       <Divider align="left">
        <div className="flex-score justify-content-between justify-content-center">
          <span className="p-tag" style={{position: 'relative', backgroundColor : '#183462', fontSize:'1.1rem', height: '2rem', marginTop: '0.5rem'}}>Resumo do Veículo</span>          
        </div>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('placa')}</label>
                 <Chip style={{width:"100%"}} label={automotivaOnline.PLACA} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('marcaModelo')}</label>
                 <Chip style={{width:"100%"}} label={automotivaOnline.MODELO} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('chassi')}</label>
              <Chip style={{width:"100%"}} label={automotivaOnline.CHASSI} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('motor')}</label>
              <Chip style={{width:"100%"}} label={automotivaOnline.MOTOR} />
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('renavam')}</label>
                 <Chip style={{width:"100%"}} label={automotivaOnline.RENAVAM} />
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('cor')}</label>
                 <Chip style={{width:"100%"}} label={automotivaOnline.COR} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('combustivel')}</label>
                 <Chip style={{width:"100%"}} label={automotivaOnline.COMBUSTIVEL} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('anoFabricaoModelo')}</label>
              <Chip style={{width:"100%"}} label={automotivaOnline.VEIANOFABR + '/' + automotivaOnline.VEIANOMODELO} />
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
       <Divider align="left">
        <span className="p-tag" style={{backgroundColor : '#183462', fontSize:'1.1rem'}}>Base Nacional</span>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Chassi" value={automotivaOnline.CHASSI}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Cilindradas" value={automotivaOnline.CILINDRADA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Espécie" value={automotivaOnline.ESPECIE}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Combustível" value={automotivaOnline.COMBUSTIVEL}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Potência" value={automotivaOnline.POTENCIA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Modelo" value={automotivaOnline.MODELO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Marca" value={automotivaOnline.MARCA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Placa" value={automotivaOnline.PLACA}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Ano Fabricação" value={automotivaOnline.VEIANOFABR}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Ano Modelo" value={automotivaOnline.VEIANOMODELO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Capacidade" value={automotivaOnline.CAPACIDADEPASSAG}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Tipo" value={automotivaOnline.TIPO}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Município" value={automotivaOnline.MUNICIPIO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="UF" value={automotivaOnline.UF}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Procedência" value={automotivaOnline.VEIPROCEDENCIA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Motor" value={automotivaOnline.MOTOR}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Cor" value={automotivaOnline.COR}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Renavan" value={automotivaOnline.RENAVAM}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Situação" value={automotivaOnline.SITUACAOVEIC}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Documento Proprietário" value={automotivaOnline.CPF_CNPJ_PROPRIETARIO}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="CMT" value={automotivaOnline.CMT}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Data Atualização" value={automotivaOnline.ULTIMAATUALIZACAO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Tipo Documento Faturado" value={automotivaOnline.TIPODOCUMENTOFATURADO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="UF Documento Faturado" value={automotivaOnline.UFFATURADO}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Documento Faturado" value={automotivaOnline.CPFCNPJFATURADO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Cambio" value={automotivaOnline.CAIXACAMBIO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Capacidade Carga" value={automotivaOnline.CAPACIDADECARGA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="PBT" value={automotivaOnline.PBT}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Quantidade de Eixos" value={automotivaOnline.EIXOS}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Situação chassi" value={automotivaOnline.TIPOREMARCCHASSI}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Ocorrência" value={automotivaOnline.OCORRENCIA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Comunicado de Venda" value={automotivaOnline.INDICADORCOMUNICACAODEVENDAS}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Tipo Documento Proprietário" value={automotivaOnline.TIPODOCPROPRIETARIO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Restrição" value={automotivaOnline.RESTRICAO01}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap"></div>
          <div className="col p-fluid flex flex-wrap"></div>
        </div>
    </Panel>
  )
}

import React from "react";
import "./styles.css";

type LabelProps = React.ComponentProps<"label"> & {
  text: string;
  required?: boolean;
};

export const Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ text, required = false, ...props }, ref) => {
    return (
      <label ref={ref} className="custom-label" {...props}>
        {text}
        {required && <span>*</span>}
      </label>
    );
  }
);

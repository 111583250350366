
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import OutPutCard from "../../../components/outputCard";
import { Divider } from "primereact/divider";





export default function AutomotivaMotor({
  automotivaMotor
}: {
  automotivaMotor: any
}) {
  const { t } = useTranslation();
  automotivaMotor = automotivaMotor.RETORNO
  return (
    <Panel header={t('Automotiva Motor')} style={{width:'98%'}}>
       <Divider align="left">
        <div className="flex-score justify-content-between justify-content-center">
          <span className="p-tag" style={{position: 'relative', backgroundColor : '#183462', fontSize:'1.1rem', height: '2rem', marginTop: '0.5rem'}}>Resumo do Veículo</span> 
        </div>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('placa')}</label>
                 <Chip style={{width:"100%"}} label={automotivaMotor.PLACA} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('marcaModelo')}</label>
                 <Chip style={{width:"100%"}} label={automotivaMotor.MODELO} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('chassi')}</label>
              <Chip style={{width:"100%"}} label={automotivaMotor.CHASSI} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('motor')}</label>
              <Chip style={{width:"100%"}} label={automotivaMotor.MOTOR} />
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('renavam')}</label>
                 <Chip style={{width:"100%"}} label={automotivaMotor.RENAVAM} />
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('cor')}</label>
                 <Chip style={{width:"100%"}} label={automotivaMotor.COR} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('combustivel')}</label>
                 <Chip style={{width:"100%"}} label={automotivaMotor.COMBUSTIVEL} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('anoFabricaoModelo')}</label>
              <Chip style={{width:"100%"}} label={automotivaMotor.VEIANOFABR + '/' + automotivaMotor.VEIANOMODELO} />
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
       <Divider align="left">
        <span className="p-tag" style={{backgroundColor : '#183462', fontSize:'1.1rem'}}>Dados veículo</span>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Chassi" value={automotivaMotor.CHASSI}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Cilindradas" value={automotivaMotor.CILINDRADA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Espécie" value={automotivaMotor.ESPECIE}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Combustível" value={automotivaMotor.COMBUSTIVEL}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Potência" value={automotivaMotor.POTENCIA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Modelo" value={automotivaMotor.MODELO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Marca" value={automotivaMotor.MARCA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Placa" value={automotivaMotor.PLACA}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Ano Fabricação" value={automotivaMotor.VEIANOFABR}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Ano Modelo" value={automotivaMotor.VEIANOMODELO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Capacidade" value={automotivaMotor.CAPACIDADEPASSAG}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Tipo" value={automotivaMotor.TIPO}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Município" value={automotivaMotor.MUNICIPIO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="UF" value={automotivaMotor.UF}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Procedência" value={automotivaMotor.VEIPROCEDENCIA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Motor" value={automotivaMotor.MOTOR}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Cor" value={automotivaMotor.COR}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Renavan" value={automotivaMotor.RENAVAM}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Segmento" value={automotivaMotor.SEGMENTO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Sub Segmento" value={automotivaMotor.SUBSEGMENTO}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="CMT" value={automotivaMotor.CMT}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Data Atualização" value={automotivaMotor.ULTIMAATUALIZACAO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Tipo Documento Faturado" value={automotivaMotor.TIPODOCUMENTOFATURADO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="UF Documento Faturado" value={automotivaMotor.UFFATURADO}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Documento Faturado" value={automotivaMotor.CPFCNPJFATURADO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Indicador Venda" value={automotivaMotor.INDICADORCOMUNICACAODEVENDAS}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Capacidade Carga" value={automotivaMotor.CAPACIDADECARGA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="PBT" value={automotivaMotor.PBT}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Ocorrência" value={automotivaMotor.OCORRENCIA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Indicador Renajud" value={automotivaMotor.INDICADORRESTRICAORENAJUD}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap"></div>
          <div className="col p-fluid flex flex-wrap"></div>
        </div>
      
    </Panel>
  )
}

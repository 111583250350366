import { Panel } from "primereact/panel";
import { useTranslation } from "react-i18next";
import { InfoDisplay } from "../../../../components/InfoDisplay";
import "./styles.module.css";

interface IInformativoProps {
  informative: any;
}

const styles = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "24px",
};

export default function Informativo({
  informative
}: IInformativoProps) {
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <Panel header={t("filtroConsulta")} style={{ width: "98%" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <div style={styles}>
            <InfoDisplay label={t("usuario")} value={informative.usuario} />
            <InfoDisplay label={t("data")} value={informative.data} />
          </div>
          <div style={styles}>
            <InfoDisplay
              label={t("filtro")}
              value={
                informative.documento !== "" ? informative.documento : "N/A"
              }
            />
            <InfoDisplay label={t("protocolo")} value={informative.protocolo} />
          </div>
          <div style={styles}>
            <InfoDisplay
              label={t("opcoesConsulta")}
              value={informative.opcoes[0] ?? "N/A"}
            />
          </div>
        </div>
      </Panel>

      
    </div>
  );
}

import { createBrowserRouter, Outlet, Navigate} from 'react-router-dom'
import { RouterProvider } from 'react-router'
import Login from '../pages/login';
import LoginSSO from '../pages/loginsso';
import RecuperarSenha from '../pages/recuperarSenha';
import RedefinirSenha from '../pages/redefinirSenha';
import {FunctionComponent} from 'react'
import Home from '../pages/home';
import Consulta from '../pages/consulta';
import Cliente from '../pages/cliente';
import Franquia from '../pages/franquia';
import Permissao from '../pages/permissao';
import RelatorioConsultaSintetica from '../pages/relatorioConsultaSintetica';
import Page404 from '../pages/404'
import { useGlobalState } from "./globalState/GlobalStateProvider";
import Usuario from '../pages/usuario';
import Registro from '../pages/registro';
import Leilao from '../pages/leilao';
import Veiculo from '../pages/veiculo';
import Consultas from '../pages/consultas';
import AlterarSenha from '../pages/alterarSenha';
import RelatorioApuracaoBilhete from '../pages/relatorioApuracaoBilhete';
import ParametrosAplicacao from '../pages/parametrosAplicacao';
import VeiculoVenda from '../pages/veiculoVenda';


const PermissionLayout = ( {permission} : any ) => {
    const {state} = useGlobalState();
    const hasPermission = !permission || state.user?.funcoes.includes(permission);
  
    return hasPermission ? <Outlet /> : <Navigate to="/404" replace />;
  };

const router = createBrowserRouter([
    {
      element: <PermissionLayout permission={undefined} />,
      children: [
        {
          path: "/",
          element: <Login />,
        }
      ],
    },
    {
      element: <PermissionLayout permission={undefined} />,
      children: [
        {
          path: "/loginsso/:jwt",
          element: <LoginSSO />,
        }
      ],
    },
    {
        element: <PermissionLayout permission={undefined} />,
        children: [
          {
            path: "/login",
            element: <Login />,
          }
        ],
    },
    {
      element: <PermissionLayout permission={undefined} />,
      children: [
        {
          path: "/recuperacao",
          element: <RecuperarSenha />,
        }
      ],
    },
    {
      element: <PermissionLayout permission={undefined} />,
      children: [
        {
          path: "/redefinir/:hash",
          element: <RedefinirSenha />,
        }
      ],
    },
    {
        element: <PermissionLayout permission={'HOME'} />,
        children: [
          {
            path: "/home",
            element: <Home />,
          }
        ],
    },
      {
        element: <PermissionLayout permission={'CONSULTAR'} />,
        children: [
          {
            path: "/consultar",
            element: <Consulta />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={'CONSULTAR'} />,
        children: [
          {
            path: "/consultar/:id",
            element: <Consulta />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={'CONSULTA_VISUALIZAR'} />,
        children: [
          {
            path: "/historico",
            element: <Consultas />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={'RELATORIO_CONSULTA_SINTETICA'} />,
        children: [
          {
            path: "/filtrarconsultasintetica",
            element: <RelatorioConsultaSintetica />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={'RELATORIO_APURACAO_BILHETE'} />,
        children: [
          {
            path: "/filtrarapuracaobilhete",
            element: <RelatorioApuracaoBilhete />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={'USUARIO_VISUALIZAR'} />,
        children: [
          {
            path: "/usuario",
            element: <Usuario />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={'REGISTRO_VISUALIZAR'} />,
        children: [
          {
            path: "/registro",
            element: <Registro />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={'FRANQUIA_VISUALIZAR'} />,
        children: [
          {
            path: "/franquia",
            element: <Franquia />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={'CLIENTE_VISUALIZAR'} />,
        children: [
          {
            path: "/cliente",
            element: <Cliente />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={'LEILAO_VISUALIZAR'} />,
        children: [
          {
            path: "/leilao",
            element: <Leilao />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={'VEICULO_VISUALIZAR'} />,
        children: [
          {
            path: "/veiculo",
            element: <Veiculo />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={'VEICULO_VENDA_VISUALIZAR'} />,
        children: [
          {
            path: "/veiculovenda",
            element: <VeiculoVenda />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={'PERMISSAO_VISUALIZAR'} />,
        children: [
          {
            path: "/permissao",
            element: <Permissao />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={'USERINFO'} />,
        children: [
          {
            path: "/alterarsenha",
            element: <AlterarSenha />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={undefined} />,
        children: [
          {
            path: "/404",
            element: <Page404 />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={undefined} />,
        children: [
          {
            path: "/parametros",
            element: <ParametrosAplicacao />,
          }
        ],
      },
      {
        element: <PermissionLayout permission={undefined} />,
        children: [
          {
            path: "*",
            element: <Page404 />,
          }
        ],
      }
  ],{ basename: '/creditbureau' });
  
  const RouterCustom: FunctionComponent = () => {
    return <RouterProvider router={router} />;
  };
          
  export default RouterCustom;
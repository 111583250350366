import { Panel } from "primereact/panel";
import { InfoDisplay } from "../../../components/InfoDisplay";
import { ICadastralTelefonePJ } from "../../../interface/CadastralTelefonePJ";

interface ICadastralTelefonePJProps {
  data: ICadastralTelefonePJ | null;
}

export const CadastralTelefonePJ = ({ data }: ICadastralTelefonePJProps) => {
  const REGISTRO = data?.BUSCATELEFONE.REGISTRO_1;

  return (
    <Panel style={{ width: "98%" }} header="Cadastral PJ Telefone">
      <section
        style={{ display: "flex", flexDirection: "column", gap: "24px" }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "24px",
          }}
        >
          <InfoDisplay label="UF" value={REGISTRO?.UF || "N/A"} />
          <InfoDisplay label="Bairro" value={REGISTRO?.BAIRRO || "N/A"} />
          <InfoDisplay
            label="Complemento"
            value={REGISTRO?.COMPLEMENTO || "N/A"}
          />
          <InfoDisplay label="Número" value={REGISTRO?.NUMERO || "N/A"} />
        </div>
        <InfoDisplay label="Logradouro" value={REGISTRO?.LOGRADOURO || "N/A"} />
      </section>

      <div style={{ margin: "24px 0px" }} />

      <section
        style={{ display: "flex", flexDirection: "column", gap: "24px" }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "24px",
          }}
        >
          <InfoDisplay label="CNPJ" value={REGISTRO?.CNPJ || "N/A"} />
          <InfoDisplay label="Endereço" value={REGISTRO?.ENDERECO || "N/A"} />
          <InfoDisplay label="Razão Social" value={REGISTRO?.RAZAO || "N/A"} />
          <InfoDisplay label="TP" value={REGISTRO?.TP || "N/A"} />
          <InfoDisplay label="Cidade" value={REGISTRO?.CIDADE || "N/A"} />
          <InfoDisplay
            label="TP Logradouro"
            value={REGISTRO?.TPLOGRADOURO || "N/A"}
          />
        </div>
        <InfoDisplay label="Cep" value={REGISTRO?.CEP || "N/A"} />
      </section>
    </Panel>
  );
};

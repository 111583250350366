import { http } from '../../config/axios'

export default function getMunicipio(params:any): Promise<any> {
  const queryParams = params
                ? Object.keys(params)
                      .map((k) => encodeURIComponent(params[k]))
                      .join('/')
                : '';
  return http
    .get('api/municipio/find/' + queryParams)
    .then((res) => {
      return res.data as any
    })
    .catch((error) => {
      console.error('Erro getMunicipio', error)
      throw Error(error.response.data)
    })
}

import Container from "../../components/menu/Container";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import findConsulta from "../../service/findConsulta";
import getConsultaDownloadResponse from "../../service/getConsultaDownloadResponse";
import React from "react";
import { Toolbar } from "primereact/toolbar";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import { SortOrder } from "primereact/api";
import { Button } from "primereact/button";
import "./consultas.css";
import { InputText } from "primereact/inputtext";
import { useGlobalState } from "../../components/globalState/GlobalStateProvider";

export default function HistoricoPage() {
  const { t } = useTranslation();
  const [consultas, setConsultas] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);
  const [usuario, setUsuario] = useState<Nullable<String | null>>(null);
  const [documento, setDocumento] = useState<Nullable<String | null>>(null);
  const [data, setData] = useState<Nullable<Date>>(new Date());
  const [dataFinal, setDataFinal] = useState<Nullable<Date>>(new Date());
  const { state } = useGlobalState();
  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, "0");
  };
  const formatDate = (date: Nullable<Date>) => {
    if (date) {
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("-");
    }
  };

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    data: formatDate(data),
    dataFinal: formatDate(dataFinal),
    usuario: usuario,
    documento: documento,
    idFranquia: state.user.franquiaSelected?.id,
    sortField: "data",
    sortOrder: SortOrder.DESC,
  });

  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: event.first / event.rows + 1,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      usuario: usuario,
      documento: documento,
      idFranquia: state.user.franquiaSelected?.id,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    });
  };

  const onSort = (event: any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      usuario: usuario,
      documento: documento,
      idFranquia: state.user.franquiaSelected?.id,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    });
  };

  const changeData = (data: Nullable<Date>) => {
    if (data) {
      setData(data);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: lazyState.first / lazyState.rows + 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFinal),
        usuario: usuario,
        documento: documento,
        idFranquia: state.user.franquiaSelected?.id,
        sortField: "data",
        sortOrder: SortOrder.DESC,
      });
    }
  };

  const changeDataFinal = (dataFi: Nullable<Date>) => {
    if (dataFi) {
      setDataFinal(dataFi);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: lazyState.first / lazyState.rows + 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFi),
        usuario: usuario,
        documento: documento,
        idFranquia: state.user.franquiaSelected?.id,
        sortField: "data",
        sortOrder: SortOrder.DESC,
      });
    }
  };

  const changeUsuario = (user: Nullable<String>) => {
    user = user ? user : null;
    setUsuario(user);
    setlazyState({
      first: lazyState.first,
      rows: lazyState.rows,
      page: lazyState.first / lazyState.rows + 1,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      usuario: user,
      documento: documento,
      idFranquia: state.user.franquiaSelected?.id,
      sortField: "data",
      sortOrder: SortOrder.DESC,
    });
  };

  const changeDocumento = (doc: Nullable<String>) => {
    doc = doc
      ? doc.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "")
      : null;
    setDocumento(doc);
    setlazyState({
      first: lazyState.first,
      rows: lazyState.rows,
      page: lazyState.first / lazyState.rows + 1,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      usuario: usuario,
      documento: doc,
      idFranquia: state.user.franquiaSelected?.id,
      sortField: "data",
      sortOrder: SortOrder.DESC,
    });
  };

  const cnpj = (v: string) => {
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
    return v;
  };

  const cpf = (v: string) => {
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  };

  const maskCpfCnpj = (input: string) => {
    console.log(input);
    if (input.length > 14) {
      return cnpj(input);
    } else {
      return cpf(input);
    }
  };

  const loadLazyData = () => {
    findConsulta(lazyState)
      .then((data) => {
        setTotalRecords(data.total);
        setConsultas(data.consultas);
      })
      .catch(() => {
        setTotalRecords(0);
        setConsultas(undefined);
      });
  };
  const cols = [
    { field: "data", header: "Data" },
    { field: "dataRequisicao", header: "Dt. Solicitação" },
    { field: "documento", header: "Documento" },
    { field: "codigoCliente", header: "Código Cliente" },
    { field: "usuario", header: "Usuário" },
    { field: "opcao", header: "Opção" },
    { field: "origem", header: "Origem" },
    { field: "cache", header: "Cache" },
    { field: "erro", header: "Erro" },
  ];
  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const lazy = {
          first: 0,
          rows: totalRecords,
          page: 1,
          data: formatDate(data),
          dataFinal: formatDate(dataFinal),
          usuario: usuario,
          documento: documento,
          idFranquia: state.user.franquiaSelected?.id,
          sortField: "data",
          sortOrder: SortOrder.DESC,
        };
        findConsulta(lazy).then((data) => {
          const doc: any = new jsPDF.default("portrait");
          doc.autoTable(exportColumns, data.consultas);
          doc.save("consultas.pdf");
        });
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const lazy = {
        first: 0,
        rows: totalRecords,
        page: 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFinal),
        usuario: usuario,
        documento: documento,
        idFranquia: state.user.franquiaSelected?.id,
        sortField: "data",
        sortOrder: SortOrder.DESC,
      };
      findConsulta(lazy).then((data) => {
        const worksheet = xlsx.utils.json_to_sheet(data.consultas);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "consultas");
      });
    });
  };

  const saveAsExcelFile = (excelBuffer: any, fileName: string) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([excelBuffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };
  const header = (
    <div className="flex-consulta align-items-center justify-content-end gap-2">
      <Button
        type="button"
        icon="pi pi-file-excel"
        severity="success"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      <Button
        type="button"
        icon="pi pi-file-pdf"
        severity="warning"
        rounded
        onClick={exportPdf}
        data-pr-tooltip="PDF"
      />
    </div>
  );

  const startContent = (
    <React.Fragment>
      <div style={{ background: "transparent" }}>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <label htmlFor="data" className="font-bold block mb-2">
                {t("dataInicial")}{" "}
              </label>
              <Calendar
                id="data"
                value={data}
                onChange={(e) => changeData(e.value)}
                showIcon
                dateFormat="dd/mm/yy"
                locale="pt"
              />
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <div className="flex-auto">
                <label htmlFor="documento" className="font-bold block mb-2">
                  {t("filtroConsulta")}
                </label>
                <InputText
                  id="documento"
                  maxLength={40}
                  onBlur={({ target }) => changeDocumento(target.value)}
                  style={{ width: "20rem" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: "transparent", marginLeft: "10rem" }}>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <label htmlFor="dataFinal" className="font-bold block mb-2">
                {t("dataFinal")}{" "}
              </label>
              <Calendar
                id="dataFinal"
                value={dataFinal}
                onChange={(e) => changeDataFinal(e.value)}
                showIcon
                dateFormat="dd/mm/yy"
                locale="pt"
              />
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              {state.user.funcoes.includes("CONSULTA_SUPER") && (
                <div className="flex-auto">
                  <label htmlFor="usuario" className="font-bold block mb-2">
                    {t("usuario")}{" "}
                  </label>
                  <InputText
                    id="usuario"
                    onBlur={(e) => changeUsuario(e.target.value)}
                    style={{ width: "20rem" }}
                    required
                  ></InputText>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  const endContent = <React.Fragment></React.Fragment>;

  const downloadResponse = (consulta: any) => {
    getConsultaDownloadResponse(consulta.id).then((blobFile: Blob) => {
      const url = window.URL.createObjectURL(
        new Blob([blobFile], { type: "octet/stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `response.txt`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
    });
  };

  const downloadTemplate = (consulta: any) => {
    return (
      <>
        {state.user.funcoes.includes("CONSULTA_DOWNLOAD") &&
          consulta.erro == "Não" && (
            <Button
              icon="pi pi-download"
              onClick={() => {
                downloadResponse(consulta);
              }}
              style={{
                background: "transparent",
                color: "gray",
                border: "none",
              }}
            />
          )}
        {state.user.funcoes.includes("CONSULTA_CACHE_WEB") &&
          consulta.erro == "Não" &&
          consulta.cache.includes("Não") &&
          consulta.protocolo && (
            <Button
              icon="pi pi-window-maximize"
              onClick={() => {
                window.open("/creditbureau/consultar/" + consulta.id, "_blank");
              }}
              style={{
                background: "transparent",
                color: "gray",
                border: "none",
              }}
            />
          )}
      </>
    );
  };

  useEffect(() => {
    loadLazyData();
  }, [lazyState]);

  return (
    <Container>
      <div className="row">
        <div className="col-lg-12 mb-3">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray">
              <i className="pi pi-history" style={{ fontSize: "2.0rem" }}></i>{" "}
              {t("historicoConsulta")}
            </h1>
          </div>
        </div>
      </div>
      <div className="card">
        <Toolbar start={startContent} end={endContent} />
        <br></br>
        <br></br>
        <DataTable
          value={consultas}
          first={lazyState.first}
          dataKey="id"
          paginator={true}
          lazy={true}
          emptyMessage="Sem registros"
          header={header}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          rows={lazyState.rows}
          totalRecords={totalRecords}
          onPage={onPage}
          tableStyle={{ minWidth: "75rem" }}
        >
          <Column field="data" sortable header={t("data")} />
          <Column
            field="dataRequisicao"
            sortable
            header={t("dataSolicitacao")}
          />
          <Column field="protocolo" sortable header={t("protocolo")} />
          <Column field="documento" sortable header={t("filtro")} />
          <Column field="codigoCliente" header={t("codigoCliente")} />
          <Column
            field="usuario"
            sortable
            sortField="usuario.nome"
            header={t("usuario")}
          />
          <Column field="opcao" sortable header={t("opcaoConsulta")} />
          <Column
            field="origem"
            sortable
            sortField="origemConsulta"
            header={t("origem")}
          />
          <Column field="cache" header={t("cache")} />
          <Column field="erro" header={t("erro")} />
          <Column style={{ width: "11%" }} body={downloadTemplate} />
        </DataTable>
      </div>
    </Container>
  );
}


import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";
import OutPutCard from "../../../components/outputCard";
import { Divider } from "primereact/divider";





export default function AutomotivaBaseEstadual({
  automotivaBaseEstadual
}: {
  automotivaBaseEstadual: any
}) {
  const { t } = useTranslation();
  automotivaBaseEstadual = automotivaBaseEstadual.RETORNO
  return (
    <Panel header={t('Automotiva Base Estadual')} style={{width:'98%'}}>
       <Divider className="automotiva" align="left">
        <div className="flex-score justify-content-between justify-content-center">
          <span className="p-tag" style={{position: 'relative', backgroundColor : '#183462', fontSize:'1.1rem', height: '2rem', marginTop: '0.5rem'}}>Resumo do Veículo</span>
        </div>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('placa')}</label>
                 <Chip style={{width:"100%"}} label={automotivaBaseEstadual.PLACA} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('marcaModelo')}</label>
                 <Chip style={{width:"100%"}} label={automotivaBaseEstadual.MARCAMODELOCOMPLETO} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('chassi')}</label>
              <Chip style={{width:"100%"}} label={automotivaBaseEstadual.CHASSI} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('motor')}</label>
              <Chip style={{width:"100%"}} label={automotivaBaseEstadual.MOTOR} />
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('renavam')}</label>
                 <Chip style={{width:"100%"}} label={automotivaBaseEstadual.RENAVAM} />
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('cor')}</label>
                 <Chip style={{width:"100%"}} label={automotivaBaseEstadual.COR} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('combustivel')}</label>
                 <Chip style={{width:"100%"}} label={automotivaBaseEstadual.COMBUSTIVEL} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('anoFabricaoModelo')}</label>
              <Chip style={{width:"100%"}} label={automotivaBaseEstadual.VEIANOFABR + '/' + automotivaBaseEstadual.VEIANOMODELO} />
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
       <Divider align="left">
        <span className="p-tag" style={{backgroundColor : '#183462', fontSize:'1.1rem'}}>Agregados</span>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Chassi" value={automotivaBaseEstadual.CHASSI}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Cilindradas" value={automotivaBaseEstadual.CILINDRADA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Espécie" value={automotivaBaseEstadual.ESPECIE}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Combustível" value={automotivaBaseEstadual.COMBUSTIVEL}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Potência" value={automotivaBaseEstadual.POTENCIA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Modelo" value={automotivaBaseEstadual.MARCAMODELOCOMPLETO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Marca" value={automotivaBaseEstadual.MARCA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Placa" value={automotivaBaseEstadual.PLACA}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Ano Fabricação" value={automotivaBaseEstadual.VEIANOFABR}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Ano Modelo" value={automotivaBaseEstadual.VEIANOMODELO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Capacidade" value={automotivaBaseEstadual.CAPACIDADEPASSAG}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Tipo" value={automotivaBaseEstadual.TIPO}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Município" value={automotivaBaseEstadual.MUNICIPIO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="UF" value={automotivaBaseEstadual.UF}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Procedência" value={automotivaBaseEstadual.VEIPROCEDENCIA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Motor" value={automotivaBaseEstadual.MOTOR}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Cor" value={automotivaBaseEstadual.COR}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Renavan" value={automotivaBaseEstadual.RENAVAM}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Comunicado Venda" value={automotivaBaseEstadual.CCOMUNICACAOVENDA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Data venda" value={automotivaBaseEstadual.DATAVENDA}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="CMT" value={automotivaBaseEstadual.CMT}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Data Atualização" value={automotivaBaseEstadual.DATAULTIMAATUALIZACAO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Tipo Documento Faturado" value={automotivaBaseEstadual.TIPODOCUMENTOFATURADO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="UF Documento Faturado" value={automotivaBaseEstadual.UFFATURADO}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Documento Faturado" value={automotivaBaseEstadual.CPFCNPJFATURADO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Cambio" value={automotivaBaseEstadual.CAIXACAMBIO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Capacidade Carga" value={automotivaBaseEstadual.CAPACIDADECARGA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="PBT" value={automotivaBaseEstadual.PBT}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Quantidade de Eixos" value={automotivaBaseEstadual.EIXOS}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Situação chassi" value={automotivaBaseEstadual.TIPOREMARCACAOCHASSI}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Renajud" value={automotivaBaseEstadual.RESRENAJUD}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Restrição Financeira" value={automotivaBaseEstadual.RESTRICAOFINAN}></OutPutCard>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
       <Divider align="left">
        <span className="p-tag" style={{backgroundColor : '#183462', fontSize:'1.1rem'}}>Proprietário</span>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Documento" value={automotivaBaseEstadual.CPF_CNPJ_PROPRIETARIO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Nome" value={automotivaBaseEstadual.PRONOME}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Tipo Documento" value={automotivaBaseEstadual.TIPODOCUMENTOPROPRIETARIO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Data Emissão CRV" value={automotivaBaseEstadual.DATAEMISSAOCRV}></OutPutCard>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
       <Divider align="left">
        <span className="p-tag" style={{backgroundColor : '#183462', fontSize:'1.1rem'}}>Financeiro</span>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="DPVAT" value={automotivaBaseEstadual.DPVAT}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Restrição Tributária" value={automotivaBaseEstadual.RESTRIBUTARIA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Licenciamento" value={automotivaBaseEstadual.EXISTEDEBITODELICENCIAMENTO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Valor Licenciamento" value={automotivaBaseEstadual.EXISTEDEBITODELICENCIAMENTOVL}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="IPVA" value={automotivaBaseEstadual.EXISTEDEBITODEIPVA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Valor IPVA" value={automotivaBaseEstadual.DEBIPVA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Multa" value={automotivaBaseEstadual.EXISTEDEBITOMULTA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Valor Multa" value={automotivaBaseEstadual.VALORTOTALDEBITOMULTA}></OutPutCard>
            </div>
          </div>
        </div>
    </Panel>
  )
}

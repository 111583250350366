import { Fragment } from "react";
import { Panel } from "primereact/panel";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";

import { ListDescriptions } from "../../../components/ListDescriptions";

export type CredifyProps = {
  data: any;
};

type Advogado = {
  NOME: string;
};

type Parte = {
  TIPO: string;
  POLO: string;
  CPF?: string;
  CNPJ?: string;
  NOME: string;
  ADVOGADOS?: Record<string, Advogado>;
};

type Processo = {
  UF: string;
  CLASSEPROCESSUAL: {
    NOME: string;
  };
  AREA: string;
  DATADISTRIBUICAO: string;
  STATUSPREDICTUS: {
    JULGAMENTOS?: {
      REGISTRO0?: {
        TIPOJULGAMENTO: string;
        DATAJULGAMENTO: string;
      };
    };
    STATUSPROCESSO: string;
  };
  TRIBUNAL: string;
  ESEGREDOJUSTICA: string;
  NUMEROPROCESSOUNICO: string;
  GRAUPROCESSO: string;
  VALORCAUSA: {
    VALOR: string;
  };
  PARTES: Record<string, Parte>;
};

type FormattedData = {
  label: string;
  value: string;
};

type ParteFormatada = {
  tipo: string;
  polo: string;
  cpf?: string;
  cnpj?: string;
  nome: string;
  advogados: string;
};

type FormattedResponse = {
  dadosPrincipais: FormattedData[];
  partes: ParteFormatada[];
};

function formatData(data: Record<string, Processo>): FormattedResponse[] {
  const formattedResponseArray: FormattedResponse[] = [];

  const formatValue = (label: string, value?: string) => {
    return { label, value: value || "-" };
  };

  const processRegistro = (registro: Processo): FormattedResponse => {
    const formattedData: FormattedData[] = [];

    formattedData.push(formatValue("UF", registro.UF));
    formattedData.push(
      formatValue("Classe Processual", registro.CLASSEPROCESSUAL?.NOME)
    );
    formattedData.push(formatValue("Área", registro.AREA));
    formattedData.push(
      formatValue("Data Distribuição", registro.DATADISTRIBUICAO)
    );
    formattedData.push(formatValue("Tribunal", registro.TRIBUNAL));
    formattedData.push(
      formatValue("Segredo de Justiça", registro.ESEGREDOJUSTICA)
    );
    formattedData.push(
      formatValue("Número do Processo Único", registro.NUMEROPROCESSOUNICO)
    );
    formattedData.push(formatValue("Grau do Processo", registro.GRAUPROCESSO));
    formattedData.push(
      formatValue("Valor da Causa", registro.VALORCAUSA?.VALOR)
    );

    if (registro.STATUSPREDICTUS) {
      formattedData.push(
        formatValue(
          "Status do Processo",
          registro.STATUSPREDICTUS.STATUSPROCESSO
        )
      );

      if (registro.STATUSPREDICTUS.JULGAMENTOS?.REGISTRO0) {
        const julgamento = registro.STATUSPREDICTUS.JULGAMENTOS.REGISTRO0;
        formattedData.push(
          formatValue("Tipo de Julgamento", julgamento.TIPOJULGAMENTO)
        );
        formattedData.push(
          formatValue("Data do Julgamento", julgamento.DATAJULGAMENTO)
        );
      }
    }

    const formatPartes = (): ParteFormatada[] => {
      const partesArray: ParteFormatada[] = [];
      const partes = registro.PARTES || {};

      Object.keys(partes).forEach((parteKey) => {
        const parte = partes[parteKey];

        const advogados = parte.ADVOGADOS
          ? Object.values(parte.ADVOGADOS)
              .map((a) => a.NOME)
              .join(", ")
          : "-";

        partesArray.push({
          tipo: parte.TIPO || "-",
          polo: parte.POLO || "-",
          cpf: parte.CPF || "-",
          cnpj: parte.CNPJ || "-",
          nome: parte.NOME || "-",
          advogados: advogados,
        });
      });

      return partesArray;
    };

    const partes = formatPartes();

    return {
      dadosPrincipais: formattedData,
      partes: partes,
    };
  };

  Object.keys(data).forEach((key) => {
    if (key.startsWith("REGISTRO")) {
      const registro = data[key];
      formattedResponseArray.push(processRegistro(registro));
    }
  });

  return formattedResponseArray;
}

export function Credify({ data }: CredifyProps) {
  const formattedResponse = formatData(data.DATA);

  return (
    <>
      {formattedResponse.map(({ dadosPrincipais, partes }, i) => (
        <Fragment key={i}>
          <ListDescriptions
            dados={dadosPrincipais}
            categoria="Informações do Processo"
          />

          <Panel header="Partes do Processo" style={{ marginBottom: 32 }}>
            <DataTable value={partes}>
              <Column field="tipo" header="Tipo" />
              <Column field="polo" header="Polo" />
              <Column field="cpf" header="CPF" />
              <Column field="cnpj" header="CNPJ" />
              <Column field="nome" header="Nome" />
              <Column field="advogados" header="Advogados" />
            </DataTable>
          </Panel>

          <Divider />
        </Fragment>
      ))}
    </>
  );
}

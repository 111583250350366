
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Card} from "primereact/card";
import limiteCreditoImg from "../../../assets/limiteCredito.svg"



export default function LimiteCreditoPJ({
  limiteCreditoPJ
}: {
  limiteCreditoPJ: any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('limiteCredito')} style={{width:'98%'}}>
       
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
          <div className="card p-0 overflow-hidden flex flex-column" style={{padding: '0', overflow: 'auto'}}>
              <div className="flex-score align-items-center p-3">
                <i className="pi pi-money-bill text-6xl text-green-500"></i>
                <div className="ml-3" style={{width: '100%'}}>
                  <span className="text-green-500 block white-space-nowrap">{t('limiteCredito')} Arriscado </span>
                  <span className="text-green-500 block text-4xl font-bold">{limiteCreditoPJ.limiteCredito1}</span>
                  
                </div>
                
                
              </div>
              <br></br>
              <div className="flex-score align-items-center">
                <div className="ml-3" style={{width: '100%'}}>
                  <span className="text-green-500 block white-space-nowrap">{t('percentualCapacidadePagamento')} </span>
                  <span className="text-green-500 block text-4xl font-bold">{limiteCreditoPJ.percentualCapacidadePagamento1}</span>
                  
                </div>
                
              </div>
              <img src={limiteCreditoImg} className="w-full" alt="conversion"/>

            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div className="card p-0 overflow-hidden flex flex-column" style={{padding: '0', overflow: 'auto'}}>
              <div className="flex-score align-items-center p-3">
                <i className="pi pi-money-bill text-6xl text-green-500"></i>
                <div className="ml-3" style={{width: '100%'}}>
                  <span className="text-green-500 block white-space-nowrap">{t('limiteCredito')} Moderado </span>
                  <span className="text-green-500 block text-4xl font-bold">{limiteCreditoPJ.limiteCredito2}</span>
                  
                </div>
                
                
              </div>
              <br></br>
              <div className="flex-score align-items-center">
                <div className="ml-3">
                  <span className="text-green-500 block white-space-nowrap">{t('percentualCapacidadePagamento')} </span>
                  <span className="text-green-500 block text-4xl font-bold">{limiteCreditoPJ.percentualCapacidadePagamento2}</span>
                  
                </div>
                
              </div>
              <img src={limiteCreditoImg} className="w-full" alt="conversion"/>

            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
          <div className="card p-0 overflow-hidden flex flex-column" style={{padding: '0', overflow: 'auto'}}>
              <div className="flex-score align-items-center p-3">
                <i className="pi pi-money-bill text-6xl text-green-500"></i>
                <div className="ml-3">
                  <span className="text-green-500 block white-space-nowrap">{t('limiteCredito')} Conservador </span>
                  <span className="text-green-500 block text-4xl font-bold">{limiteCreditoPJ.limiteCredito3}</span>
                  
                </div>
                
                
              </div>
              <br></br>
              <div className="flex-score align-items-center">
                <div className="ml-3">
                  <span className="text-green-500 block white-space-nowrap">{t('percentualCapacidadePagamento')} </span>
                  <span className="text-green-500 block text-4xl font-bold">{limiteCreditoPJ.percentualCapacidadePagamento3}</span>
                  
                </div>
                
              </div>
              <img src={limiteCreditoImg} className="w-full" alt="conversion"/>

            </div>
          </div>
          
          
      </div>

    </Panel>
  )
}

import { BiMenu } from "@react-icons/all-files/bi/BiMenu";
import logo from "../../assets/caropositivo.jpeg";
import './Topbar.css';
import { useGlobalState } from "../globalState/GlobalStateProvider";
import { GlobalSaveData } from "../globalState/GlobalSaveData";
import TopbarUserInfo from "./TopbarUserInfo";
import TopbarRelatorio from "./TopbarRelatorio";
import TopbarFranquia from "./TopbarFranquia";

const Topbar = () => {
  const {state} = useGlobalState();
  const handleToggleSidebar = () => {
   
    GlobalSaveData("sidebarOpen", !state.sidebarOpen);
  };

  return (
    <div id="interface-topbar">
      <div className="wrapper">
       <div  style={{paddingLeft:'10px'}}>
          <button onClick={handleToggleSidebar} style={{background:'transparent', border:'none'}}> 
            <BiMenu style={{color:'white', fontSize:'30px', cursor:'pointer'}} />
          </button>
        </div>
       
      <div style={{paddingLeft:'10px', flexGrow: 1}}><img src={logo} style={{width:'150px', height:'56px', paddingTop:'3px', backgroundColor: 'white'}} alt="Logo" /></div>
       {!state.user.franquia && state.user.funcoes.includes("FRANQUIA_ADM") && ( <TopbarFranquia/>)}  
       <TopbarRelatorio />
       <TopbarUserInfo />

        
      </div>
    </div>
  );
};

export default Topbar;

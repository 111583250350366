import { http } from '../../config/axios'

export default function postFranquia(franquia: any): Promise<string> {
  return http
    .post('api/franquia', franquia)
    .then((res) => {
      return res.data as string
    })
    .catch((error) => {
      console.error('Erro post franquia', error)
      throw Error(error.response.data)
    })
}

import {
  DadosProps,
  AlertStatus,
  CategoriaDados,
  ApiResponsePrecificadorFipe,
  CredifyPrecificadorFipeProps,
} from "./types";
import styles from "./styles.module.css";

const formatarDadosPrecificadorFipe = (
  retorno: ApiResponsePrecificadorFipe
): CategoriaDados[] => {
  const labelMap: { [key: string]: string } = {
    STATUS: "Status",
    CHASSI: "Chassi",
    VIGENCIA: "Vigência",
    PRECOMEDIO: "Preço Médio",
    PRECOFIPE: "Preço (FIPE)",
    MODELOFIPE: "Modelo (FIPE)",
    CODIGOFIPE: "Código (FIPE)",
    FABRICANTEFIPE: "Fabricante (FIPE)",
    COMBUSTIVELFIPE: "Combustível (FIPE)",
    ANOMODELOFIPE: "Ano do Modelo (FIPE)",
    DESCRICAORETORNO: "Descrição do Retorno",
  };

  const formatRecursively = (obj: any): DadosProps[] => {
    return Object.keys(obj).map((key) => {
      const value = obj[key];

      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        const subValue = Object.values(value).join(", ");
        return {
          label: labelMap[key] || key,
          value: subValue !== "" ? subValue : "-",
        };
      }

      return {
        label: labelMap[key] || key,
        value: value !== undefined && value !== "" ? String(value) : "-",
      };
    });
  };

  const categorias: CategoriaDados[] = [];

  Object.keys(retorno.PRECIFICADORFIPE).forEach((key) => {
    if (key.startsWith("REGISTRO_")) {
      const dadosRegistro = formatRecursively(retorno.PRECIFICADORFIPE[key]);
      categorias.push({
        categoria: key.replace(/_/g, " "),
        dados: dadosRegistro,
      });
    }
  });

  return categorias;
};

const alertStatuses: AlertStatus[] = [
  { color: "gray", icon: "pi-car", text: "RESTRIÇÕES" },
  { color: "gray", icon: "pi-car", text: "MULTAS E DÉBITOS" },
  { color: "gray", icon: "pi-car", text: "INDÍCIO DE SINISTRO" },
  { color: "gray", icon: "pi-car", text: "COMUNICAÇÃO DE VENDA" },
  { color: "gray", icon: "pi-car", text: "ROUBO E FURTO" },
  { color: "gray", icon: "pi-car", text: "VENDA DIRETA" },
  { color: "gray", icon: "pi-car", text: "LEILÃO" },
  { color: "gray", icon: "pi-car", text: "REMARKETING" },
  { color: "gray", icon: "pi-car", text: "DUPLICIDADE DE MOTOR" },
  { color: "gray", icon: "pi-car", text: "CHASSI REMARCADO" },
  { color: "gray", icon: "pi-car", text: "RENAJUD" },
  { color: "red", icon: "pi-car", text: "OUTROS ALERTAS" },
];

export function CredifyPrecificadorFipe({
  data,
}: CredifyPrecificadorFipeProps) {
  const result = formatarDadosPrecificadorFipe(data);

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.headerItem}>
          <i className={`pi pi-calendar ${styles.headerIcon}`}></i>
          <span className={styles.label}>DATA DA PESQUISA:</span>
          <span className={styles.value}>08/07/2024</span>
        </div>
        <div className={styles.headerItem}>
          <i className={`pi pi-clock ${styles.headerIcon}`}></i>
          <span className={styles.value}>15:46:59</span>
        </div>
        <div className={styles.headerItem}>
          <i className={`pi pi-calendar ${styles.headerIcon}`}></i>
          <span className={styles.label}>DATA DA IMPRESSÃO:</span>
          <span className={styles.value}>23/08/2024</span>
        </div>
        <div className={styles.headerItem}>
          <i className={`pi pi-clock ${styles.headerIcon}`}></i>
          <span className={styles.value}>16:09:35</span>
        </div>
      </div>

      <div className={styles.alertContainer}>
        {alertStatuses.map((status, index) => (
          <div
            key={index}
            className={`${styles.alertBox} ${
              status.color === "red" ? styles.red : styles.gray
            }`}
          >
            <div className={styles.icon}>
              <i
                style={{ fontSize: "2rem" }}
                className={`pi ${status.icon}`}
              ></i>
            </div>
            <div className={styles.textAlert}>{status.text}</div>
          </div>
        ))}
      </div>

      {result.map(({ categoria, dados }) => (
        <div key={categoria} className={styles.wrapper}>
          <div className={styles.header}>
            <p className={styles.title}>Detalhes do veículo: {categoria}</p>
          </div>
          <div>
            <div className={styles.listaWrapper}>
              {dados.map(({ label, value }) => (
                <div className={styles.dadosWrapper}>
                  <p className={styles.text}>{label}: </p>&nbsp;
                  <p className={`${styles.text} ${styles.value}`}>
                    {`${value || "-"}`}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

import { http } from '../../config/axios'

 const findFranquia = (params:any): Promise<any> => {
  const queryParams = params
                ? Object.keys(params)
                      .map((k) => encodeURIComponent(params[k]))
                      .join('/')
                : '';
  return http
    .get('api/franquia/find/' + queryParams)
    .then((res) => {
      return res.data as any
    })
    .catch((error) => {
      console.error('Erro findFranquia', error)
      throw Error(error.response.data)
    })
}

export default findFranquia;

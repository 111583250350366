import { http } from '../../config/axios'

export default function postRecuperarSenha(email: String): Promise<string> {

  return http
    .post('api/gatewaybiro/recuperation', email, {
      headers: {
        "Content-Type":"text/plain"
      }
  })
    .then((res) => {
      return res.data as string
    })
    .catch((error) => {
      console.error('Erro ao Recuperar Senha', error)
      throw Error(error.response.data)
    })
}

import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";
import PermissaoList from './PermissaoList';
import React, { SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Messages } from 'primereact/messages';
import { Dialog } from 'primereact/dialog';
import { Controller, useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import postPermission from '../../service/postPermission';
import putPermission from '../../service/putPermission';
import { Checkbox } from 'primereact/checkbox';
import { Tree, TreeCheckboxSelectionKeys, TreeMultipleSelectionKeys } from 'primereact/tree';
import getPermissao from '../../service/getPermissao';
import { InputNumber } from 'primereact/inputnumber';


export default function PermissaoPage() {
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const [permission, setPermission] = useState<any | null>(null);
  const [permissionDad, setPermissionDad] = useState<any | null>(null);
  const labelButton = t('cadastrar')
  const [selectedTipoConsulta, setselectedTipoConsulta] = useState<number|undefined>();
  const [date, setDate] = useState(new Date());
  const [checked, setChecked] = useState(false);
  const [nodes, setNodes] = useState<any[]>([]);
  const [expandedKeyss] = useState<any>({'QUODPF': true});
  const [selectedKeys, setSelectedKeys] = useState<string | TreeMultipleSelectionKeys | TreeCheckboxSelectionKeys | null>(null); 
  const [idsPermissoes, setIdsPermissoes] = useState<any[]>([]);
  const [listInactivePermissions, setListInactivePermissions] = useState(false);
  
  const defaultValues = useMemo(() => {
  
    return {
      identificador: '',
      descricao: '',
      fornecedorConsulta: '',
      ordenacao: null,
      inativa: null
    };
}, [permission]);
  const footerContent = (
  <React.Fragment>
        <Button form='form-permission' style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar" type="submit"   />
        <Button label="Fechar" icon="pi pi-times" style={{color:'#183462'}} onClick={() => resetForm()} className="p-button-text" />
  </React.Fragment>
  );
  const startContent = (
    <React.Fragment>
        <Button label={labelButton} icon="pi pi-plus" style={{backgroundColor:'#183462'}} onClick={() => {setPermission({id:undefined})}}/>
        <div style={{ marginLeft: '8rem', display: 'inline-flex', alignItems: 'center' }}>
        <Checkbox 
          inputId="listInactivePermissions" 
          onChange={e => setListInactivePermissions(e.checked ? true : false)} 
          checked={listInactivePermissions}
        />
        <label htmlFor="listInactivePermissions" className="ml-2">Listar Também Permissões Inativas</label>
      </div>
    </React.Fragment>
);

const {
  control,
  formState: { errors},
  handleSubmit,
  reset,
  setValue
} = useForm({ defaultValues });

const onSubmit = (data:any) => {
  data.identificador && save(data);
};

const resetForm = () => {
  setChecked(false);
  setselectedTipoConsulta(undefined);
  setPermission(null);
  reset();
}

const tiposConsulta = [
  { name: 'Selecione', code: undefined },
  { name: 'Consulta Pessoa Física', code: 0 },
  { name: 'Consulta Pessoa Jurídica', code: 1 },
  { name: 'Consulta Pessoa  PF/PJ', code: 2 },
  { name: 'Consulta Automotiva', code: 3 }
];

const save = (data:any) => {
  const opcoes: string[] = [];
    if(selectedKeys){
      Object.getOwnPropertyNames(selectedKeys).map((item) => opcoes.push(item));
    }
  const permissao = {
    id: permission.id? permission.id : undefined,
    label: data.descricao,
    value: data.identificador,
    ordenacao: data.ordenacao,
    idPai: permissionDad?.id,
    tipoConsulta: selectedTipoConsulta,
    agrupadorConsulta: checked,
    consultas:opcoes,
    fornecedorConsulta: data.fornecedorConsulta,
    inativa: data.inativa
  }
  window.scrollTo(0, 0);
 
  savePermission(permissao)
} 
const savePermission = (permission:any) => {
  if(permission.id){
    putPermission(permission).then(data => {
      if(data.includes('Erro')){
        if (msgs.current) {
          msgs.current?.clear();
          msgs.current?.show([
              { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
             
          ]);
        }
      }
      else{
        setDate(new Date())
        resetForm();
        if (msgs.current) {
          msgs.current?.clear();
          msgs.current?.show([
              { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
             
          ]);
        }
      }
    })
  }
  else{
    postPermission(permission).then(data => {
      if(data.includes('Erro')){
        if (msgs.current) {
          msgs.current?.clear();
          msgs.current?.show([
              { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
             
          ]);
        }
      }
      else{
        setDate(new Date())
        resetForm();
        if (msgs.current) {
          msgs.current?.clear();
          msgs.current?.show([
              { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
             
          ]);
        }
      }
    })
  }
  
  
}
const createPermissoes = () => {
  if(selectedTipoConsulta == 0 || selectedTipoConsulta == 1 || selectedTipoConsulta == 3){
    getPermissao().then(data => {
      const optionsPF = data.filter((f: {
        agrupadorConsulta: boolean; tipoConsulta: string; 
}) => f.agrupadorConsulta == false && (f.tipoConsulta === (selectedTipoConsulta === 3 ? 'AUTO' : (selectedTipoConsulta === 0 ? 'PF' : 'PJ') || f.tipoConsulta === 'PFPJ')))
      optionsPF.forEach((element: {
        children: any; label: string; value: string; permissoes:any[] 
}) => {
        verifyChecked(element);
        if(element.children){
          element.children.forEach((child:any) => {verifyChecked(child)})
        }
        option.push({label:element.label, key:element.value, Collapse:true, children: element.permissoes ? createPermissoesFilhos(element.permissoes.filter((f: {
          agrupadorConsulta: boolean; tipoConsulta: string; 
}) => f.agrupadorConsulta == false && (f.tipoConsulta === (selectedTipoConsulta === 3 ? 'AUTO' :  (selectedTipoConsulta === 0 ? 'PF' : 'PJ') || f.tipoConsulta === 'PFPJ')))) : element.children });
      });
    })
  }
  
  const option : any[] = [];
  
  
 
  return option;
}
const createPermissoesFilhos = (permissoes:any[]) => {
  const option : any[] = [];
  if(permissoes){
    permissoes.forEach((element: {
      children: any; label: string; value: string; permissoes:any[]  
}) => {
      verifyChecked(element);
      if(element.children){
        element.children.forEach((child:any) => {verifyChecked(child)})
      }

      option.push({label:element.label, key:element.value, Collapse:true, children: element.permissoes ? createPermissoesFilhos(element.permissoes.filter((f: {
        agrupadorConsulta: boolean; tipoConsulta: string; 
}) => f.agrupadorConsulta == false && (f.tipoConsulta === (selectedTipoConsulta === 3 ? 'AUTO' : (selectedTipoConsulta == 0 ? 'PF' : 'PJ') || f.tipoConsulta === 'PFPJ')))) : element.children});
    });
  }
  return option;
}

const verifyChecked = (element:any) => {

  if(permission && permission.consultas){
    
    const checked = permission.consultas.find((k:string) => k == element.value);

    if(checked){
      idsPermissoes.push(element.value)
      const selectedKeysChecked: SetStateAction<string | TreeMultipleSelectionKeys | TreeCheckboxSelectionKeys | null> = {
    
      }

      idsPermissoes.forEach(id => {
        selectedKeysChecked[id] = {
          partialChecked: false,
          checked: true
        }
      });
      
     setSelectedKeys(selectedKeysChecked);
    }
  }
}

const edit = (permission:any) => {
  setValue('descricao', permission.label);
  setValue('identificador', permission.value);
  setValue('ordenacao', permission.ordenacao);
  setValue('inativa', permission.inativa);
  setValue('fornecedorConsulta', permission.fornecedorConsulta);
  setChecked(permission.agrupadorConsulta)
  setPermission(permission)
  if(permission.idPai){
    setTimeout(() => {
      setPermissionDad({id:permission.idPai, value: permission.valuePai, label: permission.labelPai})
    }, 100);
  }
  if(permission.tipoConsulta){
    setselectedTipoConsulta((permission.tipoConsulta == 'PF' ? 0 : (permission.tipoConsulta == 'PJ' ? 1 : (permission.tipoConsulta == 'PFPJ' ? 2 : 3))))
  }
} 

useEffect(() => {
  setNodes(createPermissoes());
  
},[permission, selectedTipoConsulta])
const getFormErrorMessage = (name:string) => {
type ObjectKey = keyof typeof errors;
const error = errors[Object.keys(errors).find(k => k === name) as ObjectKey]
return error ? <small className="p-error">{error.message}</small> : <small className="p-error">&nbsp;</small>;
};

  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-code" style={{ fontSize: '2.0rem' }}></i> {t('permissoes')}</h1>
            </div>     
          </div>
      </div>
      <div className="card">
        <Messages ref={msgs} />
        
          
        <Toolbar start={startContent} />
        <br></br>
        <br></br>
        <PermissaoList 
          date={date} 
          onSelect={(permissionSelect:any) => { if (!permission?.id) { setPermissionDad(permissionSelect) }}} 
          onEdit={(permission:any) => { edit(permission); }}
          listInactivePermissions={listInactivePermissions}
        />
        <Dialog header={t('permissao')} visible={permission} style={{ width: '80vw' }} onHide={() => resetForm()} footer={footerContent}>
          <form id='form-permission' onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
           
            <div>
              <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="identificador"
                          control={control}
                          rules={{ required: 'Identificador é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.identificador })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="identificador" className="font-bold block mb-2 required">{t('identificador')} </label>
                                    <InputText disabled={permission?.id} style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="descricao"
                          control={control}
                          rules={{ required: 'Descrição é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.descricao })}></label>
                                  <span className="">
                                  <div>
                                      <label htmlFor="descricao" className="font-bold block mb-2 required">{t('descricao')} </label>
                                      <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                  </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                  </div>
              </div>
              <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="ordenacao"
                          control={control}
                         
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.ordenacao })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="ordenacao" className="font-bold block mb-2">{t('ordenacao')} </label>
                                    <InputNumber mode='decimal' style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  </div>
                  <div className="col">
                    <Controller
                      name="inativa"
                      control={control}
                      rules={{ validate: value => value !== null || 'Status é Obrigatório' }}
                      render={({ field, fieldState }) => (
                        <>
                          <label htmlFor={field.name} className={classNames({ 'p-error': errors.inativa })}></label>
                          <span className="">
                            <div>
                              <label htmlFor="inativa" className="font-bold block mb-2 required">{t('status')} </label>
                              <Dropdown 
                                style={{ width: '100%' }} 
                                id={field.name} 
                                value={field.value} 
                                className={classNames({ 'p-invalid': fieldState.error })} 
                                onChange={(e) => field.onChange(e.value)} 
                                options={[
                                  { name: 'Ativa', inativa: false }, 
                                  { name: 'Inativa', inativa: true }
                                ]} 
                                optionLabel="name" 
                                placeholder="Selecione o Status" 
                                optionValue='inativa'
                              />
                            </div>
                          </span>
                          {getFormErrorMessage(field.name)}
                        </>
                      )}
                    />
                  </div>
              </div>
            </div>
            <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    <label htmlFor="permissaoPai" className="font-bold block mb-2">{t('permissaoPai')} </label>
                    <InputText style={{width:'100%'}} value={permissionDad?.label} disabled />
                  </div>
                  <div className="col">
                    <label htmlFor="permissaoPai" className="font-bold block mb-2">{t('tipoConsulta')} </label>
                    <Dropdown value={selectedTipoConsulta} disabled={permission?.id} onChange={(e) => setselectedTipoConsulta(e.value)} options={tiposConsulta} optionLabel="name" 
                      placeholder="Selecione o tipo Consulta" optionValue='code' style={{width:'100%'}} className="w-full md:w-14rem" />
                  </div>
            </div>
            <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    <div className="flex align-items-center">
                        <Checkbox inputId="agrupadorConsulta" onChange={e => setChecked(e.checked? true:false)} checked={checked} disabled={selectedTipoConsulta != 0 && selectedTipoConsulta != 1 && selectedTipoConsulta != 3}/>
                        <label htmlFor="ingredient2" className="ml-2">{t('agruparConsulta')}</label>
                    </div>
                  </div>
                  {selectedTipoConsulta !== undefined && selectedTipoConsulta >= 0 && (
                    <div className="col p-fluid flex flex-wrap">
                      <Controller
                            name="fornecedorConsulta"
                            control={control}
                            render={({ field, fieldState }) => (
                                <>
                                    <label htmlFor={field.name} className={classNames({ 'p-error': errors.descricao })}></label>
                                    <span className="">
                                    <div>
                                        <label htmlFor="forncedor" className="font-bold block mb-2">{t('forncedorConsulta')} </label>
                                        <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                    </span>
                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />
                    </div>
                  )}
                  
              
            </div>
            {checked &&  (
              <Tree expandIcon={undefined} collapseIcon={undefined} expandedKeys = {expandedKeyss} value={nodes} selectionMode="checkbox" selectionKeys={selectedKeys} onSelectionChange={(e) => setSelectedKeys(e.value)} />
            )}
            
          </form>
               

        </Dialog>  
      
        
      </div>
                
    </Container>
  )
}

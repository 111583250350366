import { useMemo } from "react";
import { useGlobalState } from "../globalState/GlobalStateProvider";
import menuItems from "../../content/menuItems";
import usePopstate from "../../hooks/usePopstate";
import MenuItem from "./MenuItem";
import './Sidebar.css';

const Sidebar = () => {
  const { currentPath } = usePopstate();
  const dividedMenuItems = ["dispatchers", "carriers", "configurations"];
  const {state} = useGlobalState();

  const handleIsActive = (url: string) => {
    const reg = new RegExp(currentPath.split('\/')[currentPath.split('\/').length - 1], "g");
    const isActive = reg.test(url);
    return isActive;
  };

  const userPermissions = useMemo(() => { 
    const result : string[] = state.user.funcoes;
    return result;
  }, []);

  const hasPermission = (permissionName:string) => {
    const has = userPermissions.includes(permissionName);
    
    return has;
  };

  const filteredMenuItems = useMemo(() => {
    let hasDivider = false;
    const filtered = menuItems
      .filter((item) => !item.permission || hasPermission(item.permission))
      .map((item) => {
        if (dividedMenuItems.includes(item.label) && !hasDivider) {
          item.hasDivider = true;
          hasDivider = true;
        }

        return item;
      });
      
    return filtered;
  }, [userPermissions]);

  return (
    <div
      id="interface-sidebar"
      className={!!state?.sidebarOpen ? "opened" : "closed"}
    >
      <menu>
        {filteredMenuItems.map((item) => (
          <MenuItem
            key={item.url}
            isActive={handleIsActive(item.url)}
            {...{ item }}
          />
        ))}
      </menu>
    </div>
  );
};

export default Sidebar;

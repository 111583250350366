import { ListDescriptions } from "../../../../components/ListDescriptions";
import { BuscaTelefone, DadosFormatados, RetornoEndpoint } from "./types";

const formatarChave = (key: string): string => {
  const labelMap: { [key: string]: string } = {
    TP: "Tipo",
    CEP: "CEP",
    UF: "Estado",
    CNPJ: "CNPJ",
    BAIRRO: "Bairro",
    CIDADE: "Cidade",
    NUMERO: "Número",
    ENDERECO: "Endereço",
    RAZAO: "Razão Social",
    LOGRADOURO: "Logradouro",
    COMPLEMENTO: "Complemento",
    TPLOGRADOURO: "Tipo de Logradouro",
  };

  return labelMap[key] || key;
};

const formatarDados = (buscaTelefone: BuscaTelefone): DadosFormatados[] => {
  return Object.keys(buscaTelefone).map((registroKey) => {
    const registro = buscaTelefone[registroKey as keyof BuscaTelefone];

    const dados = Object.entries(registro).map(([key, value]) => {
      const label = formatarChave(key);
      return { label, value };
    });

    return {
      categoria: registroKey.replace("_", " ").replace("REGISTRO", "Registro"),
      dados,
    };
  });
};

export function CredifyPfTelefone({ data }: RetornoEndpoint) {
  const dados = formatarDados(data.BUSCATELEFONE);

  return (
    <>
      {dados.map(({ categoria, dados }) => (
        <ListDescriptions key={categoria} dados={dados} categoria={categoria} />
      ))}
    </>
  );
}

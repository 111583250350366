
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import OutPutCard from "../../../components/outputCard";
import { Divider } from "primereact/divider";





export default function AutomotivaAgregado({
  automotivaAgregado
}: {
  automotivaAgregado: any
}) {
  const { t } = useTranslation();
  automotivaAgregado = automotivaAgregado.RETORNO
  return (
    <Panel header={t('Automotiva Agregado')} style={{width:'98%'}}>
       <Divider className="automotiva" align="left">
        <div className="flex-score justify-content-between justify-content-center">
          <span className="p-tag" style={{position: 'relative', backgroundColor : '#183462', fontSize:'1.1rem', height: '2rem', marginTop: '0.5rem'}}>Resumo do Veículo</span>
         
            <img style={{width: '150px', position:'relative'}} src={'data:image/png;base64, ' + automotivaAgregado.imagem}></img>
         
            <img style={{width: '50px', position:'relative'}} src={automotivaAgregado.logo}></img>
          
        </div>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('placa')}</label>
                 <Chip style={{width:"100%"}} label={automotivaAgregado.PLACA} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('marcaModelo')}</label>
                 <Chip style={{width:"100%"}} label={automotivaAgregado.MARCAMODELO} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('chassi')}</label>
              <Chip style={{width:"100%"}} label={automotivaAgregado.CHASSI} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('motor')}</label>
              <Chip style={{width:"100%"}} label={automotivaAgregado.MOTOR} />
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('renavam')}</label>
                 <Chip style={{width:"100%"}} label={automotivaAgregado.RENAVAM} />
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('cor')}</label>
                 <Chip style={{width:"100%"}} label={automotivaAgregado.CORVEICULO} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('combustivel')}</label>
                 <Chip style={{width:"100%"}} label={automotivaAgregado.COMBUSTIVEL} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('anoFabricaoModelo')}</label>
              <Chip style={{width:"100%"}} label={automotivaAgregado.ANOFABRICACAO + '/' + automotivaAgregado.ANOMODELO} />
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
       <Divider align="left">
        <span className="p-tag" style={{backgroundColor : '#183462', fontSize:'1.1rem'}}>Agregados</span>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Chassi" value={automotivaAgregado.CHASSI}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Cilindradas" value={automotivaAgregado.CILINDRADAS}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Espécie" value={automotivaAgregado.ESPECIEVEICULO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Combustível" value={automotivaAgregado.COMBUSTIVEL}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Potência" value={automotivaAgregado.POTENCIA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Modelo" value={automotivaAgregado.MARCAMODELO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Marca" value={automotivaAgregado.FABRICANTE}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Placa" value={automotivaAgregado.PLACA}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Ano Fabricação" value={automotivaAgregado.ANOFABRICACAO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Ano Modelo" value={automotivaAgregado.ANOMODELO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Capacidade" value={automotivaAgregado.CAPACIDADEPASSAGEIRO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Tipo" value={automotivaAgregado.TIPOVEICULO}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Município" value={automotivaAgregado.MUNICIPIO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="UF" value={automotivaAgregado.UF}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Procedência" value={automotivaAgregado.PROCEDENCIA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Motor" value={automotivaAgregado.MOTOR}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Cor" value={automotivaAgregado.CORVEICULO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Renavan" value={automotivaAgregado.RENAVAM}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Segmento" value={automotivaAgregado.SEGMENTO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Sub Segmento" value={automotivaAgregado.SUBSEGMENTO}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="CMT" value={automotivaAgregado.CAPACMAXTRACAO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Data Atualização" value={automotivaAgregado.DATAULTIMAATUALIZACAO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Tipo Documento Faturado" value={automotivaAgregado.TIPODOCFATURADO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="UF Documento Faturado" value={automotivaAgregado.UFFATURADO}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Documento Faturado" value={automotivaAgregado.DOCFATURADO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Cambio" value={automotivaAgregado.CAIXACAMBIO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Capacidade Carga" value={automotivaAgregado.CAPACIDADECARGA}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="PBT" value={automotivaAgregado.PESOBRUTOTOTAL}></OutPutCard>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Quantidade de Eixos" value={automotivaAgregado.NUMEROEIXOS}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Situação chassi" value={automotivaAgregado.SITUACAOCHASSI}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap"></div>
          <div className="col p-fluid flex flex-wrap"></div>
        </div>
      
    </Panel>
  )
}

import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";
import './Home.css';
import { useGlobalState } from "../../components/globalState/GlobalStateProvider";
import graficoConsulta from '../../service/graficoConsulta';
import { Panel } from 'primereact/panel';
import { useEffect, useState } from 'react';
import Pie from './Pie';
import LineChart from './LineChart';
import BarChart from './BarChart';


export default function Page() {
  const { t } = useTranslation();
  const {state} = useGlobalState();
  const [grafico, setGrafico] = useState<any>(null);

  useEffect(
    () => {
      graficoConsulta().then((data) => setGrafico(data))
    },[])

  const getDataAtual = () => {
      const data = new Date();
      const day = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"][data.getDay()];
      const date = data.getDate();
      const month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][data.getMonth()];
      const year = data.getFullYear();
      return day + ', ' + date + ' de ' +  month + ' de ' + year + '.';
    
  }

  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-home" style={{ fontSize: '2.0rem' }}></i> {t('home')}</h1>
            </div>     
          </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mb-3">
          <div className="cardHome shadow">
            <div className="card-header py-3">
                <h4 className="m-0 font-weight-bold text-primary h6">Olá, {state.user.nomeUsuario}</h4>
            </div>
            <div className="card-body">
              <h4 style={{color: '#858796'}} className='h6'> { getDataAtual()} </h4>
              <h4 style={{color: '#858796'}} className='h6'>Bem-vindo! Ao sistema de consultas da Ultracheck Carro Positivo.</h4>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      {grafico && (
        <Panel header={t('dashboard')} style={{width:'99%'}}>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div >
                <Pie title='Consultas Por Origem' series={grafico.consultasOrigem}></Pie>
              </div>
            </div>
            <div className="col">
              <div >
                <Pie title='Consultas Por Opção' series={grafico.consultasOpcao}></Pie>
              </div>
            </div>
          </div>
           <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div >
                <LineChart series={grafico.consultaPorHora} title='Consultas Por Hora'></LineChart>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div >
                <BarChart series={grafico.consultaPorUsuario} title='Consultas Por Usuário'></BarChart>
              </div>
            </div>
          </div>
          
         
          
        </Panel>
      )}
      
    </Container>
  )
}

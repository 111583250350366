import { Chip } from "primereact/chip";

import styles from "./styles.module.css";

export type DescriptionProps = {
  label: string;
  value: string | number | null;
};

export function Description({ label, value }: DescriptionProps) {
  return (
    <div className={styles.wrapper}>
      <p className={`${styles.text} ${styles.label}`}>{label}</p>

      <Chip style={{ width: "auto" }} label={`${value || "-"}`} />
    </div>
  );
}

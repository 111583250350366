// utils/validationUtils.ts

export const formatCpfOrCnpj = (value: string): string => {
    // Remove qualquer caractere que não seja dígito
    const cleanValue = value.replace(/\D/g, '');

    // Verifica se o valor é um CPF (11 dígitos) ou CNPJ (14 dígitos)
    if (cleanValue.length === 11) {
        // Formata CPF: 000.000.000-00
        return cleanValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cleanValue.length === 14) {
        // Formata CNPJ: 00.000.000/0000-00
        return cleanValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
        // Retorna erro ou mensagem caso o valor não tenha o tamanho de CPF ou CNPJ
        return 'Valor inválido para CPF ou CNPJ';
    }
}

export const formatCep = (cep: string): string => {
    // Remove qualquer caractere que não seja dígito
    const cleanCep = cep.replace(/\D/g, '');

    // Verifica se o CEP tem o tamanho correto (8 dígitos)
    if (cleanCep.length !== 8) {
        return 'CEP inválido';
    }

    // Formata o CEP no formato 00000-000
    return cleanCep.replace(/(\d{5})(\d{3})/, '$1-$2');
}

export const formatPhoneNumber = (phoneNumber: string): string => {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

    // Verifica se o número tem o formato válido de 10 ou 11 dígitos
    if (cleanedPhoneNumber.length === 10) {
        return cleanedPhoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3'); // Formato (XX) XXXX-XXXX
    } else if (cleanedPhoneNumber.length === 11) {
        return cleanedPhoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3'); // Formato (XX) XXXXX-XXXX
    }

    return ''; // Retorna vazio se o número não for válido
};

// Validação de CPF
export const isValidCPF = (cpf: string): boolean => {
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        return false; // CPF inválido se não tiver 11 dígitos ou todos os números forem iguais
    }

    let sum: number;
    let rest: number;
    sum = 0;

    for (let i = 1; i <= 9; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(cpf.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(cpf.substring(10, 11))) return false;

    return true;
};

// Validação de CNPJ
export const isValidCNPJ = (cnpj: string): boolean => {
    cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

    if (cnpj.length !== 14) return false;

    let length: number = cnpj.length - 2;
    let numbers: string = cnpj.substring(0, length);
    let digits: string = cnpj.substring(length);
    let sum: number = 0;
    let pos: number = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += parseInt(numbers.charAt(length - i)) * pos--;
        if (pos < 2) pos = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) return false;

    length += 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += parseInt(numbers.charAt(length - i)) * pos--;
        if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(1))) return false;

    return true;
};

// Validação de e-mail
export const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

// Validação de campo vazio
export const isEmpty = (value: string | null | undefined): boolean => {
    return value === null || value === undefined || value.trim() === '';
};

import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import logo from "../../assets/new-logo-car.png";
import postRecuperarSenha from "../../service/postRecuperarSenha/postRecuperarSenhaService";
import "./styles.css";
import { Label } from "../../components/Label";
import { Button } from "../../components/Button";

export default function RecuperarSenha() {
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const navigate = useNavigate();

  const submit = () => {
    if (email) {
      setTimeout(() => {
        postRecuperarSenha(email)
          .then((data) => {
            msgs.current?.clear();
            msgs.current?.show([
              {
                sticky: false,
                life: 100000,
                severity: "info",
                summary: "Info",
                detail: data,
                closable: false,
              },
            ]);
          })
          .catch((error) => {
            msgs.current?.clear();
            msgs.current?.show([
              {
                sticky: false,
                life: 100000,
                severity: "error",
                summary: "Erro",
                detail: "Login ou Senha inválidos",
                closable: false,
              },
            ]);
          });
      }, 1000);
    }
  };
  return (
    <div className="main-reset-password">
      <div className="container-reset-password">
        <div className="container-image-reset-password">
          <img src={logo} alt="logo" />
        </div>

        <div className="container-form-reset-password">
          <Messages ref={msgs} />

          <h2>Solicitação de recuperação de senha</h2>

          <div className="input-container-reset-password">
            <Label htmlFor="email" text={t("inserirEmail")} required />
            <InputText
              id="email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <Button onClick={submit} text="Recuperar senha" />

          <Button
            onClick={() => navigate("/")}
            text="Voltar"
            variants="outline"
          />
        </div>
      </div>
    </div>
  );
}

import { Column } from "primereact/column";
import { Message } from "primereact/message";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";

import styles from "./styles.module.css";
import { ListDescriptions } from "../../../../components/ListDescriptions";

type RenajudRecord = {
  PLACA: string;
  CHASSI: string;
  OCORRENCIA: number;
  CHAVERETORNO: number;
  NOMETRIBUNAL: string;
  CODIGOTRIBUNAL: string;
  DATADAINCLUSAO: string;
  QTDOCORRENCIAS: number;
  MUNICIPIOPLACA: string;
  NUMERODOPROCESSO: string;
  NOMEORGAOJUDICIAL: string;
  TIPORESTRICJUDICIAL: string;
  CODIGOORGAOJUDICIAL: string;
  CONSTARESTRICAORENAJUD: string;
};

type Retorno = {
  COR: string;
  BASE: string;
  TIPO: string;
  PLACA: string;
  EIXOS: number;
  DADOS: string;
  MOTOR: string;
  CHASSI: string;
  ESPECIE: string;
  RENAVAM: string;
  MSG_ERRO: string;
  ANOMODELO: number;
  MUNICIPIO: string;
  PROCEDENCIA: string;
  COMBUSTIVEL: string;
  MARCAMODELO: string;
  EXISTE_ERRO: number;
  CHAVERETORNO: number;
  CODIGORETORNO: string;
  ANOFABRICACAO: number;
  CAPACIDADEPASSAGEIROS: number;
  QUANTIDADE_DE_OCORRENCIAS_TOTAL_EXISTENTE: number;
  QUANTIDADE_DE_BLOQUEIO_ATIVO_LOCALIZADO: number;
  QUANTIDADE_DE_OCORRENCIAS_EXIBIDAS_E_DISPONIVEL_PELO_DETRAN: number;
};

type FormattedItem = {
  label: string;
  value: string | number;
};

const formatRetorno = (retorno: Retorno): FormattedItem[] => {
  const labelMap: { [key: string]: string } = {
    COR: "Cor",
    BASE: "Base",
    TIPO: "Tipo",
    PLACA: "Placa",
    EIXOS: "Eixos",
    DADOS: "Dados",
    MOTOR: "Motor",
    CHASSI: "Chassi",
    ESPECIE: "Espécie",
    RENAVAM: "Renavam",
    MUNICIPIO: "Município",
    PROCEDENCIA: "Procedência",
    COMBUSTIVEL: "Combustível",
    EXISTE_ERRO: "Existe Erro",
    MARCAMODELO: "Marca/Modelo",
    ANOMODELO: "Ano do Modelo",
    MSG_ERRO: "Mensagem de Erro",
    CHAVERETORNO: "Chave de Retorno",
    CODIGORETORNO: "Código de Retorno",
    ANOFABRICACAO: "Ano de Fabricação",
    QUANTIDADE_DE_OCORRENCIAS_TOTAL_EXISTENTE: "Ocorrências Totais",
    CAPACIDADEPASSAGEIROS: "Capacidade de Passageiros",
    QUANTIDADE_DE_OCORRENCIAS_EXIBIDAS_E_DISPONIVEL_PELO_DETRAN:
      "Ocorrências Exibidas pelo Detran",
    QUANTIDADE_DE_BLOQUEIO_ATIVO_LOCALIZADO:
      "Quantidade de Bloqueios Ativos Localizados",
  };

  return Object.keys(retorno).map((key) => ({
    label: labelMap[key] || key,
    value:
      retorno[key as keyof Retorno] !== undefined &&
      retorno[key as keyof Retorno] !== ""
        ? retorno[key as keyof Retorno]
        : "-",
  }));
};

const getRenajudRecords = (renajudData: RenajudRecord[]): RenajudRecord[] => {
  return renajudData.map((record) => ({
    PLACA: record.PLACA,
    CHASSI: record.CHASSI,
    OCORRENCIA: record.OCORRENCIA,
    CHAVERETORNO: record.CHAVERETORNO,
    NOMETRIBUNAL: record.NOMETRIBUNAL,
    MUNICIPIOPLACA: record.MUNICIPIOPLACA,
    CODIGOTRIBUNAL: record.CODIGOTRIBUNAL,
    QTDOCORRENCIAS: record.QTDOCORRENCIAS,
    DATADAINCLUSAO: record.DATADAINCLUSAO,
    NUMERODOPROCESSO: record.NUMERODOPROCESSO,
    NOMEORGAOJUDICIAL: record.NOMEORGAOJUDICIAL,
    TIPORESTRICJUDICIAL: record.TIPORESTRICJUDICIAL,
    CODIGOORGAOJUDICIAL: record.CODIGOORGAOJUDICIAL,
    CONSTARESTRICAORENAJUD: record.CONSTARESTRICAORENAJUD,
  }));
};

export default function AutomotivaRenajud({
  automotivaRenajud,
}: {
  automotivaRenajud: any;
}) {
  const { t } = useTranslation();
  const formattedRetorno = formatRetorno(automotivaRenajud.RETORNO);
  const formattedRenajud = automotivaRenajud?.RENAJUD
    ? getRenajudRecords(automotivaRenajud.RENAJUD)
    : null;

  return (
    <>
      <ListDescriptions
        dados={formattedRetorno}
        categoria={t("Automotiva Renajud")}
      />

      {formattedRenajud ? (
        <DataTable value={formattedRenajud} className="p-datatable-striped">
          <Column field="TIPORESTRICJUDICIAL" header="Restrição" />
          <Column field="CHASSI" header="Chassi" />
          <Column field="CODIGOORGAOJUDICIAL" header="Orgão Judicial" />
          <Column field="PLACA" header="Placa" />
          <Column
            header="Tribunal"
            field="NOMETRIBUNAL"
            bodyClassName={styles.tribunalColumn}
          />
          <Column field="NUMERODOPROCESSO" header="Nº Processo" />
          <Column field="DATADAINCLUSAO" header="Data Inclusão" />
          <Column field="MUNICIPIOPLACA" header="Município" />
          <Column field="QTDOCORRENCIAS" header="Ocorrências" />
          <Column field="OCORRENCIA" header="Ocorrência" />
        </DataTable>
      ) : (
        <div className={styles.messageWrapper}>
          <Message
            severity="success"
            text="Nenhum registro de Renajud encontrado."
          />
        </div>
      )}
    </>
  );
}


import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";
import OutPutCard from "../../../components/outputCard";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";





export default function AutomotivaHistoricoProprietario({
  automotivaHistoricoProprietario
}: {
  automotivaHistoricoProprietario: any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('Automotiva Histórico Proprietário')} style={{width:'98%'}}>
       <Divider align="left">
        <div className="flex-score justify-content-between justify-content-center">
          <span className="p-tag" style={{position: 'relative', backgroundColor : '#183462', fontSize:'1.1rem', height: '2rem', marginTop: '0.5rem'}}>Resumo do Veículo</span> 
        </div>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('placa')}</label>
                 <Chip style={{width:"100%"}} label={automotivaHistoricoProprietario.RETORNO.PLACA} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('chassi')}</label>
              <Chip style={{width:"100%"}} label={automotivaHistoricoProprietario.RETORNO.CHASSI} />
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('renavam')}</label>
                 <Chip style={{width:"100%"}} label={ automotivaHistoricoProprietario.PROPRIETARIOANTERIORESXML && automotivaHistoricoProprietario.PROPRIETARIOANTERIORESXML.length > 0? automotivaHistoricoProprietario.PROPRIETARIOANTERIORESXML[0].RENAVAM : 'Não Informado'} />
            </div>
          </div>

          <div className="col">
            <div >
              <label className="label-dados">{t('anoFabricacao')}</label>
              <Chip style={{width:"100%"}} label={automotivaHistoricoProprietario.RETORNO.ANOFABRICACAO} />
            </div>
          </div>
        </div>
       
        <br></br>
        <br></br>
       <Divider align="left">
        <span className="p-tag" style={{backgroundColor : '#183462', fontSize:'1.1rem'}}>Histórico Proprietários</span>
       </Divider>
       <br></br>
       <DataTable size="small" value={automotivaHistoricoProprietario.PROPRIETARIOANTERIORESXML} tableStyle={{ minWidth: '50rem' }}>
          <Column field="ANO_EXERCICIO" header={t('anoExercicio')}></Column>
          <Column field="NOME_PROPRIETARIO" header={t('nome')}></Column>
          <Column field="UF_DUT" header={t('ufDut')}></Column>
          <Column field="MUNICIPIO" header={t('municipio')}></Column>
          <Column field="CORRECAO_CHASSI_RENAVAM" header={t('correcaoChassi')}></Column>
       </DataTable>
    </Panel>
  )
}

import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { Tree, TreeCheckboxSelectionKeys, TreeExpandedKeysType, TreeMultipleSelectionKeys } from 'primereact/tree';
import { useGlobalState } from '../../../components/globalState/GlobalStateProvider';
import { Divider } from 'primereact/divider';
import { FilterWeb } from '../../../interface/FilterWeb';
import { InputText } from 'primereact/inputtext';
import { Fieldset } from 'primereact/fieldset';


export default function FiltroAutomotiva({
    onProcess,
    onClose,
    msgs
  }: {
    onProcess: (filterWeb : FilterWeb) => void
    onClose: () => void,
    msgs:any
  }) {
  const { t } = useTranslation();
  const {state} = useGlobalState();
  const [placa, setPlaca] = useState<string>("");
  const [chassi, setChassi] = useState<string>("");
  const [motor, setMotor] = useState<string>("");
  const [documento, setDocumento] = useState<string>("");
  const [renavam, setRenavam] = useState<string>("");
  const [selectedKeys, setSelectedKeys] = useState<string | TreeMultipleSelectionKeys | TreeCheckboxSelectionKeys | any | null>(null); 
  const [selectedOptionCheck, setSelectedOptionCheck] = useState<any[]>([]);
  const placaObrigatorio = [
    "AUTOMOTIVA_AGREGADO",
    "AUTOMOTIVA_RENAINF",
    "AUTOMOTIVA_LEILAO"
  ] 
  const placaChassiObrigatorio = [
    "AUTOMOTIVA_PRECIFICADOR",
    "AUTOMOTIVA_GRAVAMESIMPLES",
    "AUTOMOTIVA_HISTORICOPROPRIETARIO",
    "AUTOMOTIVA_RENAJUD",
    "AUTOMOTIVA_BASEESTADUAL",
    "AUTOMOTIVA_BASERENAVAM",
    "AUTOMOTIVA_DECODIFICADOR",
    "AUTOMOTIVA_ONLINE",
    "AUTOMOTIVA_PERDATOTAL"

  ] 

  const expandNode = (node:any, _expandedKeys:any) => {
    if (node.children && node.children.length) {
        _expandedKeys[node.key] = true;

        for (let child of node.children) {
            expandNode(child, _expandedKeys);
        }
    }
  };

  
  const createOptions = (optionElement : any) =>{
    const option : any[] = [];

    optionElement.permissoes.filter((f: {
      tipoConsulta: string; 
    }) => f.tipoConsulta ===  'AUTO')
    .sort( (a:any,b:any) => {
      {
        if(!a.ordenacao){
          return 1;
        }

        if(!b.ordenacao){
          return -1;
        }

        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        return 0;
      }
    } )
    .map((element: any) => {
        option.push({label:element.label, key:element.value, Collapse:true, children: element.permissoes ? createPermissoesFilhos(element.permissoes.filter((f: {
          tipoConsulta: string; 
        }) => f.tipoConsulta ===  'AUTO')) : []});
            });
      
    
  
            expandAll(option)      
    return option;
  }

  const expandAll = (option:any) => {
    let _expandedKeys = {};

    for (let node of option) {
        expandNode(node, _expandedKeys);
    }

   return _expandedKeys;
};

  const createPermissoesFilhos = (permissoes:any[]) => {
    const option : any[] = [];
    if(permissoes){
      permissoes
      .sort( (a:any,b:any) => {
        {
          if(!a.ordenacao){
            return 1;
          }
  
          if(!b.ordenacao){
            return -1;
          }
  
          if (a.ordenacao > b.ordenacao) {
            return 1;
          }
          if (a.ordenacao < b.ordenacao) {
            return -1;
          }
          return 0;
        }
      } )
      .forEach((element: {
        children: any; label: string; value: string; permissoes:any[]  
      }) => {
        option.push({label:element.label, key:element.value, Collapse:true, children: element.permissoes  ? createPermissoesFilhos(element.permissoes.filter((f: {
          tipoConsulta: string; 
        }) => f.tipoConsulta === 'AUTO')) : []});
      });
    }
    return option;
  }

  const mensagemErroFiltro = (mensagem : string) => {
    window.scrollTo(0, 0);
    msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 100000, severity: 'error', summary: 'Erro', detail: mensagem, closable: false }
        
      ]);
  }

  const valid = (filteWeb :FilterWeb) => {
    if(filteWeb.consultas.length === 0){
       mensagemErroFiltro('Opções de consulta obrigatório')
       return false;
    }

    if(filteWeb.consultas.includes("AUTOMOTIVA_MOTOR") && !filteWeb.motor){
        mensagemErroFiltro('Número motor obrigatório')
       return false;
    }

    let retorno = true;
    filteWeb.consultas.forEach((c:string) => {
      if(placaObrigatorio.includes(c) && (!filteWeb.placa || filteWeb.placa.length === 0)){
        mensagemErroFiltro('Placa obrigatório')
        retorno = false;
        return false;
      }
    })
    filteWeb.consultas.forEach((c:string) => {
      if(placaChassiObrigatorio.includes(c) && (!filteWeb.placa || filteWeb.placa.length === 0) && (!filteWeb.chassi || filteWeb.chassi.length === 0)){
        mensagemErroFiltro('Placa ou Chassi obrigatório')
        retorno = false;
        return false;
      }
    })


    return retorno;
  }

  const processConsulta = () => {

    let opcoes: string[] = [];
    if(selectedKeys){
      Object.getOwnPropertyNames(selectedKeys).map((item) => opcoes.push(item));
    }
   
    selectedOptionCheck.map((item) => opcoes.push(item.value));
    const filteWeb :FilterWeb = {
      documento: documento,
      placa: placa,
      chassi: chassi,
      motor: motor, 
      consultas: opcoes,
      valor: null,
      arrojado: null,
      usual:null,
      conservador: null,
      indicadores: [],
      nomeRazao:null,
      uf:null,
      cidade:null,
      dataNascimento:null,
      ddi: null,
      ddd: null,
      telefone: null,
      email: null, 
      renavam : renavam
    };
    if(valid(filteWeb)){
      onProcess(filteWeb);
    }
   
  }

  const validDocument = (document:string) => {
    window.scrollTo(0, 0);
    let valid = true
    if(document.length === 14){
      valid = verifyCPF(document)
    }
    else{
      valid = verifyCnpj(document)
    }
    if(valid){
      msgs.current?.clear();
    }
    else{
      msgs.current?.clear();
      msgs.current?.show([
         { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Documento inválido', closable: false }
             
      ]);
    }
  }
 
  const verifyCnpj : any = (cnpj:string) => {
 
    cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj === '') return false;
     
    if (cnpj.length !== 14)
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;
         
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho) + '';
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(0)))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(1)))
          return false;
           
    return true;
    
 
}
 
  const  verifyCPF = (strCPF:string) => {
    strCPF = strCPF.replaceAll('.','').replace('-','')
    let Soma;
    let Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;
    if (strCPF == "11111111111") return false;
    if (strCPF == "22222222222") return false;
    if (strCPF == "33333333333") return false;
    if (strCPF == "44444444444") return false;
    if (strCPF == "55555555555") return false;
    if (strCPF == "66666666666") return false;
    if (strCPF == "77777777777") return false;
    if (strCPF == "88888888888") return false;
    if (strCPF == "99999999999") return false;
 
    for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
 
    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;
 
    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
 
    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
}
 
  const cnpj = (v:string) =>{
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/,"$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    return v
}
 
const cpf = (v:string) => {
    v=v.replace(/\D/g,"")                    //Remove tudo o que não é dígito
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                                             //de novo (para o segundo bloco de números)
    v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v
}
 
const maskCpfCnpj = (input:string) =>{
  if(input.length > 14){
    return cnpj(input)
  }
  else{
    return cpf(input)
  }
}

  const nodeTemplate = (node: any, options: { className: string | undefined; }) => {
    let label = <span>{node.label}</span>;
    if (node.children.length !== 0) {
       label = <b>{node.label}</b>;
    }
    if(node.key.includes('AUTOMOTIVA_MOTOR') && selectedKeys && Object.getOwnPropertyNames(selectedKeys)?.includes('AUTOMOTIVA_MOTOR') ){
      return <>
      <Fieldset onClick={(e)=> e.stopPropagation()} legend="Motor">
        <InputText id="motor" value={motor} onChange={(e)=>{setMotor(e.target.value);}}  placeholder="Motor" required></InputText>
      </Fieldset>
      </>
    }
    if(node.key.includes('AUTO_CSV') && selectedKeys && Object.getOwnPropertyNames(selectedKeys)?.includes('AUTO_CSV') ){
      return <>
      <Fieldset onClick={(e)=> e.stopPropagation()} legend={node.key}>
        <div className="flex-auto">
          <label htmlFor="documento" className="font-bold block mb-2 required">{t('documento')}</label>
          <InputText id="documento" maxLength={18} max={18} onKeyUp={(e) => {setDocumento(maskCpfCnpj(e.key === 'Backspace' ? documento.substring(0,documento.length -1) : documento + e.key))}} value={documento} onBlur={() => validDocument(documento)} placeholder="Documento" required></InputText>
        </div>
        <br></br>
        <div className="flex-auto">
          <label htmlFor="renavam" className="font-bold block mb-2 required">{t('renavam')}</label>
          <InputText id="renavam" value={renavam} onChange={(e)=>{setRenavam(e.target.value);}}  placeholder="Renavam" required></InputText>
        </div>
      </Fieldset>
      </>
    }

    if(node.key.includes('CADASTRAL_CNH_DOCUMENTO') && selectedKeys && Object.getOwnPropertyNames(selectedKeys)?.includes('CADASTRAL_CNH_DOCUMENTO') ){
      return <>
      <Fieldset onClick={(e)=> e.stopPropagation()} legend={node.key}>
        <div className="flex-auto">
          <label htmlFor="documento" className="font-bold block mb-2 required">{t('documento')}</label>
          <InputText id="documento" maxLength={18} max={18} onKeyUp={(e) => {setDocumento(maskCpfCnpj(e.key === 'Backspace' ? documento.substring(0,documento.length -1) : documento + e.key))}} value={documento} onBlur={() => validDocument(documento)}  placeholder="Documento" required></InputText>
        </div>
        <br></br>
        
      </Fieldset>
      </>
    }
    return label;
  }

  return (<>
    <Panel header={t('consultaAutomotiva')}>
        <div className=" p-fluid flex flex-wrap gap-3">
            <div className="flex-auto">
                <label htmlFor="placa" className="font-bold block mb-2">Placa</label>
                <InputMask id="placa" value='placa' onBlur={(e)=>{setPlaca(e.target.value);}} mask="aaa-9*99" placeholder="Placa" required></InputMask>
            </div>
            <br></br>
            <div className="flex-auto">
                <label htmlFor="chassi" className="font-bold block mb-2">Chassi</label>
                <InputText id="chassi" value={chassi} onChange={(e)=>{setChassi(e.target.value);}}  placeholder="Chassi" required></InputText>
            </div>
            <br></br>
            
            {state.user.consultas.filter((f: { tipoConsulta: string; }) => f.tipoConsulta === 'AUTO').sort( (a:any,b:any) => {
      {
        if(!a.ordenacao){
          return 1;
        }

        if(!b.ordenacao){
          return -1;
        }

        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        return 0;
      }
    } ).map((_item: any) => {
                    return ( <div key={_item.value+'_main'}>
                        <b>{_item.label}</b>
                        <div key={_item.value} className="flex flex-column gap-3">
                            <br></br>
                            <Tree key={_item.value+'_tree'} nodeTemplate={nodeTemplate} expandIcon={undefined} collapseIcon={undefined} expandedKeys = {expandAll(createOptions(_item))} value={createOptions(_item)} selectionMode="checkbox" selectionKeys={selectedKeys} onSelectionChange={(e) => {setSelectedKeys(e.value);  }} style={{border:'none', marginLeft:'-2.0rem', background:'transparent'}}  />
                        </div>
                        <br></br>
                        <Divider />
                        <br></br>
                        </div>);
                })}
         
        </div>
        <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Consultar"  onClick={processConsulta} />
            
        </div> 
            
    </Panel>
   
    </>
  )
}

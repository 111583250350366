import { DataTable } from "primereact/datatable";
import { ListDescriptions } from "../../../../components/ListDescriptions";
import { DadosFormatados, RetornoEndpoint } from "./types";
import { Column } from "primereact/column";
import { Panel } from "primereact/panel";

const formatarChave = (key: string): string => {
  const formatacoes: { [key: string]: string } = {
    CEP: "CEP",
    DDD: "DDD",
    CPF: "CPF",
    UF: "Estado",
    NOME: "Nome",
    SEXO: "Sexo",
    DATA: "Data",
    CNPJ: "CNPJ",
    SIGNO: "Signo",
    IDADE: "Idade",
    VALOR: "Renda",
    EMAIL: "E-mail",
    NUMERO: "Número",
    CIDADE: "Cidade",
    BAIRRO: "Bairro",
    CPFCNPJ: "CPF/CNPJ",
    TELEFONE: "Telefone",
    NOMEMAE: "Nome da Mãe",
    DOCUMENTO: "Documento",
    LOGRADOURO: "Logradouro",
    PERCENTUAL: "Percentual",
    COMPLEMENTO: "Complemento",
    RAZAOSOCIAL: "Razão Social",
    QUALIFICACAO: "Qualificação",
    NOMERAZAO: "Nome/Razão Social",
    NASCFUND: "Data de Nascimento",
  };
  return formatacoes[key] || key;
};

const dadosSemArray = (dados: any, categoriaNome: string): DadosFormatados => {
  const dadosFormatados = Object.entries(dados).map(([key, value]) => ({
    label: formatarChave(key),
    value: value as string,
  }));

  return {
    categoria: categoriaNome,
    dados: dadosFormatados,
  };
};

const formatarDadosSemArray = (
  data: RetornoEndpoint["data"]
): DadosFormatados[] => {
  const resultado: DadosFormatados[] = [];

  if (data.DADOSCADASTRAIS) {
    resultado.push(dadosSemArray(data.DADOSCADASTRAIS, "Dados Cadastrais"));
  }
  if (data.OBS) {
    resultado.push(dadosSemArray(data.OBS, "Observações"));
  }
  if (data.RENDAIBGE) {
    resultado.push(dadosSemArray(data.RENDAIBGE, "Renda IBGE"));
  }

  return resultado;
};

const formatarDadosComArray = (
  data: RetornoEndpoint["data"]
): JSX.Element[] => {
  const resultado: JSX.Element[] = [];

  if (data.TELEFONES) {
    resultado.push(
      <Panel header="Telefones" key="telefones" style={{ marginBottom: 32 }}>
        <DataTable value={Object.values(data.TELEFONES)}>
          <Column field="DDD" header="DDD" />
          <Column field="TELEFONE" header="Telefone" />
        </DataTable>
      </Panel>
    );
  }

  if (data.EMAIL) {
    resultado.push(
      <Panel header="Emails" key="emails" style={{ marginBottom: 32 }}>
        <DataTable value={Object.values(data.EMAIL)}>
          <Column field="EMAIL" header="Email" />
        </DataTable>
      </Panel>
    );
  }

  if (data.ENDERECOS) {
    resultado.push(
      <Panel header="Endereços" key="enderecos" style={{ marginBottom: 32 }}>
        <DataTable value={Object.values(data.ENDERECOS)}>
          <Column field="LOGRADOURO" header="Logradouro" />
          <Column field="NUMERO" header="Número" />
          <Column field="BAIRRO" header="Bairro" />
          <Column field="CIDADE" header="Cidade" />
          <Column field="UF" header="UF" />
          <Column field="CEP" header="CEP" />
        </DataTable>
      </Panel>
    );
  }

  if (data.MORADORESENDERECO) {
    resultado.push(
      <Panel header="Moradores" key="moradores" style={{ marginBottom: 32 }}>
        <DataTable value={Object.values(data.MORADORESENDERECO)}>
          <Column field="CPF" header="CPF" />
          <Column field="NOME" header="Nome" />
        </DataTable>
      </Panel>
    );
  }

  if (data.PARTICIPACAOSOCIETARIA) {
    resultado.push(
      <Panel key="societaria" header="Participação Societária">
        <DataTable value={Object.values(data.PARTICIPACAOSOCIETARIA)}>
          <Column field="DOCUMENTO" header="Documento" />
          <Column field="CNPJ" header="CNPJ" />
          <Column field="PERCENTUAL" header="Percentual" />
          <Column field="QUALIFICACAO" header="Qualificação" />
          <Column field="DATA" header="Data" />
          <Column field="RAZAOSOCIAL" header="Razão Social" />
        </DataTable>
      </Panel>
    );
  }

  return resultado;
};
export function CredifyConsultaCPF({ data }: RetornoEndpoint) {
  const dadosLista = formatarDadosSemArray(data);
  const dadosTabela = formatarDadosComArray(data);

  return (
    <>
      {dadosLista.map(({ categoria, dados }) => (
        <ListDescriptions key={categoria} dados={dados} categoria={categoria} />
      ))}

      {dadosTabela.map((tabela) => (
        <div key={tabela.key}>{tabela}</div>
      ))}
    </>
  );
}

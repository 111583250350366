import { Panel } from "primereact/panel";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { formatDate } from "../../../../utils/format";
import { ApiResponse, FormattedData, Gps391Props } from "./types";
import { ListDescriptions } from "../../../../components/ListDescriptions";

const formatApiResponse = (data: ApiResponse): FormattedData[] => {
  const formattedData: FormattedData[] = [];

  // const spca108DebitoDetails = [
  //   {
  //     label: "Devedor",
  //     value:
  //       data["SPCA-108-DEBITO"]["SPCA-108-RESUMO"]["SPCA-108-TOTAL"][
  //         "SPCA-108-DEVEDOR"
  //       ],
  //   },
  //   {
  //     label: "Valor",
  //     value:
  //       data["SPCA-108-DEBITO"]["SPCA-108-RESUMO"]["SPCA-108-TOTAL"][
  //         "SPCA-108-VALOR"
  //       ],
  //   },
  // ];

  // formattedData.push({
  //   categoria: "Débito",
  //   dados: spca108DebitoDetails,
  // });

  const spca221SinteseDetails = [
    { label: "Nome", value: data["SPCA-221-SINTESE"]["SPCA-221-NOME"] },
    { label: "Mãe", value: data["SPCA-221-SINTESE"]["SPCA-221-MAE"] },
    { label: "CPF", value: data["SPCA-221-SINTESE"]["SPCA-221-CPF"] },
    {
      label: "Nascimento",
      value: formatDate(
        data["SPCA-221-SINTESE"]["SPCA-221-NASCIMENTO"].toString()
      ),
    },
  ];

  formattedData.push({
    categoria: "Sintese",
    dados: spca221SinteseDetails,
  });

  return formattedData;
};

function removerPrefixo(obj: any, prefixo: string): any {
  const novoObjeto: any = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const novaChave = key.startsWith(prefixo)
        ? key.replace(prefixo, "")
        : key;

      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        const subObjeto = removerPrefixo(obj[key], prefixo);
        for (const subKey in subObjeto) {
          if (subObjeto.hasOwnProperty(subKey)) {
            const novaSubChave = `data${
              subKey.charAt(0).toUpperCase() + subKey.slice(1)
            }`;
            novoObjeto[novaSubChave] = subObjeto[subKey];
          }
        }
      } else {
        novoObjeto[novaChave] = obj[key];
      }
    }
  }

  return novoObjeto;
}

function formatarDadosArray(
  arrayData: any[] | any,
  prefixo: string,
  categoria: string
): any[] {
  const arraysFormatados: any[] = [];

  if (!Array.isArray(arrayData)) {
    arrayData = [arrayData];
  }

  const dadosFormatados = arrayData.map((item: any) => {
    return removerPrefixo(item, prefixo);
  });

  arraysFormatados.push({
    categoria,
    dados: dadosFormatados,
  });

  return arraysFormatados;
}
export function Gps391({ data }: Gps391Props) {
  const dataList = formatApiResponse(data);
  const debitoData = formatarDadosArray(
    data["SPCA-124-DEBITO"],
    "SPCA-124-",
    "Débito"
  );

  const consultaData = formatarDadosArray(
    data["SPCA-126-CONSULTA"],
    "SPCA-126-",
    "Consulta anteriores"
  );

  return (
    <>
      {dataList.map(({ categoria, dados }) => (
        <ListDescriptions key={categoria} dados={dados} categoria={categoria} />
      ))}

      {debitoData.map(({ categoria, dados }) => (
        <Panel key={categoria} header={categoria}>
          <DataTable value={dados} scrollable className="no-wrap-table">
            <Column field="UF" header="UF" />
            <Column field="MOEDA" header="Moeda" />
            <Column field="VALORN" header="Valor" />
            <Column field="SITUACAO" header="Situação" />
            <Column field="TIPO" header="Tipo" />
            <Column field="CIDADE" header="Cidade" />
            <Column field="CONDICAO" header="Condição" />
            <Column field="VALOR" header="Valor" />
            <Column field="INFORMANTE" header="Informante" />
            <Column field="DESCRICAO" header="Descrição" />
            <Column field="CONTRATO" header="Contrato" />
            <Column
              field="dataREGISTRO"
              header="Data Registro"
              body={(rowData) => formatDate(rowData["dataREGISTRO"])}
            />
            <Column
              field="dataDISPONIVEL"
              header="Data Disponível"
              body={(rowData) => formatDate(rowData["dataDISPONIVEL"])}
            />
          </DataTable>
        </Panel>
      ))}

      {consultaData.map(({ categoria, dados }) => (
        <Panel key={categoria} header={categoria} style={{ marginTop: 32 }}>
          <DataTable value={dados} scrollable className="no-wrap-table">
            <Column field="DATA" header="Data" />
            <Column field="DESCRICAO" header="Descrição" />
            <Column field="INFORMANTE" header="Informante" />
            <Column field="TIPO" header="Tipo" />
          </DataTable>
        </Panel>
      ))}
    </>
  );
}

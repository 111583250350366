import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Password } from "primereact/password";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/new-logo-car.png";
import { GlobalSaveData } from "../../components/globalState/GlobalSaveData";
import { UserCredentials } from "../../interface/UserCredentials";
import getRelatorioNotificacao from "../../service/getRelatorioNotificacao";
import getUser from "../../service/getUser/getUserService";
import postLogin from "../../service/postLogin/postLoginService";
import "./styles.css";
import { Label } from "../../components/Label";

export default function Page() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const submit = () => {
    const userCredential: UserCredentials = {
      login: login,
      password: password,
    };

    setTimeout(() => {
      postLogin(userCredential)
        .then((data) => {
          GlobalSaveData("token", data);
          setTimeout(() => {
            getRelatorioNotificacao().then((data) => {
              GlobalSaveData("relatorios", data);
            });
          }, 100);

          setTimeout(() => {
            getUser()
              .then((user) => {
                GlobalSaveData("user", user);
                setTimeout(() => {
                  window.location.href =
                    user.urlRedirect && user.urlRedirect !== "null"
                      ? user.urlRedirect
                      : "/creditbureau/home";
                }, 100);
              })
              .catch((error) => {
                msgs.current?.clear();
                msgs.current?.show([
                  {
                    sticky: false,
                    life: 100000,
                    severity: "error",
                    summary: "Erro",
                    detail: "Login ou Senha inválidos",
                    closable: false,
                  },
                ]);
              });
          }, 100);
        })
        .catch((error) => {
          msgs.current?.clear();
          msgs.current?.show([
            {
              sticky: false,
              life: 100000,
              severity: "error",
              summary: "Erro",
              detail: "Login ou Senha inválidos",
              closable: false,
            },
          ]);
        });
    }, 1000);
  };
  return (
    <div className="main-login">
      <div className="container-login">
        <div className="container-image-login">
          <img src={logo} alt="logo" />
        </div>

        <div className="container-form-login">
          <Messages ref={msgs} />

          <h2>Seja bem-vindo</h2>

          <form className="container-form-login">
            <div className="input-container-login">
              <Label htmlFor="login" text={t("login")} required />
              <InputText
                id="login"
                onChange={(e) => setLogin(e.target.value)}
                required
              />
            </div>

            <br />

            <div className="input-container-login">
              <Label htmlFor="login" text={t("senha")} required />
              <Password
                onChange={(e) => setPassword(e.target.value)}
                feedback={false}
                toggleMask
              />
            </div>

            <a href="/creditbureau/recuperacao" className="reset-password">
              Esqueci minha senha
            </a>

            <button type="button" onClick={submit} className="button-secondary">
              Entrar
            </button>
          </form>

          <a
            className="link-privacity"
            href={require("../../assets/privacidade.pdf")}
            target="_blank"
            rel="noreferrer"
          >
            Política de Privacidade
          </a>
        </div>
      </div>
    </div>
  );
}

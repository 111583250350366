import { http } from '../../config/axios'

export default function postVeiculo(veiculo: any): Promise<string> {
  return http
    .post('api/veiculo', veiculo)
    .then((res) => {
      return res.data as string
    })
    .catch((error) => {
      console.error('Erro post veiculo', error)
      throw Error(error.response.data)
    })
}

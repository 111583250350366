import { IProprietarioTelefoneOperadora } from "../../../interface";
import { InfoDisplay } from "../../../components/InfoDisplay";
import { Panel } from "primereact/panel";

type IPhoneInfoProps = {
  data: IProprietarioTelefoneOperadora | null;
};

export const ProprietarioTelefoneOperadora = ({ data }: IPhoneInfoProps) => {
  const CONTACT = data?.PROPRIETARIOTELEFONEOPERADORA.CONTACT;
  const LOCATION = data?.PROPRIETARIOTELEFONEOPERADORA.LOCATION;
  const PHONE_TYPE = data?.PROPRIETARIOTELEFONEOPERADORA.PHONE_TYPE;
  const NUMBERING = data?.PROPRIETARIOTELEFONEOPERADORA.NUMBERING;
  const BLOCKLISTING = data?.PROPRIETARIOTELEFONEOPERADORA.BLOCKLISTING;
  const CARRIER = data?.PROPRIETARIOTELEFONEOPERADORA.CARRIER;

  return (
    <Panel
      header="Cadastral Proprietário Telefone Operadora"
      style={{ width: "98%" }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        {/* Dados do Telefone */}
        <section>
          <h3
            style={{
              fontSize: "1.125rem",
              lineHeight: "normal",
              fontWeight: "500",
              marginBottom: "1rem",
            }}
          >
            Dados do Telefone
          </h3>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "24px",
            }}
          >
            <InfoDisplay
              label="Tipo de Telefone"
              value={
                PHONE_TYPE?.DESCRIPTION === "FIXED_LINE" ? "Fixo" : "Celular"
              }
            />
            <InfoDisplay label="Operadora" value={CARRIER?.NAME || "N/A"} />
            <InfoDisplay
              label="Bloqueado"
              value={BLOCKLISTING?.BLOCKED === "TRUE" ? "Sim" : "Não"}
            />
            <InfoDisplay
              label="Número Limpo"
              value={`${NUMBERING?.CLEANSING.CALL.COUNTRY_CODE} ${NUMBERING?.CLEANSING.CALL.PHONE_NUMBER}`}
            />
          </div>
        </section>

        {/* Localização */}
        <section>
          <h3
            style={{
              fontSize: "1.125rem",
              lineHeight: "normal",
              fontWeight: "500",
              marginBottom: "1rem",
            }}
          >
            Localização
          </h3>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "24px",
            }}
          >
            <InfoDisplay label="Cidade" value={LOCATION?.CITY || "N/A"} />
            <InfoDisplay label="País" value={LOCATION?.COUNTRY.NAME || "N/A"} />
          </div>
        </section>

        {/* Informações de Contato do Proprietário */}
        <section>
          <h3
            style={{
              fontSize: "1.125rem",
              lineHeight: "normal",
              fontWeight: "500",
              marginBottom: "1rem",
            }}
          >
            Informações do Proprietário
          </h3>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "24px",
            }}
          >
            <InfoDisplay label="ID" value={CONTACT?.ID || "N/A"} />
            <InfoDisplay
              label="Nome"
              value={`${CONTACT?.FIRST_NAME || ""} ${CONTACT?.LAST_NAME || ""}`}
            />
            <InfoDisplay
              label="Endereço 1"
              value={CONTACT?.ADDRESS1 || "N/A"}
            />
            <InfoDisplay
              label="Endereço 2"
              value={CONTACT?.ADDRESS2 || "N/A"}
            />
            <InfoDisplay
              label="Endereço 3"
              value={CONTACT?.ADDRESS3 || "N/A"}
            />
            <InfoDisplay
              label="Endereço 4"
              value={CONTACT?.ADDRESS4 || "N/A"}
            />
            <InfoDisplay label="Cidade" value={CONTACT?.CITY || "N/A"} />
            <InfoDisplay
              label="CEP"
              value={CONTACT?.ZIP_POSTAL_CODE || "N/A"}
            />
            <InfoDisplay
              label="Data de Nascimento"
              value={CONTACT?.DATE_OF_BIRTH ?? "N/A"}
            />
          </div>
        </section>
      </div>
    </Panel>
  );
};

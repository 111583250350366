
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";
import OutPutCard from "../../../components/outputCard";
import { Divider } from "primereact/divider";





export default function AutomotivaPrecificador({
  automotivaPrecificador
}: {
  automotivaPrecificador: any
}) {
  const { t } = useTranslation();
  let fipe : any = {};
  let molicar : any = {};
  let resumo : any = {};
  if(!automotivaPrecificador.RETORNO.FALHA){
    fipe = automotivaPrecificador.VALORDETALHE1
    molicar = automotivaPrecificador.VALORDETALHE2
    resumo = automotivaPrecificador.RETORNO
  }
  
  
  return (
    <Panel header={t('Automotiva Precificador')} style={{width:'98%'}}>
       {!automotivaPrecificador.RETORNO.FALHA ? (<>
        <Divider align="left">
        <div className="flex-score justify-content-between justify-content-center">
          <span className="p-tag" style={{position: 'relative', backgroundColor : '#183462', fontSize:'1.1rem', height: '2rem', marginTop: '0.5rem'}}>Resumo do Veículo</span> 
        </div>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('placa')}</label>
                 <Chip style={{width:"100%"}} label={resumo.PLACA} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('marcaModelo')}</label>
                 <Chip style={{width:"100%"}} label={resumo.MODELO} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('chassi')}</label>
              <Chip style={{width:"100%"}} label={resumo.CHASSI} />
            </div>
          </div>
          
        </div>
        <div className="grid">
          <div className="col">
            <div >
              <label className="label-dados">{t('motor')}</label>
              <Chip style={{width:"100%"}} label={resumo.MOTOR} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('combustivel')}</label>
                 <Chip style={{width:"100%"}} label={resumo.COMBUSTIVEL} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('anoModelo')}</label>
              <Chip style={{width:"100%"}} label={resumo.ANO} />
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
       <Divider align="left">
        <span className="p-tag" style={{backgroundColor : '#183462', fontSize:'1.1rem'}}>{fipe.VR1TIPO}</span>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Chassi" value={fipe.VR1SEQCHASSI}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Valor" value={fipe.VR1VALOR}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Modelo" value={fipe.VR1VERSAO}></OutPutCard>
            </div>
          </div>
          
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Código" value={fipe.VR1CODIGO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Combustível" value={fipe.VR1COMBUSTIVEL}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Marca" value={fipe.VR1MARCA}></OutPutCard>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
       <Divider align="left">
        <span className="p-tag" style={{backgroundColor : '#183462', fontSize:'1.1rem'}}>{molicar.VR2TIPO}</span>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Chassi" value={molicar.VR2SEQCHASSI}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Valor" value={molicar.VR2VALOR}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Modelo" value={molicar.VR2VERSAO}></OutPutCard>
            </div>
          </div>
          
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Código" value={molicar.VR2CODIGO}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Combustível" value={molicar.VR2COMBUSTIVEL}></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                 <OutPutCard label="Marca" value={molicar.VR2MARCA}></OutPutCard>
            </div>
          </div>
        </div>
        </>) : (<p>{automotivaPrecificador.RETORNO.FALHA}</p>)}
       
    </Panel>
  )
}

import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";
import VeiculoVendaList from './VeiculoVendaList';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import VeiculoVendaCadastrar from './VeiculoVendaCadastrar';
import { Messages } from 'primereact/messages';
import postVeiculoVenda from "../../service/postVeiculoVenda";
import putVeiculoVenda from '../../service/putVeiculoVenda';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import getVeiculoVendaMotivoCancelamento from '../../service/getVeiculoVendaMotivoCancelamento';
import putVeiculoVendaCancelarComunicado from '../../service/putVeiculoVendaCancelarComunicado';

export default function LeilaoPage() {
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const msgsDialog = useRef<Messages>(null);
  const [veiculoVenda, setVeiculoVenda] = useState<any | null>(null);
  const [veiculoVendaEdit, setVeiculoVendaEdit] = useState<any | null>(null);
  const [selectedMotivo, setSelectedMotivo] = useState<any>(null);
  const [motivos, setMotivos] = useState<any>([]);
  const labelButton = t('cadastrar')
  const saveVeiculoVenda = (veiculoVenda:any) => {
    if(veiculoVenda.id){
      putVeiculoVenda(veiculoVenda).then(data => {
        if(data.includes('Erro')){
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
               
            ]);
          }
        }
        else{
          setVeiculoVenda(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
               
            ]);
          }
        }
      })
    }
    else{
      postVeiculoVenda(veiculoVenda).then(data => {
        if(data.includes('Erro')){
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
               
            ]);
          }
        }
        else{
          setVeiculoVenda(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
               
            ]);
          }
        }
      })
    }
    
    
  }

  const resetForm = () => {
    setVeiculoVendaEdit(undefined);
  }  

  const cancelarComunicado = () => {
    const cancelamento = {
      id: veiculoVendaEdit.id,
      motivoCancelamento: selectedMotivo
    }
    putVeiculoVendaCancelarComunicado(cancelamento).then(data => {
      if(data.includes('Erro')){
        if (msgs.current) {
          msgsDialog.current?.clear();
          msgsDialog.current?.show([
              { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
             
          ]);
        }
      }
      else{
        setVeiculoVendaEdit(null);
        
        if (msgs.current) {
          msgs.current?.clear();
          msgs.current?.show([
              { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
             
          ]);
        }
      }
    })
  }

  useEffect(() => {
    getVeiculoVendaMotivoCancelamento().then( (data) => setMotivos(data))

  }, [])

  const footerContent = (
    <React.Fragment>
          <Button form='form-permission' style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Cancelar" onClick={() => cancelarComunicado()}   />
          <Button label="Fechar" icon="pi pi-times" style={{color:'#183462'}} onClick={() => resetForm()} className="p-button-text" />
    </React.Fragment>
    );

  const startContent = (
    <React.Fragment>
        <Button label={labelButton} icon="pi pi-plus" style={{backgroundColor:'#183462'}} onClick={() => {setVeiculoVenda({})}}/>
    </React.Fragment>
);

  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-shopping-cart" style={{ fontSize: '2.0rem' }}></i> {t('comunicadoVendaVeiculo')}</h1>
            </div>     
          </div>
      </div>
      <div className="card">
        <Messages ref={msgs} />
        {!veiculoVenda && (
          <>
            <Toolbar start={startContent} />
            <br></br>
            <br></br>
            <VeiculoVendaList msgs={msgs} onEdit={(veiculoVenda:any) => { setVeiculoVendaEdit(veiculoVenda);}}></VeiculoVendaList>
          </>
        )}
        {veiculoVenda && (
          <>
           <VeiculoVendaCadastrar msgs={msgs} veiculoVenda={veiculoVenda} onSave={saveVeiculoVenda} onClose={() => {setVeiculoVenda(null)}}></VeiculoVendaCadastrar>
          </>
        )}
      </div>
      <Dialog header={t('cancelarComunicadoVenda')} visible={veiculoVendaEdit} style={{ width: '80vw' }} onHide={() => resetForm()} footer={footerContent}>
        <Messages ref={msgsDialog} />
        <div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap" style={{flexGrow:'0.5'}}>
              <div>
                <label className="label-dados" style={{paddingLeft:'0'}}>{t('motivoCancelamento')}</label>
                <Dropdown style={{width: '50%'}} value={selectedMotivo} onChange={(e) => setSelectedMotivo(e.value)} options={motivos} optionLabel="label" placeholder="Selecione Motivo Cancelamento" 
                        filter className="w-full md:w-14rem" />  
              </div>
            </div>
          </div>
        </div>
      </Dialog>
                
    </Container>
  )
}

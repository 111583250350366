
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function CadastralNomePF({
  cadastralNomePF,
}: {
  cadastralNomePF: any
}) {
  const { t } = useTranslation();
  const list: any[] | undefined = []
  if(cadastralNomePF.BUSCANOME){
    Object.getOwnPropertyNames(cadastralNomePF.BUSCANOME).map((item) => list.push(cadastralNomePF.BUSCANOME[item]))
  }
  console.log(list)
  return (
    <Panel header={t('cadastralNomePF')} style={{width:'98%'}}>
       
       <DataTable size="small" value={list} tableStyle={{ minWidth: '50rem' }}>
          <Column field="CPF" header={t('documento')}></Column>
          <Column field="NOME" header={t('nome')}></Column>
          <Column field="UF" header={t('uf')}></Column>
          <Column field="CIDADE" header={t('cidade')}></Column>
      </DataTable>
    </Panel>
  )
}

import React from "react";
import { classNames } from "primereact/utils";
import "./styles.css";

type ButtonProps = React.ComponentProps<"button"> & {
  text: string;
  variants?: "solid" | "outline";
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ text, variants = "solid", className, ...props }, ref) => {
    const variantClass = {
      solid: "btn-custom-solid",
      outline: "btn-custom-outlined",
    };

    return (
      <button
        ref={ref}
        className={classNames(variantClass[variants], className)}
        {...props}
      >
        {text}
      </button>
    );
  }
);
